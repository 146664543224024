import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { NumberInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
import { updatePrice } from 'src/store/actions/priceActions';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    margin-bottom: 10px;
    h6 {
      color: ${(props) => props.theme.color.cyan};
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${(props) => props.theme.color.deepGrey};
    transform: scale(1.5);

    &:hover {
      background-color: ${(props) => props.theme.color.lightGrey};
      transition: 0.3s;
    }
  }
  .form {
    margin-bottom: 1rem;
  }
`;

const UpdateAirtimePrice = ({ isOpen, handleClose, updatePrice, data, type, resetSelected }) => {
  const [commission, setCommission] = useState('');

  useEffect(() => {
    setCommission(data.commission);
  }, [data]);

  const resetState = () => {
    setCommission('');
  };

  const closeModal = () => {
    handleClose();
    resetState();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const payload = { commission };
    updatePrice(payload, data._id, type);
    resetSelected();
    return closeModal();
  };

  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <Wrapper>
        <div className="header">
          <div className="header_title">
            <h6>Update Airtime Price</h6>
          </div>

          <div onClick={closeModal} className="cancel">
            <CloseIcon />
          </div>
        </div>
        <div className="form">
          <NumberInput label="Commission" value={commission} onChange={setCommission} />
        </div>
        <Button text="Update" fullWidth={true} onClick={onSubmit} />
      </Wrapper>
    </Modal>
  );
};

UpdateAirtimePrice.defaultProps = {
  isOpen: false,
};

UpdateAirtimePrice.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  resetSelected: PropTypes.func.isRequired,
  updatePrice: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default connect(null, { updatePrice })(UpdateAirtimePrice);
