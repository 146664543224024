import React from 'react';
import { formatAmount, sortTransactionType } from 'src/utils/helpers';
import ViewTransaction from './ViewTransaction';
import TransactionStatus from './TransactionStatus';

export const overviewHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Type</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{sortTransactionType(rowData)}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['Amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <TransactionStatus status={rowData['Status']} />;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['Date']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{rowData['Time']}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return <ViewTransaction transactionRef={rowData.transactionRef} />;
    },
  },
];
