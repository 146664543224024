import React from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import PropTypes from 'prop-types';
import Notify from 'src/components/notification/Notify';
import SatoshiBold from 'src/assets/font/Satoshi-Bold.otf';
import SatoshiRegular from 'src/assets/font/Satoshi-Regular.otf';

const theme = {
  color: {
    green3: '#20A43C',
    green2: '#03b336',
    green: '#62CE5F',
    lightGreen: '#74F671',
    yellow: '#E5D64A',
    lightYellow: '#FAE953',
    black: '#000000',
    font_01: '#666666',
    font_02: '#282828',
    font_03: '#dcdcdc',
    font_04: '#181818',
    blue: '#3D7EF6',
    white: '#FFFFFF',
    red: '#D06060',
    cyan: '#219DD3',
    lightRed: '#F67171',
    deepGrey: '#949494',
    grey: '#B9B9B9',
    grey2: '#c5c5c5',
    grey3: '#cccccc',
    lightGrey: '#E5E5E5',
    background: '#f5f5f5',
    background_header: '#343F79',
    background_table: '#f6f6f6',
  },
};

const Wrapper = styled.div`
  background-color: ${theme.color.background};
  min-height: 100vh;
  min-width: 100vw;
`;

const GlobalStyle = createGlobalStyle`
	@font-face {
    font-family: satoshi;
    src: url(${SatoshiRegular});
  	font-style: normal;
  	font-weight: 400;
  }

	@font-face {
    font-family: satoshi;
    src: url(${SatoshiBold});
	  font-weight: 700;
  }

	html {
		box-sizing: border-box;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}
	body {
		padding: 0;
		margin: 0;
		font-size: 1.2rem;
		font-family: satoshi, sans-serif;
		color: ${theme.color.font_02};
		overflow-x: hidden;
	}
	div, span {
    margin: 0;
    padding: 0;
  }
	h1, h2, h3, h4, h5, h6, p {
		margin: 0;
		padding: 0;
	}
	h1 {
    font-size: 1.5rem;
	}
	h2, h3 {
    font-size: 1.1rem;
	}
	h6,p {
    font-size: 1rem;
	}
	a {
		color: ${(props) => props.theme.color.cyan};
		transition: 0.3s;

		&:hover {
			color: ${(props) => props.theme.color.cyan}C1;
		}
	}
`;

const Theme = (props) => (
  <ThemeProvider theme={theme}>
    <div>
      <GlobalStyle />
      <Wrapper>
        <Notify />
        {props.children}
      </Wrapper>
    </div>
  </ThemeProvider>
);
Theme.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Theme;
