import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from 'src/components/common/table/Table';
import { overviewHeader } from './transactionsHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import TransactionSearch from './TransactionSearch';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Failed', value: 'failed' },
];

const typeOptions = [
  { value: 'all', text: 'All' },
  { value: 'agent_onboarding', text: 'Agent Onboarding' },
  { value: 'loan_collection', text: 'Loan Collection' },
  { value: 'loan_disbursement', text: 'Loan Disbursement' },
];

function TransactionTable({ tableData, onPageChange, onLimitChange, status, onStatusChange, type, onTypeChange }) {
  const tableKey = {};

  const rightHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <SelectInput selectLabel="Type" value={type} onChange={onTypeChange} options={typeOptions} />
      <SelectInput selectLabel="Status" value={status} onChange={onStatusChange} options={statusOptions} />
    </div>
  );

  return (
    <Wrapper>
      <Table
        headers={overviewHeader}
        leftHeader={<TransactionSearch />}
        rightHeader={rightHeader}
        showNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

TransactionTable.propTypes = {
  tableData: PropTypes.object,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  status: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  onTypeChange: PropTypes.func.isRequired,
};

export default TransactionTable;
