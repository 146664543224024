import React from 'react';
import PosActions from './PosActions';

export const posMappingHeader = [
  {
    title: function title() {
      return <p>POS ID</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['posId'] || '-'}</p>;
    },
  },
  {
    title: function title() {
      return <p>Email</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['email'] || '-'}</p>;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function DateTaken(rowData) {
      return <PosActions data={rowData} />;
    },
  },
];
