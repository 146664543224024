import {
  TRANSACTION_IS_LOADING,
  TRANSACTION_NOT_LOADING,
  ADD_TRANSACTIONS,
  ADD_TRANSACTION,
  CLEAR_TRANSACTIONS,
  SET_TRANSACTION_DATE,
  SET_TRANSACTION_LIMIT,
  SET_TRANSACTION_PAGE,
  SET_TRANSACTION_STATUS,
  RESET_TRANSACTION_DATE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  transactions: null,
  transaction: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSACTION_IS_LOADING:
      return { ...state, isLoading: true };
    case TRANSACTION_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case ADD_TRANSACTION:
      return { ...state, transaction: action.payload };
    case CLEAR_TRANSACTIONS:
      return { ...state, transactions: initialState.transactions };
    case SET_TRANSACTION_DATE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_TRANSACTION_DATE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          start_date: null,
          end_date: null,
        },
      };
    case SET_TRANSACTION_LIMIT:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          limit: action.payload,
        },
      };
    case SET_TRANSACTION_PAGE:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          page: action.payload,
        },
      };
    case SET_TRANSACTION_STATUS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          status: action.payload,
        },
      };
    default:
      return state;
  }
}
