import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { formatDate } from 'src/utils/helpers';
import { DateRangeInput } from 'src/components/common/inputs';
import WalletHistoryTable from './WalletHistoryTable';
import {
  getYawoWalletStatsByIuwi,
  getAllYawoWalletHistories,
  setYawoWalletHistoryPage,
  setYawoWalletHistoryLimit,
  setYawoWalletHistoryDate,
  resetYawoWalletHistoryDate,
} from 'src/store/actions/yawo/walletHistoryActions';
import { formatAmount } from 'src/utils/helpers';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time_display {
    min-width: 13rem;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .subheading {
    color: ${(props) => props.theme.color.cyan};
  }
`;

function WalletHistoriesComponent({
  getYawoWalletStatsByIuwi,
  getAllYawoWalletHistories,
  isLoading,
  yawoWalletHistories,
  yawoVendor,
  setYawoWalletHistoryPage,
  setYawoWalletHistoryLimit,
  setYawoWalletHistoryDate,
  resetYawoWalletHistoryDate,
}) {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(history.location.search);
  const yawoWalletIuwi = useQuery().get('yawoWalletIuwi') || yawoVendor.yawoWallet || '';

  const [type, setType] = useState('all');
  const [status, setStatus] = useState('all');
  const [yawoWalletStats, setYawoWalletStats] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (yawoWalletIuwi) {
      getYawoWalletStatsByIuwi(yawoWalletIuwi, (yawoWalletStats) => {
        setYawoWalletStats(yawoWalletStats);
      });
    }
  }, [yawoWalletIuwi]);

  const getYawoWalletHistories = () => {
    const query = {};
    if (yawoWalletIuwi && yawoWalletIuwi !== '') {
      query.iuwi = yawoWalletIuwi;
    }
    if (type && type !== 'all') {
      query.walletHistoryType = type;
    }
    if (status && status !== 'all') {
      query.status = status;
    }
    getAllYawoWalletHistories(query);
  };

  useEffect(() => {
    getYawoWalletHistories();
  }, [status, type]);

  const onPageChange = (page) => {
    setYawoWalletHistoryPage(page);
    getYawoWalletHistories();
  };

  const onLimitChange = (limit) => {
    setYawoWalletHistoryLimit(limit);
    getYawoWalletHistories();
  };

  const onDateChange = async (date) => {
    await setYawoWalletHistoryDate(date.startDate, date.endDate);
    getYawoWalletHistories();
  };

  const onResetDateClick = () => {
    resetYawoWalletHistoryDate();
    getYawoWalletHistories();
  };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard
          isLoading={isLoading}
          title="Wallet IUWI"
          value={yawoWalletStats !== null && yawoWalletStats.iuwi}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Wallet Balance"
          value={yawoWalletStats !== null && formatAmount(yawoWalletStats.walletBalance)}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Total Income"
          value={yawoWalletHistories !== null && yawoWalletHistories.totalIncome}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Total Debit"
          value={yawoWalletHistories !== null && yawoWalletHistories.totalDebit}
        />
        <div className="date_range">
          <Button
            onClick={toggle}
            text={`${yawoWalletHistories === null ? '' : formatDate(yawoWalletHistories.startDate)} - ${
              yawoWalletHistories === null ? '' : formatDate(yawoWalletHistories.endDate)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div>
      </div>

      <br />
      <h3 className="subheading">Wallet History</h3>

      <WalletHistoryTable
        isLoading={isLoading}
        tableData={yawoWalletHistories}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        type={type}
        setType={setType}
        status={status}
        onStatusChange={setStatus}
      />
    </Wrapper>
  );
}

WalletHistoriesComponent.propTypes = {
  getYawoWalletStatsByIuwi: PropTypes.func.isRequired,
  getAllYawoWalletHistories: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoWalletHistories: PropTypes.object,
  yawoVendor: PropTypes.object,
  setYawoWalletHistoryLimit: PropTypes.func.isRequired,
  setYawoWalletHistoryPage: PropTypes.func.isRequired,
  setYawoWalletHistoryDate: PropTypes.func.isRequired,
  resetYawoWalletHistoryDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoWalletHistories, isLoading } = state.yawoWalletHistory;
  const { yawoVendor } = state.yawoVendor;
  return {
    isLoading,
    yawoWalletHistories,
    yawoVendor,
  };
};

export default connect(mapStateToProps, {
  getYawoWalletStatsByIuwi,
  getAllYawoWalletHistories,
  setYawoWalletHistoryDate,
  setYawoWalletHistoryLimit,
  setYawoWalletHistoryPage,
  resetYawoWalletHistoryDate,
})(WalletHistoriesComponent);
