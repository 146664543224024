import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .nav_item {
    padding: 0.3rem 1rem;
    padding-right: 1.5rem;
    min-width: max-content;
    border: 3px solid ${(props) => props.theme.color.grey2};
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.font_01};
    cursor: pointer;
    font-weight: 700;
    &:hover {
      color: ${(props) => props.theme.color.cyan};
      transition: 0.3s;
    }
  }
  .isActive {
    color: ${(props) => props.theme.color.cyan};
    border: 3px solid ${(props) => props.theme.color.cyan};
  }
`;

function DashboardTableNav({ isActive, setIsActive, options }) {
  const NavItem = ({ title, value }) => (
    <p onClick={() => setIsActive(value)} className={`nav_item ${isActive === value && 'isActive'}`}>
      {title}
    </p>
  );

  NavItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  return (
    <Wrapper>
      {options.map((option) => (
        <NavItem title={option.title} value={option.value} key={option.value} />
      ))}
    </Wrapper>
  );
}

DashboardTableNav.propTypes = {
  isActive: PropTypes.string.isRequired,
  setIsActive: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
};

export default DashboardTableNav;
