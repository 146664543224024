import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'src/components/common/Button';
import PropTypes from 'prop-types';
import { logoutUser } from 'src/store/actions/userActions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
  .side-bar__link {
    text-decoration: none;
  }
  .side-bar__link > h6 {
    font-size: 1rem;
    line-height: 26px;
    letter-spacing: 0.2px;
    padding: 10px 32px;
    color: ${(props) => props.theme.color.font_02};
    border-bottom: 1px solid ${(props) => props.theme.color.grey2};

    &:hover {
      transition: 0.3s;
      color: ${(props) => props.theme.color.cyan};
    }
  }
  .side-bar__link--isActive > h6 {
    color: ${(props) => props.theme.color.cyan};
    font-weight: 700;
    border-right: 5px solid ${(props) => props.theme.color.background_header};
  }

  .MuiDrawer-paperAnchorLeft {
    transition: all 0.1s ease-in 0s;
    height: 100%;
    background: ${(props) => props.theme.color.white};
    max-width: 100%;
    position: absolute;
    top: 88px;
    width: 300px;
    min-width: 300px;
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }
  .icon {
    transform: scale(1.5);
    display: flex;
    cursor: pointer;

    svg {
      fill: ${(props) => props.theme.color.cyan};
    }
  }
  .logout-button {
    padding: 20px 32px;

    button {
      background-color: ${(props) => props.theme.color.background_header};
      font-weight: bold;
      font-size: 16px;
    }
  }
`;

const MobileMenu = ({ logoutUser }) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  return (
    <Wrapper>
      {open ? (
        <div className="icon">
          <CloseIcon onClick={handleDrawerToggle} />
        </div>
      ) : (
        <div className="icon">
          <MenuIcon onClick={handleDrawerToggle} />
        </div>
      )}
      <Drawer variant="persistent" anchor="left" className="mobile" open={open}>
        <NavLink className="side-bar__link" activeClassName="side-bar__link--isActive" to="/dashboard">
          <h6>Dashboard</h6>
        </NavLink>
        <NavLink className="side-bar__link" activeClassName="side-bar__link--isActive" to="/price_settings">
          <h6>Price Settings</h6>
        </NavLink>
        <NavLink className="side-bar__link" activeClassName="side-bar__link--isActive" to="/pos_mapping">
          <h6>POS Mapping</h6>
        </NavLink>
        <NavLink className="side-bar__link" activeClassName="side-bar__link--isActive" to="/pos_mappings">
          <h6>Ikonik POS Mappings</h6>
        </NavLink>
        <NavLink className="side-bar__link" activeClassName="side-bar__link--isActive" to="/users">
          <h6>Users</h6>
        </NavLink>
        <NavLink className="side-bar__link" activeClassName="side-bar__link--isActive" to="/yawo">
          <h6>Yawo</h6>
        </NavLink>
        <div className="logout-button">
          <Button onClick={() => logoutUser()} endIcon={<ExitToAppIcon />} text="Logout" fullWidth />
        </div>
      </Drawer>
    </Wrapper>
  );
};

MobileMenu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const platform = state.user.profile.platform;

  return { platform };
};

export default connect(mapStateToProps, { logoutUser })(MobileMenu);
