import client from 'src/client';
import { ADD_YAWO_VENDOR, YAWO_VENDOR_IS_LOADING, YAWO_VENDOR_NOT_LOADING } from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from 'src/store/actions/alertActions';

const { authService } = client;

const yawoVendorIsLoading =
  (msg = 'Loading Yawo Vendor') =>
  (dispatch) => {
    dispatch({ type: YAWO_VENDOR_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoVendorNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_VENDOR_NOT_LOADING });
};

const addYawoVendor = (yawoVendor) => {
  return { type: ADD_YAWO_VENDOR, payload: yawoVendor };
};

export const getYawoVendor = (shortCode, callback) => (dispatch) => {
  dispatch({ type: YAWO_VENDOR_IS_LOADING });
  authService
    .get(`/yawo/vendors/${shortCode}`)
    .then((res) => {
      dispatch(addYawoVendor(res?.data?.data));
      if (callback && callback.success) callback.success(res.data);
    })
    .catch(() => {
      if (callback && callback.error) callback.error();
    })
    .finally(() => dispatch(yawoVendorNotLoading()));
};

export const createYawoVendor =
  ({ shortCode }, callbacks) =>
  (dispatch) => {
    dispatch(yawoVendorIsLoading('Creating New Vendor'));
    authService
      .post('/yawo/vendors/create', { shortCode })
      .then((res) => {
        dispatch(successAlert(res?.data?.message));
        if (callbacks?.success) callbacks.success();
        setTimeout(() => {
          dispatch(clearAlert());
        }, 1000);
      })
      .catch((err) => {
        dispatch(errorAlert({ msg: err?.response?.data?.message }));
      })
      .finally(() => dispatch(yawoVendorNotLoading()));
  };

export const getAvailableLoanLimit = (shortCode, callback) => (dispatch) => {
  authService
    .get(`/yawo/vendors/loan-limit/${shortCode}`)
    .then((res) => {
      callback(res?.data?.data);
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error gettting available loan limit' }));
    });
};
