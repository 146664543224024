import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const Wrapper = styled.div`
  width: 100%;
  .label {
    margin: 0;
    font-weight: normal;
    font-size: 1rem;
  }
  .input {
    margin: 0;
    min-width: 100%;
    border: 2px solid ${(props) => props.theme.color.grey2};
    border-radius: 4px;
    padding: 10px;
    max-height: 3.75rem;
    :focus {
      outline-color: ${(props) => props.theme.color.cyan};
    }
  }
  .errorInput {
    border: 1px solid ${(props) => props.theme.color.red};
  }
  .error {
    color: ${(props) => props.theme.color.red};
  }
`;

const NumberInput = ({ label, onChange, value, placeholder, error, name, disabled }) => {
  const displayError = () => (
    <p component="h6" className="error">
      {error && error}
    </p>
  );

  return (
    <Wrapper>
      <p className="label">{label}</p>
      <NumberFormat
        placeholder={placeholder}
        className={`input ${error && 'errorInput'}`}
        name={name}
        value={value}
        onValueChange={(values) => onChange(values.value, name)}
        allowLeadingZeros={true}
        isNumericString
        disabled={disabled}
      />
      {error && displayError()}
    </Wrapper>
  );
};

NumberInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export { NumberInput };
