import React, { useEffect, useState } from 'react';
import AppLayout from 'src/components/layout/AppLayout';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getYawoVendor } from 'src/store/actions/yawo/vendorActions';
import PriceTypeRadio from 'src/components/priceSettings/PriceTypeRadio';
import LoansComponent from 'src/components/yawo/loans/Loans';
import WalletHistoryComponent from 'src/components/yawo/wallet-history/WalletHistories';
import TransactionsComponent from 'src/components/yawo/transactions/Transactions';
import AgentsComponent from 'src/components/yawo/agents/Agents';
import CustomersComponent from 'src/components/yawo/customers/Customers';
import GuarantorsComponent from 'src/components/yawo/guarantors/Guarantors';
import CollectionsComponent from 'src/components/yawo/collections/Collections';

const options = [
  { text: 'Loans', value: 'loans' },
  { text: 'Agents', value: 'agents' },
  { text: 'Customers', value: 'customers' },
  { text: 'Guarantors', value: 'guarantors' },
  { text: 'Wallet', value: 'wallet' },
  { text: 'Transactions', value: 'transactions' },
  { text: 'Collections', value: 'collections' },
];

const Yawo = ({ isLoading, yawoVendor, getYawoVendor, profile }) => {
  const history = useHistory();
  const useQuery = () => new URLSearchParams(history.location.search);
  const yawoWalletIuwi = useQuery().get('yawoWalletIuwi') || '';
  const shortCode = profile.platform;

  const [module, setModule] = useState('');
  const handleAccess = (vendor) => {
    if (vendor?.isVerified && !module) return setModule(options[0].value);
    if (vendor?.isVerified) return;
    if (vendor?.isVerified === false) return history.push('/yawo/onboarding/submitted');
    return history.push('/yawo/onboarding/no-access');
  };

  useEffect(() => {
    if (yawoWalletIuwi) setModule('wallet');
  }, [yawoWalletIuwi]);

  useEffect(() => {
    if (module !== 'wallet') history.push('/yawo');
    if (yawoVendor) return handleAccess(yawoVendor);
    getYawoVendor(shortCode, {
      success: ({ data: vendor }) => handleAccess(vendor),
      error: () => history.push('/yawo/onboarding/no-access'),
    });
  }, [module]);

  let sideHeader = null;
  if (module) {
    sideHeader = <PriceTypeRadio value={module} onChange={setModule} options={options} />;
  }

  const YawoComponents = {
    loans: <LoansComponent />,
    wallet: <WalletHistoryComponent />,
    transactions: <TransactionsComponent />,
    agents: <AgentsComponent />,
    customers: <CustomersComponent />,
    guarantors: <GuarantorsComponent />,
    collections: <CollectionsComponent />,
  };

  return (
    <AppLayout title="Yawo" sideHeader={sideHeader}>
      {!isLoading && YawoComponents[module] ? <>{YawoComponents[module]}</> : <p>Welcome to Yawo</p>}
    </AppLayout>
  );
};

Yawo.propTypes = {
  getYawoVendor: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoVendor: PropTypes.object,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoVendor } = state.yawoVendor;
  const { profile } = state.user;
  return {
    isLoading,
    yawoVendor,
    profile,
  };
};

export default connect(mapStateToProps, { getYawoVendor })(Yawo);
