import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppLayout from 'src/components/layout/AppLayout';
import PriceSettingsComponent from 'src/components/priceSettings/PriceSettings';
import PriceTypeRadio from 'src/components/priceSettings/PriceTypeRadio';
import { connect } from 'react-redux';
import { getMappings } from 'src/store/actions/mappingActions';
import { setCategory } from 'src/store/actions/priceActions';

const options = [
  { text: 'Transfers', value: 'transfers' },
  { text: 'Withdrawals', value: 'withdrawals' },
  { text: 'Airtime', value: 'airtime' },
  { text: 'Data', value: 'data' },
  { text: 'Tv Cable', value: 'tv-cable' },
  { text: 'PHCN', value: 'electricity' },
  { text: 'POS Activation', value: 'pos-activation' },
];

const sortMapping = (mappings) => {
  const result = {};
  Object.keys(mappings).map((key) => {
    result[key] = [];
    return Object.keys(mappings[key]).map((item) => {
      return result[key].push({ title: mappings[key][item], value: item });
    });
  });
  return result;
};

const PriceSettings = ({ getMappings, mappings, isLoading, setCategory, category }) => {
  useEffect(() => {
    getMappings();
  }, [getMappings]);

  const navOptions = sortMapping(mappings)[category];
  const [terminal, setTerminal] = useState('flutterwave');
  return (
    <AppLayout
      title="Price Settings"
      sideHeader={!isLoading && <PriceTypeRadio value={category} onChange={setCategory} options={options} setTerminal={setTerminal}/>}
    >
      {!isLoading ? (
        <PriceSettingsComponent
          category={category}
          navOptions={navOptions}
          navDefault={navOptions && navOptions[0].value}
          setTerminal={setTerminal}
          terminal={terminal}
        />
      ) : (
        <></>
      )}
    </AppLayout>
  );
};

PriceSettings.propTypes = {
  getMappings: PropTypes.func.isRequired,
  setCategory: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  mappings: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { mappings, isLoading } = state.mapping;
  const { category } = state.price;
  return { mappings, isLoading, category };
};

export default connect(mapStateToProps, { getMappings, setCategory })(PriceSettings);
