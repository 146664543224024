import React, { useState } from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { TextInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';

const Wrapper = styled(Paper)`
  padding: 4rem 2rem;
  max-width: 30rem;
  min-width: 25rem;
  .forgot_button {
    margin-top: 1rem;
  }
`;

const ForgotForm = () => {
  const [email, setEmail] = useState('');

  return (
    <Wrapper>
      <form noValidate>
        <TextInput label="Email address" type={email} value={email} onChange={setEmail} />
        <Button className="forgot_button" text="Send reset link" fullWidth={true} />
      </form>
    </Wrapper>
  );
};

export default ForgotForm;
