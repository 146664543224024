import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
// import Button from 'src/components/common/Button';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput } from 'src/components/common/inputs';
import AgentTable from './AgentTable';
import {
  getAllYawoAgents,
  setYawoAgentPage,
  setYawoAgentLimit,
  setYawoAgentDate,
  resetYawoAgentDate,
} from 'src/store/actions/yawo/agentActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time_display {
    min-width: 13rem;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function AgentsComponent({
  getAllYawoAgents,
  isLoading,
  yawoAgents,
  setYawoAgentPage,
  setYawoAgentLimit,
  // setYawoAgentDate,
  // resetYawoAgentDate,
}) {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  const getYawoAgents = () => {
    const query = {};
    getAllYawoAgents(query);
  };

  useEffect(() => {
    getYawoAgents();
  }, []);

  const onPageChange = (page) => {
    setYawoAgentPage(page);
    getYawoAgents();
  };

  const onLimitChange = (limit) => {
    setYawoAgentLimit(limit);
    getYawoAgents();
  };

  // const onDateChange = (date) => {
  //   setYawoAgentDate(date.startDate, date.endDate);
  //   getYawoAgents();
  // };

  // const onResetDateClick = () => {
  //   resetYawoAgentDate();
  //   getYawoAgents();
  // };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard isLoading={isLoading} title="Total Agents" value={yawoAgents !== null && yawoAgents.count} />
        {/* <div className="date_range">
          <Button
            onClick={toggle}
            text={`${yawoAgents === null ? '' : formatDate(yawoAgents.start_date)} - ${
              yawoAgents === null ? '' : formatDate(yawoAgents.end_date)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div> */}
      </div>
      <AgentTable
        isLoading={isLoading}
        tableData={yawoAgents}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

AgentsComponent.propTypes = {
  getAllYawoAgents: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoAgents: PropTypes.object,
  setYawoAgentLimit: PropTypes.func.isRequired,
  setYawoAgentPage: PropTypes.func.isRequired,
  setYawoAgentDate: PropTypes.func.isRequired,
  resetYawoAgentDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoAgents, isLoading } = state.yawoAgent;
  return {
    isLoading,
    yawoAgents,
  };
};

export default connect(mapStateToProps, {
  getAllYawoAgents,
  setYawoAgentLimit,
  setYawoAgentPage,
  setYawoAgentDate,
  resetYawoAgentDate,
})(AgentsComponent);
