export /**
 * this fucntion takes an array of data and a map function to flatten the data
 * and returns a string of csv text
 *
 * @param {Array} data
 * @param {Function} mapFunction
 * @return {String} csv data string
 */
const convertArrayAsCsv = (data, mapFunction) => {
  if (!data.length) return;

  const mappedData = mapFunction ? data.map(mapFunction) : data;

  const headers = Object.keys(mappedData[0] || {});
  const csvheaders = headers.join();
  const csvData = mappedData.map((row) => Object.values(row).join()).join('\n');
  const csvDataWithHeaders = csvheaders + '\n' + csvData;

  return csvDataWithHeaders;
};

export default convertArrayAsCsv;
