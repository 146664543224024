import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LoanLimitIcon from '@material-ui/icons/Edit';
import SuspendIcon from '@material-ui/icons/Cancel';
import { Tooltip } from '@material-ui/core';
import ViewAgent from './ViewAgent';
import UpdateAgentLoanLimit from './UpdateAgentLoanLimit';
import SuspendAgent from './SuspendAgent';
import { getAllYawoAgents } from 'src/store/actions/yawo/agentActions';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .loan_limit {
    color: ${(props) => props.theme.color.cyan};
  }
  .suspend {
    color: ${(props) => props.theme.color.red};
  }
`;

const AgentActions = ({ agent, getAllYawoAgents }) => {
  const [openLoanLimit, setOpenLoanLimit] = useState(false);
  const toggleLoanLimit = () => setOpenLoanLimit(!openLoanLimit);

  const [openSuspend, setOpenSuspend] = useState(false);
  const toggleSuspend = () => setOpenSuspend(!openSuspend);

  return (
    <Wrapper>
      <ViewAgent agentId={agent._id} />
      <Tooltip title="Set Loan Limit">
        <LoanLimitIcon className="icon loan_limit" onClick={toggleLoanLimit} />
      </Tooltip>
      <UpdateAgentLoanLimit
        isOpen={openLoanLimit}
        handleClose={toggleLoanLimit}
        agent={agent}
        onSuccess={getAllYawoAgents}
      />
      {agent?.isActive && (
        <>
          <Tooltip title="Suspend">
            <SuspendIcon className="icon suspend" onClick={toggleSuspend} />
          </Tooltip>
          <SuspendAgent
            isOpen={openSuspend}
            handleClose={toggleSuspend}
            agentId={agent._id}
            onSuccess={getAllYawoAgents}
          />
        </>
      )}
    </Wrapper>
  );
};

AgentActions.propTypes = {
  agent: PropTypes.object.isRequired,
  getAllYawoAgents: PropTypes.func,
};

export default connect(null, { getAllYawoAgents })(AgentActions);
