import { PRICE_IS_LOADING, PRICE_NOT_LOADING, ADD_PRICES, CLEAR_PRICES, SET_CATEGORY } from 'src/store/types';

const initialState = {
  isLoading: false,
  prices: null,
  category: 'transfers',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PRICE_IS_LOADING:
      return { ...state, isLoading: true };
    case PRICE_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_PRICES:
      return { ...state, prices: action.payload };
    case SET_CATEGORY:
      return { ...state, category: action.payload };
    case CLEAR_PRICES:
      return { ...state, prices: initialState.prices };
    default:
      return state;
  }
}
