import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from 'src/components/common/table/Table';
import { overviewHeader } from './loansHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import LoanSearch from './LoanSearch';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Submitted', value: 'submitted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Approved', value: 'approved' },
  { text: 'Ongoing', value: 'ongoing' },
  { text: 'Completed', value: 'completed' },
];

function LoanTable({ tableData, onPageChange, onLimitChange, status, onStatusChange }) {
  const tableKey = {};

  const rightHeader = (
    <SelectInput selectLabel="Status" value={status} onChange={onStatusChange} options={statusOptions} />
  );

  return (
    <Wrapper>
      <Table
        headers={overviewHeader}
        leftHeader={<LoanSearch />}
        rightHeader={rightHeader}
        showNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

LoanTable.propTypes = {
  tableData: PropTypes.object,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  status: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
};

export default LoanTable;
