import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ViewCustomer from './ViewCustomer';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
`;

const CustomerActions = ({ customer }) => {
  return (
    <Wrapper>
      <ViewCustomer customerId={customer._id} />
    </Wrapper>
  );
};

CustomerActions.propTypes = {
  customer: PropTypes.object.isRequired,
};

export default CustomerActions;
