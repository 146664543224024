import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
import NoAccess from 'src/components/yawo/onboarding/NoAccess';

const NoAccessPage = () => {
  return (
    <AppLayout title="Yawo">
      <NoAccess />
    </AppLayout>
  );
};

export default NoAccessPage;
