import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  .label {
    color: ${(props) => props.theme.color.grey};
  }
  .input_section {
    border-radius: 5px;
    height: 2.1rem;
    width: 100%;
    padding: 0rem 0.5rem;
    display: grid;
    grid-template-columns: 1fr max-content;
    align-content: center;
    outline: 0px;
    border: 1px solid ${(props) => props.theme.color.grey};
  }
  .input {
    height: 2rem;
    width: 100%;
    outline: 0px;
    border: 0px;
    :focus {
      border: 0px;
      outline: 0px;
    }
  }
  .icon {
    padding: 0px;
  }
`;

const PasswordInput = ({ label, onChange, value }) => {
  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Wrapper>
      <p className="label">{label}</p>
      <div className="input_section">
        <input
          className="input"
          type={values.showPassword ? 'text' : 'password'}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} className="icon">
          {values.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </div>
    </Wrapper>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export { PasswordInput };
