import client from 'src/client';
import { clearAlert, errorAlert, infoAlert, successAlert } from './alertActions';
import { PRICE_IS_LOADING, PRICE_NOT_LOADING, ADD_PRICES, SET_CATEGORY } from 'src/store/types';

const { authService, appService } = client;

const priceIsLoading =
  (msg = 'Loading Price Settings') =>
  (dispatch) => {
    dispatch({ type: PRICE_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const priceNotLoading = () => (dispatch) => {
  dispatch({ type: PRICE_NOT_LOADING });
  dispatch(clearAlert());
};

const addPrices = (prices) => {
  return { type: ADD_PRICES, payload: prices };
};

export const setCategory = (category) => {
  return { type: SET_CATEGORY, payload: category };
};

export const getPrices = (type, terminal_type) => (dispatch, getState) => {
  dispatch(priceIsLoading());
  const { user, price } = getState();
  const platform = user.profile.platform;
  const category = price.category === 'tv-cable' ? 'tv-cables' : price.category;
  let url = `prices/${category}/${platform}`;

  if (type) {
    url += (category === 'pos-activation') ? '' :`/${type}`;
  }

  if (platform) {
    authService
      .get(url, {
        params: {
          pos_type: type === 'pos' ? terminal_type : undefined,
        },
      })
      .then((res) => {
        if (!Array.isArray(res.data)) {
          dispatch(addPrices([res.data]));
          dispatch(priceNotLoading());
        } else {
          dispatch(addPrices(res.data));
          dispatch(priceNotLoading());
        }
      })
      .catch((err) => {
        dispatch(errorAlert({ msg: err?.response?.data?.message }));
        dispatch(priceNotLoading());
      });
  }
};

export const addNewPrice = (data, terminal_type) => (dispatch, getState) => {
  dispatch(priceIsLoading('Adding New Price'));
  const { user, price } = getState();
  data.platform = user.profile.platform;
  authService
    .post(`prices/${price.category}`, data)
    .then(() => {
      dispatch(getPrices(data.type, terminal_type));
      dispatch(successAlert('Price Successfully Added'));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(priceNotLoading());
    });
};

export const updatePrice = (data, id, type, terminal_type) => (dispatch, getState) => {
  dispatch(priceIsLoading('Updating Price'));
  const price = getState().price;
  const category = price.category === 'tv-cable' ? 'tv-cables' : price.category;
  authService
    .put(`prices/${category}/${id}`, data)
    .then((res) => {
      dispatch(getPrices(type, terminal_type));
      dispatch(successAlert(res.data.message || 'Updated successfully'));
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Something went wrong. Refresh browser' }));
      dispatch(priceNotLoading());
    });
};

export const deletePrice = (id, type, terminal_type) => (dispatch, getState) => {
  dispatch(priceIsLoading('Deleting Price'));
  const category = getState().price.category;
  authService
    .delete(`prices/${category}/${id}`)
    .then((res) => {
      dispatch(getPrices(type, terminal_type));
      dispatch(successAlert(res.data.message));
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Something went wrong. Refresh browser' }));
      dispatch(priceNotLoading());
    });
};

export const getUpPrices = () => (dispatch, getState) => {
  dispatch(priceIsLoading('Fetching Up Pricing'));
  const platform = getState().user.profile.platform;
  appService
    .get(`/mmo/${platform}/price-settings/withdrawals/up`)
    .then((res) => {
      dispatch(addPrices(res.data.data));
      dispatch(priceNotLoading());
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(priceNotLoading());
    });
};

export const updateUpPricing = (data, pricingId) => (dispatch) => {
  dispatch(priceIsLoading('Updating Pricing'));
  appService
    .put(`/mmo/price-settings/withdrawals/${pricingId}/update`, data)
    .then((res) => {
      dispatch(getUpPrices());
      dispatch(successAlert(res.data.message));
      dispatch(priceNotLoading());
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(priceNotLoading());
    });
};
