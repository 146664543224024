import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ViewCollection from './ViewCollection';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .icon {
    cursor: pointer;
  }
  .complete {
    color: ${(props) => props.theme.color.cyan};
  }
`;

const CollectionActions = ({ collection }) => {
  return (
    <Wrapper>
      <ViewCollection collectionId={collection._id} />
    </Wrapper>
  );
};

CollectionActions.propTypes = {
  collection: PropTypes.object.isRequired,
};

export default CollectionActions;
