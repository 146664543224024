import React from 'react';
import { Modal } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MyModal = styled(Modal)`
  border: none;
`;

const Wrapper = styled.div`
  .modal {
    position: absolute;
    background-color: white;
    border: none;
    min-width: 40rem;
    padding: 2rem;
    @media screen and (max-width: 768px) {
      width: 95%;
      min-width: auto;
    }
  }
`;

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-${50}%, -${50}%)`,
    maxHeight: '90vh',
    overflowY: 'auto',
  };
}

export default function AppModal({ isOpen, handleClose, children }) {
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <MyModal open={isOpen} onClose={handleClose} disableBackdropClick={true}>
      <Wrapper>
        <div style={modalStyle} className="modal">
          {children}
        </div>
      </Wrapper>
    </MyModal>
  );
}

AppModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any,
};
