import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import AsyncSelect from 'react-select/async';
import { FixRequiredSelect } from 'src/components/common/inputs';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import Button from 'src/components/common/Button';
import { mapPosToAgent } from 'src/store/actions/posMappingActions';
import { loadAgentOptions } from 'src/store/actions/accountActions';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    margin-bottom: 10px;
    h6 {
      color: ${(props) => props.theme.color.cyan};
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${(props) => props.theme.color.deepGrey};
    transform: scale(1.5);

    &:hover {
      background-color: ${(props) => props.theme.color.lightGrey};
      transition: 0.3s;
    }
  }
  .form {
    margin-bottom: 1rem;
    font-size: 14px;
  }
  .pos-info {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    margin: 1rem 0;
    font-size: 14px;
  }
  .label {
    color: ${(props) => props.theme.color.grey};
    min-width: max-content;
    margin-bottom: 0.25rem;
  }
`;

const MapPosToAgent = ({ isOpen, handleClose, mapPosToAgent, profile, posDevice }) => {
  if (typeof posDevice !== 'object') return null;

  const { platform } = profile || {};
  const { _id: posId } = posDevice || {};
  const [agent, setAgent] = useState(null);

  const onSubmit = (event) => {
    event.preventDefault();
    mapPosToAgent(posId, agent?._id);
    return handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Wrapper>
        <div className="header">
          <div className="header_title">
            <h6>Map POS To Agent</h6>
          </div>

          <div onClick={handleClose} className="cancel">
            <CloseIcon />
          </div>
        </div>

        <div className="pos-info">
          <div>
            <div>
              <b>OS</b>
            </div>
            <div>{posDevice.os}</div>
          </div>

          <div>
            <div>
              <b>Device Name</b>
            </div>
            <div>{posDevice.deviceName}</div>
          </div>

          <div>
            <div>
              <b>Serial Number</b>
            </div>
            <div>{posDevice.serialNumber}</div>
          </div>
        </div>

        <form className="form" onSubmit={onSubmit}>
          <p className="label">Select Agent</p>
          <FixRequiredSelect
            name="agent"
            placeholder="Select Agent"
            cacheOptions
            loadOptions={(val) => loadAgentOptions(val, platform)}
            theme={reactSelectUtil.theme}
            styles={reactSelectUtil.styles}
            isClearable
            SelectComponent={AsyncSelect}
            required
            onChange={(newValue) => setAgent(newValue)}
            value={agent}
          />

          <br />

          <Button type="submit" text="Map POS To Agent" fullWidth={true} />
        </form>
      </Wrapper>
    </Modal>
  );
};

MapPosToAgent.defaultProps = {
  isOpen: false,
};

MapPosToAgent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  mapPosToAgent: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  posDevice: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { profile } = state.user;
  return { profile };
};

export default connect(mapStateToProps, { mapPosToAgent })(MapPosToAgent);
