import {
  OLD_MAPPING_IS_LOADING,
  OLD_MAPPING_NOT_LOADING,
  OLD_ADD_MAPPINGS,
  OLD_CLEAR_MAPPINGS,
  OLD_ADD_POS_MAPPINGS,
  OLD_SET_POS_PAGE,
  OLD_SET_POS_LIMIT,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  mappings: {},
  pos: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OLD_MAPPING_IS_LOADING:
      return { ...state, isLoading: true };
    case OLD_MAPPING_NOT_LOADING:
      return { ...state, isLoading: false };
    case OLD_ADD_MAPPINGS:
      return { ...state, mappings: { ...action.payload } };
    case OLD_ADD_POS_MAPPINGS:
      return { ...state, pos: action.payload };
    case OLD_CLEAR_MAPPINGS:
      return { ...state, mappings: initialState.mappings };
    case OLD_SET_POS_LIMIT:
      return {
        ...state,
        pos: {
          ...state.pos,
          limit: action.payload,
        },
      };
    case OLD_SET_POS_PAGE:
      return {
        ...state,
        pos: {
          ...state.pos,
          page: action.payload,
        },
      };
    default:
      return state;
  }
}
