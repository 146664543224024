import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ViewGuarantor from './ViewGuarantor';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
`;

const GuarantorActions = ({ guarantor }) => {
  return (
    <Wrapper>
      <ViewGuarantor guarantorId={guarantor._id} />
    </Wrapper>
  );
};

GuarantorActions.propTypes = {
  guarantor: PropTypes.object.isRequired,
};

export default GuarantorActions;
