import React, { useEffect, useState } from 'react';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

function SingleSelect({ value, selected, onClick }) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    value === selected ? setIsSelected(true) : setIsSelected(false);
  }, [selected, value]);

  const handleChange = () => {
    isSelected ? onClick('') : onClick(value);
  };

  return (
    <div>
      <Checkbox
        checked={isSelected}
        onChange={handleChange}
        size="small"
        style={{ margin: '0px', padding: '0px', color: '#219DD3' }}
      />
    </div>
  );
}

SingleSelect.propTypes = {
  value: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SingleSelect;
