import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
import UserDashboard from 'src/components/users/Users';

const Users = () => {
  return (
    <AppLayout title="Users">
      <UserDashboard />
    </AppLayout>
  );
};

export default Users;
