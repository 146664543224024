import {
  YAWO_CUSTOMER_IS_LOADING,
  YAWO_CUSTOMER_NOT_LOADING,
  ADD_YAWO_CUSTOMERS,
  ADD_YAWO_CUSTOMER,
  SET_YAWO_CUSTOMER_DATE,
  RESET_YAWO_CUSTOMER_DATE,
  SET_YAWO_CUSTOMER_LIMIT,
  SET_YAWO_CUSTOMER_PAGE,
  SET_YAWO_CUSTOMER_STATUS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoCustomers: null,
  yawoCustomer: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_CUSTOMER_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_CUSTOMER_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_CUSTOMERS:
      return { ...state, yawoCustomers: action.payload };
    case ADD_YAWO_CUSTOMER:
      return { ...state, yawoCustomer: action.payload };
    case SET_YAWO_CUSTOMER_DATE:
      return {
        ...state,
        yawoCustomers: {
          ...state.yawoCustomers,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_YAWO_CUSTOMER_DATE:
      return {
        ...state,
        yawoCustomers: {
          ...state.yawoCustomers,
          start_date: null,
          end_date: null,
          page: 1,
        },
      };
    case SET_YAWO_CUSTOMER_LIMIT:
      return {
        ...state,
        yawoCustomers: {
          ...state.yawoCustomers,
          limit: action.payload,
          page: 1,
        },
      };
    case SET_YAWO_CUSTOMER_PAGE:
      return {
        ...state,
        yawoCustomers: {
          ...state.yawoCustomers,
          page: action.payload,
        },
      };
    case SET_YAWO_CUSTOMER_STATUS:
      return {
        ...state,
        yawoCustomers: {
          ...state.yawoCustomers,
          status: action.payload,
          page: 1,
        },
      };
    default:
      return state;
  }
}
