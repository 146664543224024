import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UserVerificationStatus from './UserVerificationStatus';

const Wrapper = styled.div`
  .item {
    display: flex;
    width: max-content;
    padding: 0;
    align-items: center;
  }

  .text {
    font-size: 0.8rem;
  }

  .success_text {
    color: ${(props) => props.theme.color.green2};
  }
  .failed_text {
    color: ${(props) => props.theme.color.red};
  }
`;

function UserStatus({ user }) {
  const displayIsNotActive = () => (
    <div className="item">
      <p className="text failed_text">NOT ACTIVE</p>
    </div>
  );

  const displayIsActive = () => (
    <div className="item">
      <p className="text success_text">ACTIVE</p>
    </div>
  );

  return (
    <Wrapper>
      <UserVerificationStatus user={user} />
      {user?.isActive ? displayIsActive() : displayIsNotActive()}
    </Wrapper>
  );
}

UserStatus.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserStatus;
