import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getTransaction } from 'src/store/actions/transactionActions';
import Loader from 'src/components/notification/Loader';
import { formatAmount, dateFormatYMD, formatTime } from 'src/utils/helpers';

const StyledIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.color.grey};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    margin-bottom: 10px;
    h6 {
      color: ${(props) => props.theme.color.cyan};
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${(props) => props.theme.color.deepGrey};
    transform: scale(1.5);

    &:hover {
      background-color: ${(props) => props.theme.color.lightGrey};
      transition: 0.3s;
    }
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
  }
  .item {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    color: black;
    margin-bottom: 0.5rem;
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ViewTransaction = ({ transactionRef, transaction, getTransaction, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getTransaction(transactionRef);
    }
  }, [isOpen, getTransaction, transactionRef]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Tooltip title="View Transaction">
        <StyledIcon onClick={toggleModal} />
      </Tooltip>
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Transaction Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div className="item">
                <p>Amount</p>
                <h6 className="value">{formatAmount(transaction && transaction.amount)}</h6>
              </div>
              <div className="item">
                <p>Transaction Type</p>
                <h6 className="value">{transaction && transaction.type}</h6>
              </div>
              <div className="item">
                <p>Platform</p>
                <h6 className="value">{transaction && transaction.platform.toUpperCase()}</h6>
              </div>
              <div className="item">
                <p>User</p>
                <Link to={`/users/${transaction && transaction.userId}`}>
                  <h6 className="value">{transaction && transaction.user}</h6>
                </Link>
              </div>
              <div className="item">
                <p>Email</p>
                <h6 className="value">{transaction && transaction.email}</h6>
              </div>
              <div className="item">
                <p>Date</p>
                <h6 className="value">{transaction && dateFormatYMD(transaction.timeCreated)}</h6>
              </div>
              <div className="item">
                <p>Time</p>
                <h6 className="value">{transaction && formatTime(transaction.timeCreated)}</h6>
              </div>
              <div className="item">
                <p>Status</p>
                <h6 className="value">{transaction && transaction.status}</h6>
              </div>
              <div className="item">
                <p>Transaction Reference</p>
                <h6 className="value">{transaction && transaction.transactionRef}</h6>
              </div>
              {transaction && transaction.type === 'POS_WALLET_TOPUP' && (
                <React.Fragment>
                  <div className="item">
                    <p>Vendor Reference</p>
                    <h6 className="value">{transaction && transaction.vendorRef}</h6>
                  </div>
                  <div className="item">
                    <p>Transaction Fee</p>
                    <h6 className="value">{formatAmount(transaction && transaction.totalCharge)}</h6>
                  </div>
                  <div className="item">
                    <p>Card</p>
                    <h6 className="value">{transaction && transaction.card}</h6>
                  </div>
                </React.Fragment>
              )}
              {transaction && transaction.billingType === 'AIRTIME' && (
                <React.Fragment>
                  <div className="item">
                    <p>Phone Number</p>
                    <h6 className="value">{transaction && transaction.meta.phoneNumber}</h6>
                  </div>
                  <div className="item">
                    <p>Product Code</p>
                    <h6 className="value">{transaction && transaction.meta.productCode}</h6>
                  </div>
                </React.Fragment>
              )}
              {transaction && transaction.type === 'CASHOUT' && (
                <React.Fragment>
                  <div className="item">
                    <p>Transaction Fee</p>
                    <h6 className="value">{formatAmount(Number(transaction.totalCharge))}</h6>
                  </div>
                  <div className="item">
                    <p>Account Number</p>
                    <h6 className="value">{transaction && transaction.meta.accountNumber}</h6>
                  </div>
                  <div className="item">
                    <p>Account Bank</p>
                    <h6 className="value">{transaction && transaction.meta.accountBank}</h6>
                  </div>
                  <div className="item">
                    <p>Receiver</p>
                    <h6 className="value">{transaction && transaction.receiver}</h6>
                  </div>
                </React.Fragment>
              )}
              {/* 	<div>
								<Button text='View Transactions' fullWidth={true}/>
							</div> */}
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewTransaction.propTypes = {
  transaction: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getTransaction: PropTypes.func.isRequired,
  transactionRef: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, transaction } = state.transaction;
  return { isLoading, transaction };
};

export default connect(mapStateToProps, { getTransaction })(ViewTransaction);
