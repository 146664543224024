import axios from 'axios';
import keys from 'src/utils/keys';

const { authService, walletService, userService, appService } = keys;

const clients = {};

clients.authService = axios.create({
  baseURL: authService.server,
  auth: authService.auth,
});

clients.walletService = axios.create({
  baseURL: walletService.server,
  auth: walletService.auth,
});

clients.userService = axios.create({
  baseURL: userService.server,
  auth: userService.auth,
});

clients.appService = axios.create({
  baseURL: `${appService.server}/dashboards`,
  auth: appService.auth,
});

export default Object.freeze(clients);
