import React from 'react';
import { TablePagination } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  .MuiTablePagination-caption {
    font-size: 1rem;
    font-weight: bold;
    font-family: satoshi;
  }
`;

function Pagination({ page, count, limit, onLimitChange, onNextPageClick }) {
  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    onNextPageClick && onNextPageClick(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    event.preventDefault();
    onLimitChange && onLimitChange(event.target.value);
  };

  return (
    <Wrapper>
      <TablePagination
        component="div"
        page={Number(page) - 1}
        count={Number(count)}
        onChangePage={handleChangePage}
        rowsPerPage={Number(limit)}
        rowsPerPageOptions={[2, 5, 10, 20, 50]}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Wrapper>
  );
}

Pagination.propTypes = {
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onLimitChange: PropTypes.func.isRequired,
  onNextPageClick: PropTypes.func.isRequired,
};

export default Pagination;
