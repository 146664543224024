import client from 'src/client';
import { clearAlert, errorAlert, infoAlert, successAlert } from './alertActions';
import {
  OLD_MAPPING_IS_LOADING,
  OLD_MAPPING_NOT_LOADING,
  OLD_ADD_MAPPINGS,
  OLD_ADD_POS_MAPPINGS,
  OLD_CLEAR_MAPPINGS,
  OLD_SET_POS_LIMIT,
  OLD_SET_POS_PAGE,
} from 'src/store/types';
import { dateFormatYMD } from 'src/utils/helpers';

const { authService } = client;

const mappingIsLoading =
  (msg = 'Loading Mappings') =>
  (dispatch) => {
    dispatch({ type: OLD_MAPPING_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const mappingNotLoading = () => (dispatch) => {
  dispatch({ type: OLD_MAPPING_NOT_LOADING });
  dispatch(clearAlert());
};

const addMappings = (mappings) => {
  return { type: OLD_ADD_MAPPINGS, payload: mappings };
};

const addPosMappings = (mappings) => {
  return { type: OLD_ADD_POS_MAPPINGS, payload: mappings };
};

const clearMappings = () => {
  return { type: OLD_CLEAR_MAPPINGS };
};

export const getMappings = () => (dispatch) => {
  dispatch(mappingIsLoading());
  dispatch(clearMappings());
  authService
    .get('/mappings')
    .then((res) => {
      dispatch(addMappings(res.data));
      dispatch(mappingNotLoading());
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(mappingNotLoading());
    });
};

export const setPosPage = (page) => {
  return { type: OLD_SET_POS_PAGE, payload: page };
};

export const setPosLimit = (limit) => {
  return { type: OLD_SET_POS_LIMIT, payload: limit };
};

const generatePath = (endPoint, pos) => {
  if (pos === null) {
    return endPoint;
  }

  const { start_date, end_date, limit, page } = pos;

  if (start_date === null || end_date === null) {
    return `${endPoint}?limit=${limit}&page=${page}`;
  }

  return `${endPoint}?limit=${limit}&page=${page}&start_date=${dateFormatYMD(start_date)}&end_date=${dateFormatYMD(
    end_date
  )}`;
};

export const getPosMappings = () => (dispatch, getState) => {
  dispatch(mappingIsLoading());
  const { platform } = getState().user.profile;
  const { pos } = getState().mapping;
  const path = generatePath(`/mappings/pos/${platform}`, pos);
  authService
    .get(path)
    .then((res) => {
      dispatch(addPosMappings(res.data));
      dispatch(mappingNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(mappingNotLoading());
    });
};

export const addNewPosMapping = (data) => (dispatch, getState) => {
  dispatch(mappingIsLoading('Mapping New POS'));
  const { platform } = getState().user.profile;
  data.platform = platform;
  authService
    .post('/mappings/pos', data)
    .then((res) => {
      dispatch(getPosMappings());
      dispatch(successAlert(res.data.success));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(mappingNotLoading());
    });
};

export const updatePosMapping = (mappingId, data) => (dispatch) => {
  dispatch(mappingIsLoading('Updating Pos Mapping'));
  authService
    .put(`/mappings/pos/${mappingId}`, data)
    .then((res) => {
      dispatch(getPosMappings());
      dispatch(successAlert(res.data.message));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(mappingNotLoading());
    });
};

export const deletePosMapping = (mappingId) => (dispatch) => {
  dispatch(mappingIsLoading('Deleting Pos Mapping'));
  authService
    .delete(`/mappings/pos/${mappingId}`)
    .then((res) => {
      dispatch(getPosMappings());
      dispatch(successAlert(res.data.message));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(mappingNotLoading());
    });
};
