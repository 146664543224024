import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
//import PropTypes from 'prop-types';
//import PosHeaderForm from 'src/components/posMappingsNew/PosHeaderForm';
import PosMappingTable from 'src/components/posMappingsNew/PosMappingTable';

function PosMappings() {
  return (
    <AppLayout title="POS Mappings">
      <PosMappingTable />
    </AppLayout>
  );
}

PosMappings.propTypes = {};

export default PosMappings;
