import React from 'react';
import AuthLayout from 'src/components/layout/AuthLayout';
//import PropTypes from 'prop-types'
import LoginForm from 'src/components/auth/LoginForm';

function Login() {
  return (
    <AuthLayout>
      <LoginForm />
    </AuthLayout>
  );
}

Login.propTypes = {};

export default Login;
