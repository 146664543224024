import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Table from 'src/components/common/table/Table';
import { posMappingHeader } from './posMappingHeader';
import { getPosMappings, setPosMappingLimit, setPosMappingPage } from 'src/store/actions/posMappingActions';
import DownloadPosMappings from './DownloadPosMappings';

const Wrapper = styled.div`
  margin-top: 1rem;

  .justify-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
`;

function PosMappingTable({ posMappings, getPosMappings, setPosMappingLimit, setPosMappingPage }) {
  useEffect(() => {
    getPosMappings();
  }, [getPosMappings]);

  const onPageChange = (page) => {
    setPosMappingPage(page);
    getPosMappings();
  };

  const onLimitChange = (limit) => {
    setPosMappingLimit(limit);
    getPosMappings();
  };

  return (
    <Wrapper>
      <div className="justify-right">
        <DownloadPosMappings />
      </div>
      <Table
        rightHeader={null}
        headers={posMappingHeader}
        data={posMappings === null ? [] : posMappings.data}
        showNav={true}
        limit={posMappings === null ? 20 : +posMappings.limit}
        page={posMappings === null ? 1 : +posMappings.page}
        count={posMappings === null ? 1 : +posMappings.count}
        onLimitChange={onLimitChange}
        onPageChange={onPageChange}
      />
    </Wrapper>
  );
}

PosMappingTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPosMappings: PropTypes.func.isRequired,
  setPosMappingLimit: PropTypes.func.isRequired,
  setPosMappingPage: PropTypes.func.isRequired,
  posMappings: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { posMappings, isLoading } = state.posMapping;
  return { posMappings, isLoading };
};

export default connect(mapStateToProps, { getPosMappings, setPosMappingLimit, setPosMappingPage })(PosMappingTable);
