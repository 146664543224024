import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
import Submitted from 'src/components/yawo/onboarding/Submitted';

const SubmittedPage = () => {
  return (
    <AppLayout title="Yawo">
      <Submitted />
    </AppLayout>
  );
};

export default SubmittedPage;
