import {
  YAWO_WALLET_HISTORY_IS_LOADING,
  YAWO_WALLET_HISTORY_NOT_LOADING,
  ADD_YAWO_WALLET_HISTORIES,
  ADD_YAWO_WALLET_HISTORY,
  CLEAR_YAWO_WALLET_HISTORIES,
  SET_YAWO_WALLET_HISTORY_DATE,
  SET_YAWO_WALLET_HISTORY_LIMIT,
  SET_YAWO_WALLET_HISTORY_PAGE,
  SET_YAWO_WALLET_HISTORY_STATUS,
  RESET_YAWO_WALLET_HISTORY_DATE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoWalletHistories: null,
  yawoWalletHistory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_WALLET_HISTORY_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_WALLET_HISTORY_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_WALLET_HISTORIES:
      return { ...state, yawoWalletHistories: action.payload };
    case ADD_YAWO_WALLET_HISTORY:
      return { ...state, yawoWalletHistory: action.payload };
    case CLEAR_YAWO_WALLET_HISTORIES:
      return { ...state, yawoWalletHistories: initialState.yawoWalletHistories };
    case SET_YAWO_WALLET_HISTORY_DATE:
      return {
        ...state,
        yawoWalletHistories: {
          ...state.yawoWalletHistories,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          page: 1,
        },
      };
    case RESET_YAWO_WALLET_HISTORY_DATE:
      return {
        ...state,
        yawoWalletHistories: {
          ...state.yawoWalletHistories,
          startDate: null,
          endDate: null,
          page: 1,
        },
      };
    case SET_YAWO_WALLET_HISTORY_LIMIT:
      return {
        ...state,
        yawoWalletHistories: {
          ...state.yawoWalletHistories,
          limit: action.payload,
          page: 1,
        },
      };
    case SET_YAWO_WALLET_HISTORY_PAGE:
      return {
        ...state,
        yawoWalletHistories: {
          ...state.yawoWalletHistories,
          page: action.payload,
        },
      };
    case SET_YAWO_WALLET_HISTORY_STATUS:
      return {
        ...state,
        yawoWalletHistories: {
          ...state.yawoWalletHistories,
          status: action.payload,
          page: 1,
        },
      };
    default:
      return state;
  }
}
