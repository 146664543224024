import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .inline {
    display: flex;
    align-items: center;
  }
  .input {
    height: 2.1rem;
    width: 100%;
    padding: 0rem 0.5rem;
    outline: 0px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.color.grey};
    :focus {
      border: 1px solid ${(props) => props.theme.color.grey};
    }
  }
  .label {
    color: ${(props) => props.theme.color.grey};
    min-width: max-content;
  }
`;

function TextInput({ type, onChange, value, label, formType, required }) {
  return (
    <Wrapper>
      <div className={formType === 'inline' ? 'inline' : ''}>
        <p className="label">{label}</p>
        <input
          type={type}
          className="input"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          required={required}
        />
      </div>
    </Wrapper>
  );
}

Text.defaultProps = {
  type: 'text',
  formType: 'block',
};

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  formType: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export { TextInput };
