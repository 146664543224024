import React from 'react';
import PosActions from './PosActions';
import { Link } from 'react-router-dom';
import PosStatus from './PosStatus';

export const posMappingHeader = [
  {
    title: function title() {
      return <p>Serial Number</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['serialNumber']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      const { pos: posId, agentId } = rowData?.mapping?.agentMapping || {};
      return posId && agentId ? <PosStatus status="mapped" /> : <PosStatus status="not mapped" />;
    },
  },
  {
    title: function title() {
      return <p>Agent</p>;
    },
    render: function DateTaken(rowData) {
      const { agentId } = rowData?.mapping?.agentMapping || {};
      return agentId ? <Link to={`/users/${agentId}`}>view agent</Link> : <span>nil</span>;
    },
  },
  {
    title: function title() {
      return <p>Device Activation</p>;
    },
    render: function DateTaken(rowData) {
      const { status } = rowData?.mapping?.isActivated || {};
      return status ? <PosStatus status="actived" /> : <PosStatus status="not actived" />;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function DateTaken(rowData) {
      return <PosActions data={rowData} />;
    },
  },
];
