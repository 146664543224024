import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { NumberInput, SelectInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
import { addNewPrice } from 'src/store/actions/priceActions';
import { connect } from 'react-redux';
import { Grid, Divider } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';

import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    margin-bottom: 10px;
    h6 {
      color: ${(props) => props.theme.color.cyan};
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${(props) => props.theme.color.deepGrey};
    transform: scale(1.5);

    &:hover {
      background-color: ${(props) => props.theme.color.lightGrey};
      transition: 0.3s;
    }
  }
  .form {
    margin-bottom: 1rem;
    padding-top: 1rem;
  }
  .range_input {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
  }
  .divider {
    margin-top: 1rem;
  }
`;

const typeOptions = [
  { text: 'Select Type', value: 'select' },
  { text: 'Fixed', value: 'fixed' },
  { text: 'percentage', value: 'percentage' },
  { text: '% Capped at Fixed', value: 'percentage_fixed' },
];

const NewWithdrawalPrice = ({ isOpen, handleClose, addNewPrice, type, terminal }) => {
  const [platformUserFixedCharge, setPlatformUserFixedCharge] = useState('');
  const [platformUserPercentageCharge, setPlatformUserPercentageCharge] = useState('');
  const [platformAgentFixedCharge, setPlatformAgentFixedCharge] = useState('');
  const [platformAgentPercentageCharge, setPlatformAgentPercentageCharge] = useState('');
  const [userPricingChoice, setUserPricingChoice] = useState('select');
  const [agentPricingChoice, setAgentPricingChoice] = useState('select');
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  const resetState = () => {
    setPlatformUserFixedCharge('');
    setPlatformUserPercentageCharge('');
    setPlatformAgentFixedCharge('');
    setPlatformAgentPercentageCharge('');
    setUserPricingChoice('select');
    setAgentPricingChoice('select');
    setMin('');
    setMax('');
  };

  const closeModal = () => {
    handleClose();
    resetState();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const data = {
      platformUserFixedCharge,
      platformUserPercentageCharge,
      platformAgentFixedCharge,
      userPricingChoice,
      agentPricingChoice,
      platformAgentPercentageCharge,
      min,
      max,
      type,
      posType: terminal,
    };
    addNewPrice(data, terminal);
    return closeModal();
  };

  const onAgentChoiceChange = (choice) => {
    setPlatformAgentFixedCharge('');
    setPlatformAgentPercentageCharge('');
    setAgentPricingChoice(choice);
  };

  const onUserChoiceChange = (choice) => {
    setPlatformUserFixedCharge('');
    setPlatformUserPercentageCharge('');
    setUserPricingChoice(choice);
  };

  return (
    <Modal isOpen={isOpen} handleClose={closeModal}>
      <Wrapper>
        <div className="header">
          <div className="header_title">
            <h6>New Withdrawal Price</h6>
          </div>

          <div onClick={closeModal} className="cancel">
            <CloseIcon />
          </div>
        </div>
        <div className="form">
          <div>
            <p>Range</p>
            <div className="range_input">
              <NumberInput value={min} onChange={setMin} />
              <RemoveIcon />
              <NumberInput value={max} onChange={setMax} />
            </div>
          </div>
          <Divider className="divider" />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <SelectInput
                label="User Settings Type"
                options={typeOptions}
                onChange={onUserChoiceChange}
                value={userPricingChoice}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {userPricingChoice !== 'select' && userPricingChoice !== 'fixed' && (
              <Grid item xs={12} sm={6}>
                <NumberInput
                  label="Percentage User Charge"
                  value={platformUserPercentageCharge}
                  onChange={setPlatformUserPercentageCharge}
                />
              </Grid>
            )}
            {userPricingChoice !== 'select' && userPricingChoice !== 'percentage' && (
              <Grid item xs={12} sm={6}>
                <NumberInput
                  label="Fixed User Charge"
                  value={platformUserFixedCharge}
                  onChange={setPlatformUserFixedCharge}
                />
              </Grid>
            )}
          </Grid>
          <Divider className="divider" />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <SelectInput
                label="Agent Settings Type"
                options={typeOptions}
                onChange={onAgentChoiceChange}
                value={agentPricingChoice}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {agentPricingChoice !== 'select' && agentPricingChoice !== 'fixed' && (
              <Grid item xs={12} sm={6}>
                <NumberInput
                  label="Percentage Agent Charge"
                  value={platformAgentPercentageCharge}
                  onChange={setPlatformAgentPercentageCharge}
                />
              </Grid>
            )}
            {agentPricingChoice !== 'select' && agentPricingChoice !== 'percentage' && (
              <Grid item xs={12} sm={6}>
                <NumberInput
                  label="Fixed Agent Charge"
                  value={platformAgentFixedCharge}
                  onChange={setPlatformAgentFixedCharge}
                />
              </Grid>
            )}
          </Grid>
        </div>
        <Button text="Set New Price" fullWidth={true} onClick={onSubmit} />
      </Wrapper>
    </Modal>
  );
};

NewWithdrawalPrice.defaultProps = {
  isOpen: false,
};

NewWithdrawalPrice.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addNewPrice: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  terminal: PropTypes.string.isRequired,
};

export default connect(null, { addNewPrice })(NewWithdrawalPrice);
