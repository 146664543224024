import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .item {
    display: flex;
    width: max-content;
    padding: 0;
    align-items: center;
  }
  .active_text {
    color: ${(props) => props.theme.color.green2};
  }
  .suspended_text {
    color: ${(props) => props.theme.color.red};
  }
`;

function UserAccountStatus({ status }) {
  const displaySuspended = () => (
    <div className="item">
      <p className="suspended_text">suspended</p>
    </div>
  );

  const displayActive = () => (
    <div className="item">
      <p className="active_text">active</p>
    </div>
  );

  return (
    <Wrapper>
      {status === 'active' && displayActive()}
      {status === 'suspended' && displaySuspended()}
    </Wrapper>
  );
}

UserAccountStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default UserAccountStatus;
