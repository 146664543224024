import React from 'react';
//import PropTypes from 'prop-types';
import AppLayout from 'src/components/layout/AppLayout';
import PosTransactionsComponent from 'src/components/pos/PosTransactions';

function Dashboard() {
  return (
    <AppLayout title="POS Transactions">
      <PosTransactionsComponent />
    </AppLayout>
  );
}

Dashboard.propTypes = {};

export default Dashboard;
