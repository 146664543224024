import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Pagination from './Pagination';

const Wrapper = styled.div`
  padding: 20px 30px;
  background-color: ${(props) => props.theme.color.white};
  .top_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .scrolly-table {
    overflow-x: auto;
  }
  .content,
  .th {
    min-width: max-content;
    padding: 12px;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
    margin: 1rem 0;
  }
  .thead {
    text-align: left;
    min-width: 20rem;
    background: ${(props) => props.theme.color.background_table};
    color: ${(props) => props.theme.color.font_02};
  }
  tr:nth-child(even) {
    background: ${(props) => props.theme.color.background_table};
  }
`;

function Table({ headers, data, page, limit, count, onPageChange, onLimitChange, leftHeader, rightHeader, showNav }) {
  return (
    <Wrapper>
      <div className="top_actions">
        <div>{leftHeader}</div>
        <div>{rightHeader}</div>
      </div>
      <div className="scrolly-table">
        <table className="table">
          <thead className="thead">
            <tr>
              {headers.map((headerItem, index) => (
                <th nowrap="true" className="th" key={index}>
                  {headerItem.title(index)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                {headers.map((col, key) => (
                  <td nowrap="true" className="content" key={key}>
                    {col.render(item, index)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showNav && (
        <Pagination
          page={page}
          limit={limit}
          count={count}
          onNextPageClick={onPageChange}
          onLimitChange={onLimitChange}
        />
      )}
    </Wrapper>
  );
}

Table.defaultProps = {
  headers: [],
  data: [],
  showNav: false,
};

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showNav: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func,
  onLimitChange: PropTypes.func,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
};

export default Table;
