import React from 'react';
import PropTypes from 'prop-types';
import Table from 'src/components/common/table/Table';
import { userTableHeader } from './userTableHeader';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import UserSearch from './UserSearch';

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Active', value: 'active' },
  { text: 'Suspended', value: 'suspended' },
];

const UserTable = ({ tableData, onPageChange, onLimitChange, onStatusChange }) => {
  const rightHeader = (
    <SelectInput
      value={tableData === null ? 'all' : tableData.status}
      onChange={onStatusChange}
      options={statusOptions}
    />
  );

  return (
    <div>
      <Table
        headers={userTableHeader}
        rightHeader={rightHeader}
        leftHeader={<UserSearch />}
        data={tableData === null ? [] : sortTableData(tableData.data, {})}
        limit={tableData && tableData.limit}
        page={tableData && tableData.page}
        count={tableData && tableData.count}
        showNav={true}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

UserTable.defaultProps = {
  tableData: { data: [], limit: 0, page: 1, count: 0 },
};

UserTable.propTypes = {
  tableData: PropTypes.object,
  onPageChange: PropTypes.func.isRequired,
  onLimitChange: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
  platform: PropTypes.string,
};

export default UserTable;
