import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
// import Button from 'src/components/common/Button';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput } from 'src/components/common/inputs';
import CustomerTable from './CustomerTable';
import {
  getAllYawoCustomers,
  setYawoCustomerPage,
  setYawoCustomerLimit,
  setYawoCustomerDate,
  resetYawoCustomerDate,
} from 'src/store/actions/yawo/customerActions';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadAgentOptions } from 'src/store/actions/yawo/agentActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time_display {
    min-width: 13rem;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function CustomersComponent({
  getAllYawoCustomers,
  isLoading,
  yawoCustomers,
  setYawoCustomerPage,
  setYawoCustomerLimit,
  // setYawoCustomerDate,
  // resetYawoCustomerDate,
  profile,
}) {
  const shortCode = profile.platform;
  const [agent, setAgent] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  const getYawoCustomers = () => {
    const query = {};
    if (agent?.value) {
      query.agentId = agent?.value;
    }
    getAllYawoCustomers(query);
  };

  useEffect(() => {
    getYawoCustomers();
  }, [agent]);

  const onPageChange = (page) => {
    setYawoCustomerPage(page);
    getYawoCustomers();
  };

  const onLimitChange = (limit) => {
    setYawoCustomerLimit(limit);
    getYawoCustomers();
  };

  // const onDateChange = (date) => {
  //   setYawoCustomerDate(date.startDate, date.endDate);
  //   getYawoCustomers();
  // };

  // const onResetDateClick = () => {
  //   resetYawoCustomerDate();
  //   getYawoCustomers();
  // };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard
          isLoading={isLoading}
          title="Total Customers"
          value={yawoCustomers !== null && yawoCustomers.count}
        />
        {/* <div className="date_range">
          <Button
            onClick={toggle}
            text={`${yawoCustomers === null ? '' : formatDate(yawoCustomers.start_date)} - ${
              yawoCustomers === null ? '' : formatDate(yawoCustomers.end_date)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div> */}
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem 0', fontSize: '1rem' }}>
        <h4>Agent:</h4>
        <div style={{ width: '20rem' }}>
          <AsyncSelect
            name="agent"
            placeholder="Select Agent"
            cacheOptions
            loadOptions={(val) => loadAgentOptions(val, shortCode)}
            theme={reactSelectUtil.theme}
            styles={reactSelectUtil.styles}
            isClearable
            onChange={(newValue) => setAgent(newValue)}
            value={agent}
          />
        </div>
      </div>

      <CustomerTable
        isLoading={isLoading}
        tableData={yawoCustomers}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

CustomersComponent.propTypes = {
  getAllYawoCustomers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoCustomers: PropTypes.object,
  setYawoCustomerLimit: PropTypes.func.isRequired,
  setYawoCustomerPage: PropTypes.func.isRequired,
  setYawoCustomerDate: PropTypes.func.isRequired,
  resetYawoCustomerDate: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { yawoCustomers, isLoading } = state.yawoCustomer;
  const { profile } = state.user;
  return {
    isLoading,
    yawoCustomers,
    profile,
  };
};

export default connect(mapStateToProps, {
  getAllYawoCustomers,
  setYawoCustomerLimit,
  setYawoCustomerPage,
  setYawoCustomerDate,
  resetYawoCustomerDate,
})(CustomersComponent);
