import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
import Terms from 'src/components/yawo/onboarding/Terms';

const TermsPage = () => {
  return (
    <AppLayout title="Yawo">
      <Terms />
    </AppLayout>
  );
};

export default TermsPage;
