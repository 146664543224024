import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ConfirmDelete from './ConfirmDelete';
import Button from 'src/components/common/Button';

function DeletePrice({ category, type, data, resetSelected, terminal }) {
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const toggleDelete = () => {
    setDeleteIsOpen(!deleteIsOpen);
  };

  const deletableCategories = ['withdrawals', 'transfers'];

  return (
    <div>
      {deletableCategories.includes(category) && <Button text="Delete" onClick={toggleDelete} theme="red" />}
      <ConfirmDelete
        resetSelected={resetSelected}
        isOpen={deleteIsOpen}
        handleClose={toggleDelete}
        type={type}
        data={data}
        terminal={terminal}
      />
    </div>
  );
}

DeletePrice.propTypes = {
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  resetSelected: PropTypes.func.isRequired,
  terminal: PropTypes.func.isRequired,
};

export default DeletePrice;
