import { PLATFORM_IS_LOADING, PLATFORM_NOT_LOADING, ADD_PLATFORMS } from 'src/store/types';

const initialState = {
  isLoading: true,
  platforms: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PLATFORM_IS_LOADING:
      return { ...state, isLoading: true };
    case PLATFORM_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_PLATFORMS:
      return { ...state, platforms: action.payload };
    default:
      return state;
  }
}
