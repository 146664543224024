import React from 'react';
import { formatAmount } from 'src/utils/helpers';
import CollectionActions from './CollectionActions';
import CollectionStatus from './CollectionStatus';

export const overviewHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Day</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData?.day}</p>;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData?.collectionDate}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <CollectionStatus status={rowData['collectionStatus']} />;
    },
  },
  {
    title: function title() {
      return <p>Collection Ref</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['collectionRef']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function SerialNumber(rowData) {
      return <CollectionActions collection={rowData} />;
    },
  },
];
