import React from 'react';
import { formatAmount, formatDate, formatTime } from 'src/utils/helpers';
import TransactionStatus from './TransactionStatus';
import TransactionActions from './TransactionActions';

export const overviewHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Type</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['transactionType']?.replaceAll('_', ' ') || ''}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <TransactionStatus status={rowData['status']} />;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function SerialNumber(rowData) {
      return <TransactionActions transaction={rowData} />;
    },
  },
];
