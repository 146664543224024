import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_WALLET_HISTORIES,
  ADD_YAWO_WALLET_HISTORY,
  YAWO_WALLET_HISTORY_IS_LOADING,
  YAWO_WALLET_HISTORY_NOT_LOADING,
  SET_YAWO_WALLET_HISTORY_DATE,
  RESET_YAWO_WALLET_HISTORY_DATE,
  SET_YAWO_WALLET_HISTORY_LIMIT,
  SET_YAWO_WALLET_HISTORY_STATUS,
  SET_YAWO_WALLET_HISTORY_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert } from 'src/store/actions/alertActions';
import { dateFormatYMD } from 'src/utils/helpers';

const { authService } = client;

const yawoWalletHistoryIsLoading =
  (msg = 'Loading Yawo Wallet Histories') =>
  (dispatch) => {
    dispatch({ type: YAWO_WALLET_HISTORY_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoWalletHistoryNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_WALLET_HISTORY_NOT_LOADING });
};

const addYawoWalletHistories = (yawoWalletHistories) => {
  return { type: ADD_YAWO_WALLET_HISTORIES, payload: yawoWalletHistories };
};

const addYawoWalletHistory = (yawoWalletHistory) => {
  return { type: ADD_YAWO_WALLET_HISTORY, payload: yawoWalletHistory };
};

export const setYawoWalletHistoryDate = (startDate, endDate) => {
  return { type: SET_YAWO_WALLET_HISTORY_DATE, payload: { startDate, endDate } };
};

export const resetYawoWalletHistoryDate = () => {
  return { type: RESET_YAWO_WALLET_HISTORY_DATE };
};

export const setYawoWalletHistoryPage = (page) => {
  return { type: SET_YAWO_WALLET_HISTORY_PAGE, payload: page };
};

export const setYawoWalletHistoryLimit = (limit) => {
  return { type: SET_YAWO_WALLET_HISTORY_LIMIT, payload: limit };
};

export const setYawoWalletHistoryStatus = (status) => {
  return { type: SET_YAWO_WALLET_HISTORY_STATUS, payload: status };
};

const generatePath = (endPoint, yawoWalletHistory, query) => {
  const { limit, page, startDate, endDate } = yawoWalletHistory || {};
  query = {
    page,
    limit,
    startDate: startDate ? dateFormatYMD(startDate) : undefined,
    endDate: endDate ? dateFormatYMD(endDate) : dateFormatYMD(Date.now()),
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  if (query.startDate === undefined) delete query.startDate;
  if (query.endDate === undefined) delete query.endDate;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoWalletHistories =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoWalletHistoryIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Wallet Histories'));
    const { yawoWalletHistories } = getState().yawoWalletHistory;
    const { profile } = getState().user;
    const shortCode = profile.platform;
    const path = generatePath('/yawo/wallet-histories', yawoWalletHistories, {
      shortCode,
      ...query,
    });
    authService
      .get(path)
      .then((res) => {
        dispatch(addYawoWalletHistories(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Wallet Histories' })))
      .finally(() => dispatch(yawoWalletHistoryNotLoading()));
  };

export const getSingleYawoWalletHistory = (walletHistoryId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoWalletHistoryIsLoading('Loading Yawo Wallet History'));
  }
  authService
    .get(`/yawo/wallet-history/${walletHistoryId}`)
    .then((res) => {
      dispatch(addYawoWalletHistory(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Wallet History' })))
    .finally(() => dispatch(yawoWalletHistoryNotLoading()));
};

export const getYawoWalletStatsByIuwi = (yawoWalletIuwi, onSuccess) => (dispatch) => {
  authService
    .get(`/yawo/wallet/${yawoWalletIuwi}`)
    .then((res) => {
      if (onSuccess) onSuccess(res?.data?.data);
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Wallet Stats' })));
};
