import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    gap: 1rem;
  }

  input[type='radio'] {
    display: none;
  }
  .radio {
    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 13px;
      color: ${(props) => props.theme.color.font_01};
      border-bottom: 2px solid ${(props) => props.theme.color.white};
      &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.color.black};
        transition: 0.3s;
      }
    }

    .active-label {
      font-weight: 700;
      color: ${(props) => props.theme.color.cyan};
    }
  }
`;

function PriceTypeRadio({ value, onChange, options, setTerminal }) {
  const handleChange = (event) => {
    onChange(event.target.value);
    setTerminal('flutterwave');
  };

  return (
    <Wrapper>
      {options.map((option) => (
        <div className="radio" key={option.value}>
          <label className={value === option.value ? 'active-label' : ''}>
            <input type="radio" value={option.value} checked={value === option.value} onChange={handleChange} />
            {option.text}
          </label>
        </div>
      ))}
    </Wrapper>
  );
}

PriceTypeRadio.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setTerminal: PropTypes.func.isRequired,
};

export default PriceTypeRadio;
