import client from 'src/client';
import { clearAlert, errorAlert, infoAlert, successAlert } from './alertActions';
import {
  POS_MAPPING_IS_LOADING,
  POS_MAPPING_NOT_LOADING,
  ADD_POS_MAPPINGS,
  CLEAR_POS_MAPPINGS,
  SET_POS_MAPPING_LIMIT,
  SET_POS_MAPPING_PAGE,
} from 'src/store/types';
import { dateFormatYMD } from 'src/utils/helpers';
import convertArrayAsCsv from 'src/utils/convertArrayAsCsv';
import downloadBlob from 'src/utils/downloadBlob';

const { authService } = client;

const mappingIsLoading =
  (msg = 'Loading Mappings') =>
  (dispatch) => {
    dispatch({ type: POS_MAPPING_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const mappingNotLoading = () => (dispatch) => {
  dispatch({ type: POS_MAPPING_NOT_LOADING });
  dispatch(clearAlert());
};

const addPosMappings = (mappings) => {
  return { type: ADD_POS_MAPPINGS, payload: mappings };
};

export const clearPosMappings = () => {
  return { type: CLEAR_POS_MAPPINGS };
};

export const setPosMappingPage = (page) => {
  return { type: SET_POS_MAPPING_PAGE, payload: page };
};

export const setPosMappingLimit = (limit) => {
  return { type: SET_POS_MAPPING_LIMIT, payload: limit };
};

const generatePath = (endPoint, posMappings) => {
  if (posMappings === null) {
    return endPoint;
  }

  const { start_date, end_date, limit = 20, page = 1 } = posMappings;

  if (start_date === null || end_date === null) {
    return `${endPoint}?limit=${limit}&page=${page}`;
  }

  return `${endPoint}?limit=${limit}&page=${page}&start_date=${dateFormatYMD(start_date)}&end_date=${dateFormatYMD(
    end_date
  )}`;
};

export const getPosMappings = () => (dispatch, getState) => {
  dispatch(mappingIsLoading());
  const { platform } = getState().user.profile;
  const { posMappings } = getState().posMapping;
  const path = generatePath(`/pos/mmo/${platform}`, posMappings);
  authService
    .get(path)
    .then((res) => {
      dispatch(addPosMappings(res.data));
      dispatch(mappingNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert());
      dispatch(mappingNotLoading());
    });
};

export const mapPosToAgent = (posId, agentId) => (dispatch) => {
  dispatch(mappingIsLoading('Mapping Pos To Agent'));
  authService
    .post('/pos/map/agent', { posId, agentId })
    .then((res) => {
      dispatch(getPosMappings());
      dispatch(successAlert(res.data.message));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(mappingNotLoading());
    });
};

export const unmapPosFromAgent = (posId, agentId) => (dispatch) => {
  dispatch(mappingIsLoading('Unmapping Pos From Agent'));
  authService
    .post('/pos/unmap/agent', { posId, agentId })
    .then((res) => {
      dispatch(getPosMappings());
      dispatch(successAlert(res.data.message));
    })
    .catch((err) => {
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(mappingNotLoading());
    });
};

export const downloadPosMappings =
  ({ callback }) =>
  (dispatch, getState) => {
    dispatch(mappingIsLoading('Downloading Pos Mappings'));
    const { platform } = getState().user.profile;
    const { posMappings } = getState().posMapping;
    const path = generatePath(`/pos/mmo/${platform}`, {
      ...posMappings,
      limit: posMappings.count,
      page: 1,
    });

    authService
      .get(path)
      .then((res) => {
        const data = res.data?.data || [];
        const csvData = convertArrayAsCsv(data, (row) => ({
          'Serial Number': row?.serialNumber || '',
          Status: row?.mapping?.agentMapping?.agentId ? 'mapped' : 'not mapped',
          Agent: row?.mapping?.agentMapping?.agentId || '',
          'Device Activation': row?.mapping?.isActivated?.status ? 'actived' : 'not actived',
        }));
        downloadBlob(csvData, `Pos_Mappings_${platform}.csv`);
        dispatch(clearAlert());
        if (callback && callback.success) callback.success();
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Downloading Pos Mappings' })))
      .finally(() => dispatch(mappingNotLoading()));
  };
