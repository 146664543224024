import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { formatDate } from 'src/utils/helpers';
import { DateRangeInput } from 'src/components/common/inputs';
import LoanTable from './LoanTable';
import {
  getAllYawoLoans,
  setYawoLoanPage,
  setYawoLoanLimit,
  setYawoLoanDate,
  resetYawoLoanDate,
} from 'src/store/actions/yawo/loanActions';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadAgentOptions } from 'src/store/actions/yawo/agentActions';
import { loadCustomerOptions } from 'src/store/actions/yawo/customerActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time_display {
    min-width: 13rem;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function LoansComponent({
  getAllYawoLoans,
  isLoading,
  yawoLoans,
  setYawoLoanPage,
  setYawoLoanLimit,
  setYawoLoanDate,
  resetYawoLoanDate,
  profile,
}) {
  const shortCode = profile.platform;
  const [agent, setAgent] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getYawoLoans = () => {
    const query = {};
    if (agent?.value) {
      query.loanAgent = agent?.value;
    }
    if (customer?.value) {
      query.loanCustomer = customer?.value;
    }
    if (status && status !== 'all') {
      query.status = status;
    }
    getAllYawoLoans(query);
  };

  useEffect(() => {
    getYawoLoans();
  }, [status, agent, customer]);

  const onPageChange = (page) => {
    setYawoLoanPage(page);
    getYawoLoans();
  };

  const onLimitChange = (limit) => {
    setYawoLoanLimit(limit);
    getYawoLoans();
  };

  const onDateChange = async (date) => {
    await setYawoLoanDate(date.startDate, date.endDate);
    getYawoLoans();
  };

  const onResetDateClick = () => {
    resetYawoLoanDate();
    getYawoLoans();
  };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard isLoading={isLoading} title="Total Loans" value={yawoLoans !== null && yawoLoans.count} />
        <DisplayCard isLoading={isLoading} title="Submitted Loans" value={yawoLoans !== null && yawoLoans.totalSubmitted} />
        <DisplayCard isLoading={isLoading} title="Rejected Loans" value={yawoLoans !== null && yawoLoans.totalRejected} />
        <DisplayCard isLoading={isLoading} title="Approved Loans" value={yawoLoans !== null && yawoLoans.totalApproved} />
        <DisplayCard isLoading={isLoading} title="Ongoing Loans" value={yawoLoans !== null && yawoLoans.totalOngoing} />
        <div className="date_range">
          <Button
            onClick={toggle}
            text={`${yawoLoans === null ? '' : formatDate(yawoLoans.startDate)} - ${
              yawoLoans === null ? '' : formatDate(yawoLoans.endDate)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          margin: '1rem 0',
          fontSize: '1rem',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '45%', minWidth: '300px' }}>
          <h4>Agent:</h4>
          <div style={{ width: '100%' }}>
            <AsyncSelect
              name="agent"
              placeholder="Select Agent"
              cacheOptions
              loadOptions={(val) => loadAgentOptions(val, shortCode)}
              theme={reactSelectUtil.theme}
              styles={reactSelectUtil.styles}
              isClearable
              onChange={(newValue) => setAgent(newValue)}
              value={agent}
            />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', width: '45%', minWidth: '300px' }}>
          <h4>Customer:</h4>
          <div style={{ width: '100%' }}>
            <AsyncSelect
              name="customer"
              placeholder="Select Customer"
              cacheOptions
              loadOptions={(val) => loadCustomerOptions(val, shortCode)}
              theme={reactSelectUtil.theme}
              styles={reactSelectUtil.styles}
              isClearable
              onChange={(newValue) => setCustomer(newValue)}
              value={customer}
            />
          </div>
        </div>
      </div>

      <LoanTable
        isLoading={isLoading}
        tableData={yawoLoans}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        status={status}
        onStatusChange={setStatus}
      />
    </Wrapper>
  );
}

LoansComponent.propTypes = {
  getAllYawoLoans: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoLoans: PropTypes.object,
  setYawoLoanLimit: PropTypes.func.isRequired,
  setYawoLoanPage: PropTypes.func.isRequired,
  setYawoLoanDate: PropTypes.func.isRequired,
  resetYawoLoanDate: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { yawoLoans, isLoading } = state.yawoLoan;
  const { profile } = state.user;
  return {
    isLoading,
    yawoLoans,
    profile,
  };
};

export default connect(mapStateToProps, {
  getAllYawoLoans,
  setYawoLoanDate,
  setYawoLoanLimit,
  setYawoLoanPage,
  resetYawoLoanDate,
})(LoansComponent);
