import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { Tooltip } from '@material-ui/core';
//import UpdatePosMapping from './UpdatePosMapping';
import DeletePosMapping from './DeletePosMapping';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .icon {
    cursor: pointer;
  }
  .delete {
    color: ${(props) => props.theme.color.red};
  }
  .edit {
    color: ${(props) => props.theme.color.cyan};
  }
`;

const PosActions = ({ data }) => {
  //const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  /* const toggleEdit = () => {
		setOpenEdit(!openEdit);
	}; */

  const toggleDelete = () => {
    setOpenDelete(!openDelete);
  };

  return (
    <Wrapper>
      <Tooltip title="View Transactions">
        <Link to={`/pos/${data.walletId}/transactions`}>
          <ReceiptIcon className="icon edit" />
        </Link>
      </Tooltip>
      {/* <Tooltip title="Edit">
				<EditIcon
					className="icon edit"
					onClick={toggleEdit}
				/>
			</Tooltip> */}
      <Tooltip title="Delete">
        <DeleteIcon className="icon delete" onClick={toggleDelete} />
      </Tooltip>
      {/* 			<UpdatePosMapping
				isOpen={openEdit}
				handleClose={toggleEdit}
				data={data}
			/> */}
      <DeletePosMapping isOpen={openDelete} handleClose={toggleDelete} mappingId={data._id} />
    </Wrapper>
  );
};

PosActions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PosActions;
