import React from 'react';
import YawoOnboardingLayout from './Layout';
import Button from 'src/components/common/Button';
import { Link } from 'react-router-dom';

const NoAccess = () => {
  return (
    <>
      <YawoOnboardingLayout
        heading="Sorry, you’re not allowed access"
        details="Your business is not yet registered on Yawo. Please click the button to register your business on Yawo."
        button={
          <Link to="/yawo/onboarding/terms">
            <Button text="Become a Yawo MMO" />
          </Link>
        }
      />
    </>
  );
};

export default NoAccess;
