import React from 'react';
import { formatAmount, formatDate, formatTime } from 'src/utils/helpers';
import LoanStatus from './LoanStatus';
import LoanActions from './LoanActions';

export const overviewHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Amount</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['amount'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Customer</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['loanCustomer']?.firstName} {rowData['loanCustomer']?.lastName}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Guarantor</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['loanGuarantor']?.firstName} {rowData['loanGuarantor']?.lastName}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <LoanStatus status={rowData['status']} />;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function SerialNumber(rowData) {
      return <LoanActions loan={rowData} />;
    },
  },
];
