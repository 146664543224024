import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
// import Button from 'src/components/common/Button';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput } from 'src/components/common/inputs';
import CollectionTable from './CollectionTable';
import {
  getAllYawoCollections,
  setYawoCollectionPage,
  setYawoCollectionLimit,
  setYawoCollectionDate,
  resetYawoCollectionDate,
} from 'src/store/actions/yawo/collectionActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time_display {
    min-width: 13rem;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function CollectionsComponent({
  getAllYawoCollections,
  isLoading,
  yawoCollections,
  setYawoCollectionPage,
  setYawoCollectionLimit,
  // setYawoCollectionDate,
  // resetYawoCollectionDate,
}) {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  const getYawoCollections = () => {
    const query = {};
    getAllYawoCollections(query);
  };

  useEffect(() => {
    getYawoCollections();
  }, []);

  const onPageChange = (page) => {
    setYawoCollectionPage(page);
    getYawoCollections();
  };

  const onLimitChange = (limit) => {
    setYawoCollectionLimit(limit);
    getYawoCollections();
  };

  // const onDateChange = (date) => {
  //   setYawoCollectionDate(date.startDate, date.endDate);
  //   getYawoCollections();
  // };

  // const onResetDateClick = () => {
  //   resetYawoCollectionDate();
  //   getYawoCollections();
  // };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard
          isLoading={isLoading}
          title="Total Collections"
          value={yawoCollections !== null && yawoCollections.count}
        />
        {/* <div className="date_range">
          <Button
            onClick={toggle}
            text={`${yawoCollections === null ? '' : formatDate(yawoCollections.start_date)} - ${
              yawoCollections === null ? '' : formatDate(yawoCollections.end_date)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div> */}
      </div>
      <CollectionTable
        isLoading={isLoading}
        tableData={yawoCollections}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

CollectionsComponent.propTypes = {
  getAllYawoCollections: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoCollections: PropTypes.object,
  setYawoCollectionLimit: PropTypes.func.isRequired,
  setYawoCollectionPage: PropTypes.func.isRequired,
  setYawoCollectionDate: PropTypes.func.isRequired,
  resetYawoCollectionDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoCollections, isLoading } = state.yawoCollection;
  return {
    isLoading,
    yawoCollections,
  };
};

export default connect(mapStateToProps, {
  getAllYawoCollections,
  setYawoCollectionLimit,
  setYawoCollectionPage,
  setYawoCollectionDate,
  resetYawoCollectionDate,
})(CollectionsComponent);
