import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .success_text {
    color: ${(props) => props.theme.color.green};
  }
  .failed_text {
    color: ${(props) => props.theme.color.red};
  }
`;

function WalletHistoryStatus({ status }) {
  const displaySuccess = () => <p className="success_text">success</p>;

  const displayFailed = () => <p className="failed_text">failed</p>;

  return (
    <Wrapper>
      {status === 'success' && displaySuccess()}
      {status === 'failed' && displayFailed()}
    </Wrapper>
  );
}

WalletHistoryStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default WalletHistoryStatus;
