import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import Button from 'src/components/common/Button';
import { downloadPosMappings } from 'src/store/actions/posMappingActions';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    margin-bottom: 10px;
    h6 {
      color: ${(props) => props.theme.color.cyan};
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${(props) => props.theme.color.deepGrey};
    transform: scale(1.5);

    &:hover {
      background-color: ${(props) => props.theme.color.lightGrey};
      transition: 0.3s;
    }
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
`;

const DownloadPosMappings = ({ downloadPosMappings = () => null }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const confirmDownload = (event) => {
    event.preventDefault();
    downloadPosMappings({
      success: () => closeModal(),
    });
  };

  return (
    <>
      <Button text="Download POS Mappings" onClick={openModal} />
      <Modal isOpen={isOpen} handleClose={closeModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Confirm To Download Pos Mappings</h6>
            </div>

            <div onClick={closeModal} className="cancel">
              <CloseIcon />
            </div>
          </div>

          <div className="confirm">
            <div className="buttons">
              <Button text="Confirm" onClick={confirmDownload} theme="red" />
              <Button text="Cancel" onClick={closeModal} />
            </div>
          </div>
        </Wrapper>
      </Modal>
    </>
  );
};

DownloadPosMappings.propTypes = {
  downloadPosMappings: PropTypes.func.isRequired,
};

export default connect(null, { downloadPosMappings })(DownloadPosMappings);
