import React from 'react';
import { formatDate, formatTime } from 'src/utils/helpers';
import GuarantorActions from './GuarantorActions';
import UserStatus from 'src/components/yawo/UserStatus';

export const overviewHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Name</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['firstName']} {rowData['lastName']}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Phone Number</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData?.phoneNumber?.number}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <UserStatus user={rowData} />;
    },
  },
  {
    title: function title() {
      return <p>Date</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatDate(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Time</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatTime(rowData['createdAt'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function SerialNumber(rowData) {
      return <GuarantorActions guarantor={rowData} />;
    },
  },
];
