import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ViewWalletHistory from './ViewWalletHistory';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .icon {
    cursor: pointer;
  }
  .approve {
    color: ${(props) => props.theme.color.cyan};
  }
  .reject {
    color: ${(props) => props.theme.color.red};
  }
`;

const WalletHistoryActions = ({ walletHistory }) => {
  return (
    <Wrapper>
      <ViewWalletHistory walletHistoryId={walletHistory._id} />
    </Wrapper>
  );
};

WalletHistoryActions.propTypes = {
  walletHistory: PropTypes.object.isRequired,
};

export default WalletHistoryActions;
