import jwtDecode from 'jwt-decode';
import client from 'src/client';
import { appIsLoading, appNotLoading } from '../actions/appActions';
import { errorAlert, clearAlert } from './alertActions';
import { SET_USER, SET_AUTH, CLEAR_SESSION } from 'src/store/types';
import { getPlatforms } from './platformActions';

const { authService } = client;

const setIsAuthenticated = (state) => {
  return { type: SET_AUTH, payload: state };
};

const setUser = (user) => {
  return { type: SET_USER, payload: user };
};

export const isLoggedIn = () => {
  const token = localStorage.getItem('kq_dfe');
  if (token) {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      return false;
    } else {
      return decoded;
    }
  } else {
    return false;
  }
};

export const setCurrentUser = () => (dispatch) => {
  const decoded = isLoggedIn();
  if (decoded) {
    authService
      .get(`users/${decoded.identity}`)
      .then((res) => {
        dispatch(setUser(res.data));
        dispatch(setIsAuthenticated(true));
        dispatch(appNotLoading());
      })
      .catch(() => {
        dispatch(logoutUser());
      });
  } else {
    dispatch(logoutUser());
  }
};

export const loginUser = (userData) => (dispatch) => {
  dispatch(clearAlert());
  authService
    .post('/auth/login', userData)
    .then((res) => {
      dispatch(appIsLoading());
      dispatch(getPlatforms());
      const token = res.data.accessToken;
      localStorage.setItem('kq_dfe', token);
      dispatch(setCurrentUser());
    })
    .catch((err) => {
      const error = err.response.data.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(appNotLoading());
    });
};

export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem('kq_dfe');
  dispatch({ type: CLEAR_SESSION });
  dispatch(appNotLoading());
};
