import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
import UserTransactionsComponent from 'src/components/users/UserTransactions';

const UserTransactions = () => {
  return (
    <AppLayout showBackButton goBackTo="/dashboard">
      <UserTransactionsComponent />
    </AppLayout>
  );
};

export default UserTransactions;
