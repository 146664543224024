import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
  .button {
    text-transform: none;
    font-weight: bold;
    min-width: 128px;
    min-height: 46px;
    transition: 0.3s;
    font-family: satoshi, sans-serif;

    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }
  }
  .fullWidth {
    width: 100%;
  }
  .blue {
    background-color: ${(props) => props.theme.color.blue};
    color: ${(props) => props.theme.color.white};
  }
  .cyan {
    background-color: ${(props) => props.theme.color.cyan};
    color: ${(props) => props.theme.color.white};
  }
  .red {
    background-color: ${(props) => props.theme.color.red};
    color: ${(props) => props.theme.color.white};
  }
  .gray {
    background-color: ${(props) => props.theme.color.grey3};
    color: ${(props) => props.theme.color.font_01};
  }
  .white {
    color: ${(props) => props.theme.color.blue};
  }
  .MuiButton-root {
    border-radius: 0;
  }
  .time_display {
    min-width: 13rem;
  }
  .MuiButton-root.Mui-disabled {
    opacity: 0.5;
  }
`;

function AppButton({ text, theme, onClick, fullWidth, className, endIcon, startIcon, type, disabled }) {
  return (
    <Wrapper>
      <Button
        type={type}
        onClick={onClick}
        endIcon={endIcon}
        startIcon={startIcon}
        className={`button ${theme} ${fullWidth && 'fullWidth'} ${className}`}
        disabled={disabled}
      >
        {text}
      </Button>
    </Wrapper>
  );
}

AppButton.defaultProps = {
  theme: 'cyan',
  fullWidth: false,
  type: 'button',
  disabled: false,
};

AppButton.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  endIcon: PropTypes.any,
  startIcon: PropTypes.any,
  type: PropTypes.string,
};

export default AppButton;
