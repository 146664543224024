import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { formatDate } from 'src/utils/helpers';
import { DateRangeInput } from 'src/components/common/inputs';
import TransactionTable from './TransactionTable';
import {
  getAllYawoTransactions,
  setYawoTransactionPage,
  setYawoTransactionLimit,
  setYawoTransactionDate,
  resetYawoTransactionDate,
} from 'src/store/actions/yawo/transactionActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time_display {
    min-width: 13rem;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function TransactionsComponent({
  getAllYawoTransactions,
  isLoading,
  yawoTransactions,
  setYawoTransactionPage,
  setYawoTransactionLimit,
  setYawoTransactionDate,
  resetYawoTransactionDate,
}) {
  const [status, setStatus] = useState('all');
  const [type, setType] = useState('all');
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const getYawoTransactions = () => {
    const query = {};
    if (status && status !== 'all') {
      query.status = status;
    }

    if (type && type !== 'all') {
      query.transactionType = type;
    }
    getAllYawoTransactions(query);
  };

  useEffect(() => {
    getYawoTransactions();
  }, [status, type]);

  const onPageChange = (page) => {
    setYawoTransactionPage(page);
    getYawoTransactions();
  };

  const onLimitChange = (limit) => {
    setYawoTransactionLimit(limit);
    getYawoTransactions();
  };

  const onDateChange = async (date) => {
    await setYawoTransactionDate(date.startDate, date.endDate);
    getYawoTransactions();
  };

  const onResetDateClick = () => {
    resetYawoTransactionDate();
    getYawoTransactions();
  };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard
          isLoading={isLoading}
          title="Total Transactions"
          value={yawoTransactions !== null && yawoTransactions.count}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Total Revenue"
          value={yawoTransactions !== null && yawoTransactions.totalRevenue}
        />
        <div className="date_range">
          <Button
            onClick={toggle}
            text={`${yawoTransactions === null ? '' : formatDate(yawoTransactions.startDate)} - ${
              yawoTransactions === null ? '' : formatDate(yawoTransactions.endDate)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div>
      </div>

      <TransactionTable
        isLoading={isLoading}
        tableData={yawoTransactions}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        status={status}
        onStatusChange={setStatus}
        type={type}
        onTypeChange={setType}
      />
    </Wrapper>
  );
}

TransactionsComponent.propTypes = {
  getAllYawoTransactions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoTransactions: PropTypes.object,
  setYawoTransactionLimit: PropTypes.func.isRequired,
  setYawoTransactionPage: PropTypes.func.isRequired,
  setYawoTransactionDate: PropTypes.func.isRequired,
  resetYawoTransactionDate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { yawoTransactions, isLoading } = state.yawoTransaction;
  return {
    isLoading,
    yawoTransactions,
  };
};

export default connect(mapStateToProps, {
  getAllYawoTransactions,
  setYawoTransactionDate,
  setYawoTransactionLimit,
  setYawoTransactionPage,
  resetYawoTransactionDate,
})(TransactionsComponent);
