import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
import DashboardComponent from 'src/components/dashboard/Dashboard';

const Dashboard = () => {
  return (
    <AppLayout title="Dashboard">
      <DashboardComponent />
    </AppLayout>
  );
};

export default Dashboard;
