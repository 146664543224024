import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  min-height: calc(100vh - 176px - 4rem);
  padding: 48px 32px;

  h5,
  p {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.color.font_01};
  }
  p {
    max-width: 423px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0 32px 0;
  }

  button {
    width: 281px;
    @media screen and (max-width: 425px) {
      width: 200px;
    }
  }
`;

const YawoOnboardingLayout = ({ heading, details, button }) => {
  return (
    <Wrapper>
      <h5>{heading}</h5>
      <p>{details}</p>
      {button}
    </Wrapper>
  );
};

YawoOnboardingLayout.propTypes = {
  heading: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  button: PropTypes.any,
};

export default YawoOnboardingLayout;
