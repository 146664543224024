import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PosMappingTableNav from './PosMappingTableNav';
import Table from 'src/components/common/table/Table';
import { posMappingHeader } from './posMappingHeader';
import { getPosMappings, setPosLimit, setPosPage } from 'src/store/actions/mappingActions';
import Button from 'src/components/common/Button';
import NewPosMapping from './NewPosMapping';

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const options = [{ title: 'Agent Details', value: 'agent-details' }];

function PosMappingTable({ posMappings, getPosMappings, setPosLimit, setPosPage }) {
  const [isActive, setIsActive] = useState(options[0].value);
  const [isOpen, setIsOpen] = useState(false);

  const toggleNewMap = () => {
    return setIsOpen(!isOpen);
  };

  useEffect(() => {
    getPosMappings();
  }, [getPosMappings]);

  const onPageChange = (page) => {
    setPosPage(page);
    getPosMappings();
  };

  const onLimitChange = (limit) => {
    setPosLimit(limit);
    getPosMappings();
  };

  return (
    <Wrapper>
      <PosMappingTableNav isActive={isActive} setIsActive={setIsActive} options={options} />
      <Table
        rightHeader={<Button text="New" onClick={toggleNewMap} />}
        headers={posMappingHeader}
        data={posMappings === null ? [] : posMappings.data}
        showNav={true}
        limit={posMappings === null ? 20 : posMappings.limit}
        page={posMappings === null ? 1 : posMappings.page}
        count={posMappings === null ? 1 : posMappings.count}
        onLimitChange={onLimitChange}
        onPageChange={onPageChange}
      />
      <NewPosMapping isOpen={isOpen} handleClose={toggleNewMap} />
    </Wrapper>
  );
}

PosMappingTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getPosMappings: PropTypes.func.isRequired,
  setPosLimit: PropTypes.func.isRequired,
  setPosPage: PropTypes.func.isRequired,
  posMappings: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { pos, isLoading } = state.mapping;
  return { posMappings: pos, isLoading };
};

export default connect(mapStateToProps, { getPosMappings, setPosLimit, setPosPage })(PosMappingTable);
