import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_COLLECTIONS,
  ADD_YAWO_COLLECTION,
  YAWO_COLLECTION_IS_LOADING,
  YAWO_COLLECTION_NOT_LOADING,
  SET_YAWO_COLLECTION_DATE,
  RESET_YAWO_COLLECTION_DATE,
  SET_YAWO_COLLECTION_LIMIT,
  SET_YAWO_COLLECTION_STATUS,
  SET_YAWO_COLLECTION_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert } from 'src/store/actions/alertActions';

const { authService } = client;

const yawoCollectionIsLoading =
  (msg = 'Loading Yawo Collections') =>
  (dispatch) => {
    dispatch({ type: YAWO_COLLECTION_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoCollectionNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_COLLECTION_NOT_LOADING });
};

const addYawoCollections = (yawoCollections) => {
  return { type: ADD_YAWO_COLLECTIONS, payload: yawoCollections };
};

const addYawoCollection = (yawoCollection) => {
  return { type: ADD_YAWO_COLLECTION, payload: yawoCollection };
};

export const setYawoCollectionDate = (start_date, end_date) => {
  return { type: SET_YAWO_COLLECTION_DATE, payload: { start_date, end_date } };
};

export const resetYawoCollectionDate = () => {
  return { type: RESET_YAWO_COLLECTION_DATE };
};

export const setYawoCollectionPage = (page) => {
  return { type: SET_YAWO_COLLECTION_PAGE, payload: page };
};

export const setYawoCollectionLimit = (limit) => {
  return { type: SET_YAWO_COLLECTION_LIMIT, payload: limit };
};

export const setYawoCollectionStatus = (status) => {
  return { type: SET_YAWO_COLLECTION_STATUS, payload: status };
};

const generatePath = (endPoint, yawoCollection, query) => {
  const { limit, page } = yawoCollection || {};
  query = {
    page,
    limit,
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoCollections =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoCollectionIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Collections'));
    const { yawoCollections } = getState().yawoCollection;
    const { profile } = getState().user;
    const shortCode = profile.platform;
    const path = generatePath('/yawo/collections', yawoCollections, { shortCode, ...query });
    authService
      .get(path)
      .then((res) => {
        dispatch(addYawoCollections(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Collections' })))
      .finally(() => dispatch(yawoCollectionNotLoading()));
  };

export const getSingleYawoCollection = (collectionId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoCollectionIsLoading('Loading Yawo Collection'));
  }
  authService
    .get(`/yawo/collection/${collectionId}`)
    .then((res) => {
      dispatch(addYawoCollection(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Collection' })))
    .finally(() => dispatch(yawoCollectionNotLoading()));
};
