const keys = {};

// Auth service server and auth keys.
keys.authService = {
  server: process.env.REACT_APP_AUTH_SERVER,
  auth: {
    username: process.env.REACT_APP_AUTH_SERVER_USERNAME,
    password: process.env.REACT_APP_AUTH_SERVER_PASSWORD,
  },
};

// Wallet service server and auth keys
keys.walletService = {
  server: process.env.REACT_APP_WALLET_SERVER,
  auth: {
    username: process.env.REACT_APP_WALLET_SERVER_USERNAME,
    password: process.env.REACT_APP_WALLET_SERVER_PASSWORD,
  },
};

// User service server and auth keys
keys.userService = {
  server: process.env.REACT_APP_USER_SERVER,
  auth: {
    username: process.env.REACT_APP_USER_SERVER_USERNAME,
    password: process.env.REACT_APP_USER_SERVER_PASSWORD,
  },
};

// App service server and auth keys
keys.appService = {
  server: process.env.REACT_APP_APP_SERVICE_SERVER,
  auth: {
    username: process.env.REACT_APP_APP_SERVICE_USERNAME,
    password: process.env.REACT_APP_APP_SERVICE_PASSWORD,
  },
};

export default Object.freeze(keys);
