import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_TRANSACTIONS,
  ADD_YAWO_TRANSACTION,
  YAWO_TRANSACTION_IS_LOADING,
  YAWO_TRANSACTION_NOT_LOADING,
  SET_YAWO_TRANSACTION_DATE,
  RESET_YAWO_TRANSACTION_DATE,
  SET_YAWO_TRANSACTION_LIMIT,
  SET_YAWO_TRANSACTION_STATUS,
  SET_YAWO_TRANSACTION_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert } from 'src/store/actions/alertActions';
import { dateFormatYMD } from 'src/utils/helpers';

const { authService } = client;

const yawoTransactionIsLoading =
  (msg = 'Loading Yawo Transactions') =>
  (dispatch) => {
    dispatch({ type: YAWO_TRANSACTION_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoTransactionNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_TRANSACTION_NOT_LOADING });
};

const addYawoTransactions = (yawoTransactions) => {
  return { type: ADD_YAWO_TRANSACTIONS, payload: yawoTransactions };
};

const addYawoTransaction = (yawoTransaction) => {
  return { type: ADD_YAWO_TRANSACTION, payload: yawoTransaction };
};

export const setYawoTransactionDate = (startDate, endDate) => {
  return { type: SET_YAWO_TRANSACTION_DATE, payload: { startDate, endDate } };
};

export const resetYawoTransactionDate = () => {
  return { type: RESET_YAWO_TRANSACTION_DATE };
};

export const setYawoTransactionPage = (page) => {
  return { type: SET_YAWO_TRANSACTION_PAGE, payload: page };
};

export const setYawoTransactionLimit = (limit) => {
  return { type: SET_YAWO_TRANSACTION_LIMIT, payload: limit };
};

export const setYawoTransactionStatus = (status) => {
  return { type: SET_YAWO_TRANSACTION_STATUS, payload: status };
};

const generatePath = (endPoint, yawoTransaction, query) => {
  const { limit, page, startDate, endDate } = yawoTransaction || {};
  query = {
    page,
    limit,
    startDate: startDate ? dateFormatYMD(startDate) : undefined,
    endDate: endDate ? dateFormatYMD(endDate) : dateFormatYMD(Date.now()),
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  if (query.startDate === undefined) delete query.startDate;
  if (query.endDate === undefined) delete query.endDate;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoTransactions =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoTransactionIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Transactions'));
    const { yawoTransactions } = getState().yawoTransaction;
    const { profile } = getState().user;
    const shortCode = profile.platform;
    const path = generatePath('/yawo/transactions', yawoTransactions, { shortCode, ...query });
    authService
      .get(path)
      .then((res) => {
        dispatch(addYawoTransactions(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Transactions' })))
      .finally(() => dispatch(yawoTransactionNotLoading()));
  };

export const getSingleYawoTransaction = (transactionRef, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoTransactionIsLoading('Loading Yawo Transaction'));
  }
  authService
    .get(`/yawo/transactions/${transactionRef}`)
    .then((res) => {
      dispatch(addYawoTransaction(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Transaction' })))
    .finally(() => dispatch(yawoTransactionNotLoading()));
};
