import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { connect } from 'react-redux';
import { logoutUser } from 'src/store/actions/userActions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Container } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Nav from './Nav';
import MobileMenu from './MobileMenu';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.background_header};
  height: auto;
  min-height: 88px;
  padding: 20px 0;
  width: 100%;
  @media screen and (max-width: 1024px) {
    display: flex;
  }
  .right {
    display: grid;
    grid-template-columns: 180px max-content;
    align-items: center;
    grid-gap: 2.5rem;
    @media screen and (max-width: 1024px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  .logo {
    width: max-content;
    height: max-content;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${(props) => props.theme.color.cyan};
    @media screen and (max-width: 1024px){
      max-width: none;
    }
    a {
      font-size: 20px;
      border-bottom: 2px solid ${(props) => props.theme.color.background_header};
      text-decoration: none;
      font-weight: bold;
      white-space: nowrap;
      text-transform: capitalize;
    }
  }
  .button {
    font-weight: bold;
    font-size: 16px;
  }
  .left {
    border-bottom: 2px solid ${(props) => props.theme.color.background_header};
    @media screen and (max-width: 1024px) {
      display: none;
    }
    button {
      background-color: transparent;
      color: ${(props) => props.theme.color.cyan};
      &:hover {
        color: ${(props) => props.theme.color.red};
      }
    }
  }

  .header-container {
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }
  }
`;

const Header = ({ logoutUser, platform, platforms }) => {
  return (
    <Wrapper>
      <Container maxWidth="lg" className="header-container">
        <div className="right">
          <div className="logo">
            <Link to="/dashboard">
              {(platforms &&
                platforms[platform] &&
                platforms[platform].charAt(0).toUpperCase() + platforms[platform].slice(1)) ||
                'Ikonik'}
            </Link>
          </div>
          <Nav />
          <MobileMenu />
        </div>

        <div className="left">
          <Button onClick={() => logoutUser()} endIcon={<ExitToAppIcon />} text="Logout" />
        </div>
      </Container>
    </Wrapper>
  );
};

Header.propTypes = {
  platform: PropTypes.string,
  platforms: PropTypes.object,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const platform = state.user.profile.platform;
  const { platforms } = state.platform;

  return { platform, platforms };
};

export default connect(mapStateToProps, { logoutUser })(Header);
