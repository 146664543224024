import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import auth_BG from 'src/assets/img/auth_BG.svg';

const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-image: ${`url(${auth_BG})`};
  display: grid;
  justify-items: center;
  align-content: center;
  .title {
    color: ${(props) => props.theme.color.white};
    text-transform: uppercase;
  }
`;

const AuthLayout = ({ children }) => {
  return (
    <Wrapper>
      <h1 className="title">Ikonik</h1>
      {children}
    </Wrapper>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default AuthLayout;
