import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import Login from 'src/views/auth/Login';
import Forgot from 'src/views/auth/Forgot';
import Dashboard from 'src/views/dashboard/Dashboard';
import PriceSettings from 'src/views/dashboard/PriceSettings';
import PosMapping from 'src/views/dashboard/PosMapping';
import PosMappings from 'src/views/dashboard/pos/PosMappings';
import PosTransactions from 'src/views/dashboard/PosTransactions';
import Users from 'src/views/users/Users';
import User from 'src/views/users/User';
import UserTransactions from 'src/views/users/UserTransactions';
import Yawo from 'src/views/yawo';
import YawoNoAccess from 'src/views/yawo/onboarding/NoAccess';
import YawoSubmitted from 'src/views/yawo/onboarding/Submitted';
import YawoTerms from 'src/views/yawo/onboarding/Terms';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute exact isAuth={false} path="/" component={Login} />
        <Route exact path="/forgot" component={Forgot} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/price_settings" component={PriceSettings} />
        <PrivateRoute exact path="/pos_mapping" component={PosMapping} />
        <PrivateRoute exact path="/pos_mappings" component={PosMappings} />
        <PrivateRoute exact path="/pos/:walletId/transactions" component={PosTransactions} />
        <PrivateRoute exact path="/users" component={Users} />
        <PrivateRoute exact path="/users/:userId" component={User} />
        <PrivateRoute exact path="/users/:walletId/transactions" component={UserTransactions} />
        <PrivateRoute exact path="/yawo/onboarding/no-access" component={YawoNoAccess} />
        <PrivateRoute exact path="/yawo/onboarding/submitted" component={YawoSubmitted} />
        <PrivateRoute exact path="/yawo/onboarding/terms" component={YawoTerms} />
        <PrivateRoute exact path="/yawo" component={Yawo} />
      </Switch>
    </Router>
  );
};

export default Routes;
