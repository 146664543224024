import {
  POS_MAPPING_IS_LOADING,
  POS_MAPPING_NOT_LOADING,
  CLEAR_POS_MAPPINGS,
  ADD_POS_MAPPINGS,
  SET_POS_MAPPING_PAGE,
  SET_POS_MAPPING_LIMIT,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  posMappings: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case POS_MAPPING_IS_LOADING:
      return { ...state, isLoading: true };
    case POS_MAPPING_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_POS_MAPPINGS:
      return { ...state, posMappings: action.payload };
    case CLEAR_POS_MAPPINGS:
      return { ...state, posMappings: initialState.posMappings };
    case SET_POS_MAPPING_LIMIT:
      return {
        ...state,
        posMappings: {
          ...state.posMappings,
          limit: action.payload,
        },
      };
    case SET_POS_MAPPING_PAGE:
      return {
        ...state,
        posMappings: {
          ...state.posMappings,
          page: action.payload,
        },
      };
    default:
      return state;
  }
}
