import React from 'react';
import AuthLayout from 'src/components/layout/AuthLayout';
//import PropTypes from 'prop-types'
import ForgotForm from 'src/components/auth/ForgotForm';

function Forgot() {
  return (
    <AuthLayout>
      <ForgotForm />
    </AuthLayout>
  );
}

Forgot.propTypes = {};

export default Forgot;
