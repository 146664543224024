/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSingleYawoCollection } from 'src/store/actions/yawo/collectionActions';
import Loader from 'src/components/notification/Loader';
import ViewAgent from 'src/components/yawo/agents/ViewAgent';
import ViewCustomer from 'src/components/yawo/customers/ViewCustomer';

import { formatAmount, dateFormatYMD } from 'src/utils/helpers';
import CollectionStatus from './CollectionStatus';

const StyledIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.color.grey};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
  }
  .item {
    display: flex;
    /* justify-content: space-between; */
    grid-gap: 1rem;
    color: black;
    margin-bottom: 0.5rem;
    align-items: center;
    @media screen and (max-width: 425px) {
      display: block;
    }
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ViewCollection = ({ collectionId, yawoCollection, getSingleYawoCollection, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen) getSingleYawoCollection(collectionId);
  }, [isOpen, getSingleYawoCollection, collectionId]);

  return (
    <div>
      <Tooltip title="View Collection">
        <StyledIcon onClick={toggleModal} />
      </Tooltip>
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Collection Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div>
                <div className="item">
                  <p>Collection Ref</p>
                  <h6 className="value">{yawoCollection && yawoCollection.collectionRef}</h6>
                </div>
                <div className="item">
                  <p>Collection Wallet</p>
                  <h6 className="value">{yawoCollection && yawoCollection.collectionWallet}</h6>
                </div>
                <div className="item">
                  <p>Collection Status</p>
                  <h6 className="value">
                    {yawoCollection && <CollectionStatus status={yawoCollection.collectionStatus} />}
                  </h6>
                </div>
                <div className="item">
                  <p>Amount</p>
                  <h6 className="value">{formatAmount(yawoCollection && yawoCollection.amount)}</h6>
                </div>
                <div className="item">
                  <p>Day</p>
                  <h6 className="value">{yawoCollection && yawoCollection?.day}</h6>
                </div>
                <div className="item">
                  <p>Collection Date</p>
                  <h6 className="value">{yawoCollection && yawoCollection?.collectionDate}</h6>
                </div>
                <div className="item">
                  <p>Created at</p>
                  <h6 className="value">{yawoCollection && dateFormatYMD(yawoCollection.createdAt)}</h6>
                </div>
                <div className="item">
                  <p>Updated at</p>
                  <h6 className="value">{yawoCollection && dateFormatYMD(yawoCollection.updatedAt)}</h6>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div>
                <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap-reverse' }}>
                  <img src={yawoCollection?.loanAgent?.photo} style={{ maxWidth: '100%' }} />
                  <div>
                    <h6>Agent Details</h6>
                    <div className="item">
                      <p>Name</p>
                      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        <h6 className="value">
                          {yawoCollection && yawoCollection.loanAgent.firstName}{' '}
                          {yawoCollection && yawoCollection.loanAgent.lastName}
                        </h6>
                        <ViewAgent agentId={yawoCollection?.loanAgent?._id} />
                      </div>
                    </div>
                    <div className="item">
                      <p>Email</p>
                      <h6 className="value">{yawoCollection && yawoCollection.loanAgent.email.address}</h6>
                    </div>
                    <div className="item">
                      <p>Phone Number</p>
                      <h6 className="value">{yawoCollection && yawoCollection.loanAgent.phoneNumber.number}</h6>
                    </div>
                  </div>
                </div>
                <br />
                <hr />
                <br />
                <div style={{ display: 'flex', gap: '20px', flexWrap: 'wrap-reverse' }}>
                  <img src={yawoCollection?.loanCustomer?.photo} style={{ maxWidth: '100%' }} />
                  <div>
                    <h6>Customer Details</h6>

                    <div className="item">
                      <p>Name</p>
                      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                        <h6
                          className="value"
                          onClick={() => {
                            console.log(yawoCollection.loanCustomer._id);
                          }}
                        >
                          {yawoCollection && yawoCollection.loanCustomer.firstName}{' '}
                          {yawoCollection && yawoCollection.loanCustomer.lastName}
                        </h6>
                        <ViewCustomer customerId={yawoCollection?.loanCustomer?._id} />
                      </div>
                    </div>
                    <div className="item">
                      <p>Phone Number</p>
                      <h6 className="value">{yawoCollection && yawoCollection.loanCustomer.phoneNumber.number}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewCollection.propTypes = {
  yawoCollection: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoCollection: PropTypes.func.isRequired,
  collectionId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoCollection } = state.yawoCollection;
  return { isLoading, yawoCollection };
};

export default connect(mapStateToProps, { getSingleYawoCollection })(ViewCollection);
