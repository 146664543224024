import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Theme from './components/layout/Theme';
import Router from './routes/Router';
import { connect } from 'react-redux';
import { setCurrentUser } from './store/actions/userActions';
import { getPlatforms } from './store/actions/platformActions';

function App({ setCurrentUser, isLoading, getPlatforms }) {
  useEffect(() => {
    setCurrentUser();
    getPlatforms();
  }, [setCurrentUser, getPlatforms]);

  return <Theme>{!isLoading && <Router />}</Theme>;
}

App.propTypes = {
  setCurrentUser: PropTypes.func.isRequired,
  getPlatforms: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading } = state.app;
  return { isLoading };
};

export default connect(mapStateToProps, { setCurrentUser, getPlatforms })(App);
