const months = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

export const formatDate = (date) => {
  return `${months[new Date(date).getMonth()]} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`;
};

export const dateFormatYMD = (date) => {
  date = new Date(date);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
};

export const formatTime = (date) => {
  let hours = new Date(date).getHours();
  let minutes = new Date(date).getMinutes();

  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes}${ampm}`;
};

export const Naira = '\u20A6';

export const formatAmount = (amount) => {
  return amount ? `${Naira}${parseFloat(parseFloat(amount).toFixed(2)).toLocaleString('en')}` : '-';
};

export const sortTableData = (tableData, dataKey, callback) => {
  tableData.forEach((data, index) => {
    if (dataKey) {
      data['#'] = index < 9 ? `0${index + 1}` : `${index + 1}`;
      const keys = Object.keys(dataKey);

      keys.forEach((key) => {
        if (key === 'Date') {
          return (data[key] = formatDate(data[dataKey[key]]));
        }
        if (key === 'Time') {
          return (data[key] = formatTime(data[dataKey[key]]));
        }
        return (data[key] = data[dataKey[key]]);
      });
    }
    if (callback) {
      return callback(data);
    }
    return data;
  });
  return tableData;
};

const capitalize = (string = '') => string.charAt(0).toUpperCase() + String(string).toLowerCase().slice(1);

export const sortTransactionType = (data) => {
  const key = {
    CASHOUT: 'Transfer',
    POS_WALLET_TOPUP: 'Withdrawal',
    BILL: capitalize(data.billingType),
  };
  return key[data.Type] || data.Type;
};

export const formatToDigits = (number, max) => {
  if (number.length > 0) {
    number = number.replace(/[^0-9]/g, '');
  }
  return number.slice(0, max);
};
