import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSingleYawoTransaction } from 'src/store/actions/yawo/transactionActions';
import Loader from 'src/components/notification/Loader';
import { formatAmount, dateFormatYMD, formatTime } from 'src/utils/helpers';
import TransactionStatus from './TransactionStatus';

const StyledIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.color.grey};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
  }
  .item {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    color: black;
    margin-bottom: 0.5rem;
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ViewTransaction = ({ transactionRef, yawoTransaction = {}, getSingleYawoTransaction, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (isOpen) getSingleYawoTransaction(transactionRef);
  }, [isOpen, getSingleYawoTransaction, transactionRef]);

  return (
    <div>
      <Tooltip title="View Transaction">
        <StyledIcon onClick={toggleModal} />
      </Tooltip>
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Transaction Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div className="item">
                <p>Amount</p>
                <h6 className="value">{formatAmount(yawoTransaction && yawoTransaction.amount)}</h6>
              </div>
              <div className="item">
                <p>Type</p>
                <h6 className="value">{yawoTransaction && yawoTransaction.transactionType.replaceAll('_', ' ')}</h6>
              </div>
              <div className="item">
                <p>Status</p>
                <h6 className="value">{yawoTransaction && <TransactionStatus status={yawoTransaction.status} />}</h6>
              </div>
              <div className="item">
                <p>Message</p>
                <h6 className="value">{yawoTransaction && yawoTransaction.message}</h6>
              </div>
              <div className="item">
                <p>Sender</p>
                <h6 className="value">{yawoTransaction && yawoTransaction.senderShortCode}</h6>
              </div>
              <div className="item">
                <p>Receiver</p>
                <h6 className="value">{yawoTransaction && yawoTransaction.receiverShortCode}</h6>
              </div>
              <div className="item">
                <p>Yawo Ref</p>
                <h6 className="value">{yawoTransaction && yawoTransaction.yawoRef}</h6>
              </div>
              <div className="item">
                <p>Date</p>
                <h6 className="value">{yawoTransaction && dateFormatYMD(yawoTransaction.createdAt)}</h6>
              </div>
              <div className="item">
                <p>Time</p>
                <h6 className="value">{yawoTransaction && formatTime(yawoTransaction.createdAt)}</h6>
              </div>
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewTransaction.propTypes = {
  yawoTransaction: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoTransaction: PropTypes.func.isRequired,
  transactionRef: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoTransaction } = state.yawoTransaction;
  return { isLoading, yawoTransaction };
};

export default connect(mapStateToProps, { getSingleYawoTransaction })(ViewTransaction);
