import React from 'react';
import { formatDate } from 'src/utils/helpers';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link } from 'react-router-dom';
import UserAccountStatus from './UserAccountStatus';

export const userTableHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Name</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <Link to={`users/${rowData['_id']}`}>
          <p>{`${rowData['firstName']} ${rowData['lastName']}`}</p>
        </Link>
      );
    },
  },
  {
    title: function title() {
      return <p>Phone Number</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['phoneNumber']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Email</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['email']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <UserAccountStatus status={rowData['status']} />;
    },
  },
  {
    title: function title() {
      return <p>SignUp Date</p>;
    },
    render: function SerialNumber(rowData) {
      return <p>{formatDate(rowData['timeCreated'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>View</p>;
    },
    render: function SerialNumber(rowData) {
      return (
        <Link to={`users/${rowData['_id']}`}>
          <OpenInNewIcon />
        </Link>
      );
    },
  },
];
