import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NewTransfersPrice from './NewTransfersPrice';
import NewWithdrawalPrice from './NewWithdrawalPrice';
import Button from 'src/components/common/Button';

function AddNewPrice({ category, type, terminal }) {
  const [withdrawalIsOpen, setWithdrawalIsOpen] = useState(false);
  const [transferIsOpen, setTransferIsOpen] = useState(false);

  const toggleTransfer = () => {
    setTransferIsOpen(!transferIsOpen);
  };

  const toggleWithdrawal = () => {
    setWithdrawalIsOpen(!withdrawalIsOpen);
  };

  return (
    <div>
      {category === 'withdrawals' && <Button text="Add" onClick={toggleWithdrawal} />}
      {category === 'transfers' && <Button text="Add" onClick={toggleTransfer} />}
      <NewTransfersPrice isOpen={transferIsOpen} handleClose={toggleTransfer} type={type} />
      <NewWithdrawalPrice isOpen={withdrawalIsOpen} handleClose={toggleWithdrawal} type={type} terminal={terminal} />
    </div>
  );
}

AddNewPrice.propTypes = {
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  terminal: PropTypes.string.isRequired,
};

export default AddNewPrice;
