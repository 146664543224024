import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from 'src/components/common/table/Table';
import { overviewHeader } from 'src/components/dashboard/dashboardHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Pending', value: 'pending' },
  { text: 'Failed', value: 'failed' },
  { text: 'Refunded', value: 'refunded' },
];

function PosTransaction({ tableData, onPageChange, onLimitChange, onStatusChange }) {
  const tableKey = {
    Amount: 'amount',
    Type: 'type',
    Status: 'status',
    Date: 'timeCreated',
    Time: 'timeCreated',
  };

  const rightHeader = (
    <SelectInput
      value={tableData === null ? 'all' : tableData.status}
      onChange={onStatusChange}
      options={statusOptions}
    />
  );

  return (
    <Wrapper>
      <Table
        headers={overviewHeader}
        rightHeader={rightHeader}
        showNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

PosTransaction.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  tableData: PropTypes.object,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  subType: PropTypes.string,
  setSubType: PropTypes.func,
  onStatusChange: PropTypes.func.isRequired,
};

export default PosTransaction;
