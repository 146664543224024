import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DashboardTableNav from './DashboardTableNav';
import Table from 'src/components/common/table/Table';
import { overviewHeader } from './dashboardHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const options = [
  { title: 'Overview', value: 'overview' },
  { title: 'Withdrawals', value: 'withdrawals' },
  { title: 'Transfers', value: 'transfers' },
  { title: 'Collections', value: 'collections' },
  { title: 'Airtime', value: 'airtime' },
  { title: 'Data', value: 'data' },
  { title: 'Tv Cable', value: 'tv-cable' },
  { title: 'PHCN', value: 'electricity' },
];

const subOptions = {
  withdrawals: [
    { text: 'Select Type', value: ' ' },
    { text: 'Pos', value: 'pos' },
    { text: 'Card', value: 'card' },
  ],
  transfers: [
    { text: 'Select Type', value: ' ' },
    { text: 'Peer', value: 'p2p' },
    { text: 'Cashout', value: 'bank' },
  ],
  collections: [
    { text: 'Select Type', value: ' ' },
    { text: 'Peer', value: 'peer_collection' },
    { text: 'Bank', value: 'bank_collection' },
  ],
};

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Pending', value: 'pending' },
  { text: 'Failed', value: 'failed' },
  { text: 'Refunded', value: 'refunded' },
];

function DashboardTable({
  type,
  setType,
  tableData,
  onPageChange,
  onLimitChange,
  subType,
  setSubType,
  onStatusChange,
}) {
  const tableKey = {
    Amount: 'amount',
    Type: 'type',
    Status: 'status',
    Date: 'timeCreated',
    Time: 'timeCreated',
  };

  const leftHeader = subOptions[type] && (
    <SelectInput options={subOptions[type]} value={subType} onChange={setSubType} />
  );

  const rightHeader = (
    <SelectInput
      value={tableData === null ? 'all' : tableData.status}
      onChange={onStatusChange}
      options={statusOptions}
    />
  );

  return (
    <Wrapper>
      <DashboardTableNav isActive={type} setIsActive={setType} options={options} />
      <Table
        headers={overviewHeader}
        leftHeader={leftHeader}
        rightHeader={rightHeader}
        showNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

DashboardTable.propTypes = {
  type: PropTypes.string.isRequired,
  setType: PropTypes.func.isRequired,
  tableData: PropTypes.object,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  subType: PropTypes.string,
  setSubType: PropTypes.func,
  onStatusChange: PropTypes.func.isRequired,
};

export default DashboardTable;
