import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import UserTable from './UserTable';
import { connect } from 'react-redux';
import { getUserAccounts, setAccountLimit, setAccountPage, setAccountStatus } from 'src/store/actions/accountActions';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
`;

const Users = ({ accounts, getUserAccounts, setAccountLimit, setAccountPage, setAccountStatus }) => {
  const getUsers = useCallback(() => {
    getUserAccounts();
  }, [getUserAccounts]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onPageChange = (page) => {
    setAccountPage(page);
    getUsers();
  };

  const onLimitChange = (limit) => {
    setAccountLimit(limit);
    getUsers();
  };

  const onStatusChange = (status) => {
    setAccountStatus(status);
    getUsers();
  };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard title="Total Users" value={accounts ? accounts.count : ''} />
        <DisplayCard title="Active Users" value={accounts ? accounts.active_users : ''} />
        <DisplayCard title="Suspended Users" value={accounts ? accounts.suspended_users : ''} />
      </div>
      <UserTable
        tableData={accounts}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        onStatusChange={onStatusChange}
      />
    </Wrapper>
  );
};

Users.propTypes = {
  accounts: PropTypes.object,
  getUserAccounts: PropTypes.func.isRequired,
  setAccountLimit: PropTypes.func.isRequired,
  setAccountPage: PropTypes.func.isRequired,
  setAccountStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { accounts } = state.account;
  return { accounts };
};

export default connect(mapStateToProps, { getUserAccounts, setAccountPage, setAccountLimit, setAccountStatus })(Users);
