import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import Button from 'src/components/common/Button';
import { unmapPosFromAgent } from 'src/store/actions/posMappingActions';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    margin-bottom: 10px;
    h6 {
      color: ${(props) => props.theme.color.cyan};
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${(props) => props.theme.color.deepGrey};
    transform: scale(1.5);

    &:hover {
      background-color: ${(props) => props.theme.color.lightGrey};
      transition: 0.3s;
    }
  }
  .confirm {
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
  .buttons {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
`;

const UnmapPosFromAgent = ({ isOpen, handleClose, unmapPosFromAgent, posId, agentId }) => {
  const onSubmit = (event) => {
    event.preventDefault();
    unmapPosFromAgent(posId, agentId);
    return handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Wrapper>
        <div className="header">
          <div className="header_title">
            <h6>Confirm To Unmap Pos From Agent</h6>
          </div>

          <div onClick={handleClose} className="cancel">
            <CloseIcon />
          </div>
        </div>

        <div className="confirm">
          <div className="buttons">
            <Button text="Confirm" onClick={onSubmit} theme="red" />
            <Button text="Cancel" onClick={handleClose} />
          </div>
        </div>
      </Wrapper>
    </Modal>
  );
};

UnmapPosFromAgent.defaultProps = {
  isOpen: false,
};

UnmapPosFromAgent.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  unmapPosFromAgent: PropTypes.func.isRequired,
  posId: PropTypes.string.isRequired,
  agentId: PropTypes.string.isRequired,
};

export default connect(null, { unmapPosFromAgent })(UnmapPosFromAgent);
