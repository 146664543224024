import React from 'react';
import loader from 'src/assets/svg/loader.svg';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    margin: 3rem;
    height: 10rem;
    width: 10rem;
  }
`;

export default function Loader() {
  return (
    <Wrapper>
      <img src={loader} alt="Loading" className="loader" />
    </Wrapper>
  );
}
