import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSingleYawoCustomer, getAllYawoCustomers } from 'src/store/actions/yawo/customerActions';
import VerifyCustomer from './VerifyCustomer';
import Loader from 'src/components/notification/Loader';
import { dateFormatYMD, formatTime } from 'src/utils/helpers';
import UserVerificationStatus from 'src/components/yawo/UserVerificationStatus';
import UserIsActiveStatus from 'src/components/yawo/UserIsActiveStatus';
import Button from 'src/components/common/Button';

const StyledIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.color.grey};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    color: black;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 425px) {
      display: grid;
      grid-gap: 0rem;
    }
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ViewCustomer = ({ customerId, yawoCustomer, getSingleYawoCustomer, getAllYawoCustomers, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const [openVerify, setOpenVerify] = useState(false);
  const toggleVerify = () => setOpenVerify(!openVerify);

  useEffect(() => {
    if (isOpen) getSingleYawoCustomer(customerId);
  }, [isOpen, getSingleYawoCustomer, customerId]);

  return (
    <div>
      <Tooltip title="View Customer">
        <StyledIcon onClick={toggleModal} />
      </Tooltip>
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Customer Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div>
                <>
                  <div>
                    <div className="item">
                      <p>Photo</p>
                    </div>
                    <div>
                      <img src={yawoCustomer?.photo} style={{ width: '100%', maxWidth: '320px' }} />
                    </div>
                  </div>
                </>
              </div>
              <div>
                <div className="item">
                  <p>First Name</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer.firstName}</h6>
                </div>
                <div className="item">
                  <p>Last Name</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer.lastName}</h6>
                </div>
                <div className="item">
                  <p>Other Names</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer.otherNames}</h6>
                </div>
                <div className="item">
                  <p>Email</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer?.email?.address}</h6>
                </div>
                <div className="item">
                  <p>Phone Number</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer?.phoneNumber?.number}</h6>
                </div>
                <div className="item">
                  <p>Sex</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer?.sex}</h6>
                </div>
                <div className="item">
                  <p>Date of Birth</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer?.dob}</h6>
                </div>
                <div className="item">
                  <p>Yawo Wallet</p>
                  <h6 className="value">
                    {yawoCustomer && yawoCustomer.yawoWallet && (
                      <>
                        {yawoCustomer.yawoWallet}{' '}
                        <Link to={`/yawo?yawoWalletIuwi=${yawoCustomer.yawoWallet}`}>
                          <span>view wallet</span>
                        </Link>
                      </>
                    )}
                  </h6>
                </div>
                <div className="item">
                  <p>Agent</p>
                  {yawoCustomer?.loanAgent && (
                    <h6 className="value">
                      {yawoCustomer?.loanAgent?.firstName} {yawoCustomer?.loanAgent?.lastName} (
                      {yawoCustomer?.loanAgent?.email?.address})
                    </h6>
                  )}
                </div>
                <div className="item">
                  <p>Verification Status</p>
                  <h6 className="value">{yawoCustomer && <UserVerificationStatus user={yawoCustomer} />}</h6>
                </div>
                <div className="item">
                  <p>Is Active</p>
                  <h6 className="value">{yawoCustomer && <UserIsActiveStatus user={yawoCustomer} />}</h6>
                </div>
                <div className="item">
                  <p>Account Number</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer?.bank?.accountNumber}</h6>
                </div>
                <div className="item">
                  <p>Bank Name</p>
                  <h6 className="value">{yawoCustomer && yawoCustomer?.bank?.bankName}</h6>
                </div>
                <div className="item">
                  <p>Date</p>
                  <h6 className="value">{yawoCustomer && dateFormatYMD(yawoCustomer.createdAt)}</h6>
                </div>
                <div className="item">
                  <p>Time</p>
                  <h6 className="value">{yawoCustomer && formatTime(yawoCustomer.createdAt)}</h6>
                </div>
                <>
                  {!yawoCustomer?.vendor?.isVerified && (
                    <>
                      <Button text="Verify Customer" fullWidth={true} onClick={toggleVerify} />
                      <VerifyCustomer
                        isOpen={openVerify}
                        handleClose={toggleVerify}
                        customerId={customerId}
                        onSuccess={getAllYawoCustomers}
                      />
                    </>
                  )}
                </>
              </div>
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewCustomer.propTypes = {
  yawoCustomer: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoCustomer: PropTypes.func.isRequired,
  getAllYawoCustomers: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoCustomer } = state.yawoCustomer;
  return { isLoading, yawoCustomer };
};

export default connect(mapStateToProps, { getSingleYawoCustomer, getAllYawoCustomers })(ViewCustomer);
