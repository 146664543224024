import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  @media screen and (max-width: 1024px) {
    display: none;
  }
  .header_nav {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1.5rem;
    align-items: start;
  }
  .nav_link {
    text-decoration: none;
  }
  .nav_link > h6 {
    font-size: 1rem;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: ${(props) => props.theme.color.font_03};
    border-bottom: 2px solid ${(props) => props.theme.color.background_header};
    &:hover {
      transition: 0.3s;
      color: ${(props) => props.theme.color.white};
    }
  }
  .nav_isActive > h6 {
    color: ${(props) => props.theme.color.white};
    font-weight: 700;
    border-bottom: 2px solid ${(props) => props.theme.color.white};
  }
`;

export default function Nav() {
  return (
    <Wrapper>
      <nav className="header_nav">
        <NavLink className="nav_link" activeClassName="nav_isActive" to="/dashboard">
          <h6>Dashboard</h6>
        </NavLink>
        <NavLink className="nav_link" activeClassName="nav_isActive" to="/price_settings">
          <h6>Price Settings</h6>
        </NavLink>
        <NavLink className="nav_link" activeClassName="nav_isActive" to="/pos_mapping">
          <h6>POS Mapping</h6>
        </NavLink>
        <NavLink className="nav_link" activeClassName="nav_isActive" to="/pos_mappings">
          <h6>Ikonik POS Mappings</h6>
        </NavLink>
        <NavLink className="nav_link" activeClassName="nav_isActive" to="/users">
          <h6>Users</h6>
        </NavLink>
        <NavLink className="nav_link" activeClassName="nav_isActive" to="/yawo">
          <h6>Yawo</h6>
        </NavLink>
      </nav>
    </Wrapper>
  );
}
