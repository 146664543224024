import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from 'src/components/common/table/Table';
import { overviewHeader } from './collectionsHeaders';
import { sortTableData } from 'src/utils/helpers';
import CollectionSearch from './CollectionSearch';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

function CollectionTable({ tableData, onPageChange, onLimitChange }) {
  const tableKey = {};

  return (
    <Wrapper>
      <Table
        headers={overviewHeader}
        leftHeader={<CollectionSearch />}
        rightHeader={null}
        showNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

CollectionTable.propTypes = {
  tableData: PropTypes.object,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
};

export default CollectionTable;
