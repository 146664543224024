import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  .success_text {
    color: ${(props) => props.theme.color.green};
  }
  .pending_text {
    color: ${(props) => props.theme.color.blue};
  }
`;

function CollectionStatus({ status }) {
  const displayPending = () => <p className="pending_text">pending</p>;

  const displaySuccess = () => <p className="success_text">success</p>;

  return (
    <Wrapper>
      {status === 'pending' && displayPending()}
      {status === 'success' && displaySuccess()}
    </Wrapper>
  );
}

CollectionStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default CollectionStatus;
