import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import arrow from 'src/assets/svg/arrow.svg';

import Header from './Header';

const Wrapper = styled.div`
  width: 100vw;
  height: max-content;

  .container {
    padding: 2rem;
    overflow-x: auto;
    overflow-y: auto;
    @media screen and (max-width: 768px) {
      padding: 2rem 1rem;
    }
  }
  .title_sideBar {
    background-color: ${(props) => props.theme.color.white};
    padding: 22px 0;
    height: auto;
    min-height: 88px;
    display: flex;

    .title-container {
      padding: 0 2rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      @media screen and (max-width: 768px) {
        padding: 0 1rem;
      }
      h2 {
        flex-basis: 205px;
        border-bottom: 2px solid ${(props) => props.theme.color.white};
      }
    }
  }
  h2 {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.2px;
    line-height: 26px;
    color: ${(props) => props.theme.color.font_02};
  }
  .go-back {
    display: flex;
    gap: 10px;
    button {
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
`;

export default function AppLayout({ children, title, sideHeader, goBackTo = '', showBackButton = false }) {
  const history = useHistory();

  return (
    <Wrapper>
      <Header />
      <div className="title_sideBar">
        <Container maxWidth="lg" className="title-container">
          <div className="go-back">
            {showBackButton && (
              <>
                {history.length > 2 ? (
                  <button onClick={() => history.goBack()}>
                    <img src={arrow} alt="Go Back" className="arrow" />
                  </button>
                ) : goBackTo ? (
                  <button onClick={() => history.push(goBackTo)}>
                    <img src={arrow} alt="Go Back" className="arrow" />
                  </button>
                ) : null}
              </>
            )}
            <h2>{title}</h2>
          </div>
          {sideHeader}
        </Container>
      </div>
      <Container maxWidth="lg" className="container">
        {children}
      </Container>
    </Wrapper>
  );
}

AppLayout.propTypes = {
  children: PropTypes.node,
  goBackTo: PropTypes.string,
  showBackButton: PropTypes.bool,
  title: PropTypes.string.isRequired,
  sideHeader: PropTypes.any,
};
