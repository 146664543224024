import React from 'react';
import { formatAmount } from 'src/utils/helpers';
import AgentActions from './AgentActions';
import UserStatus from 'src/components/yawo/UserStatus';

export const overviewHeader = [
  {
    title: function title() {
      return <p>#</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData['#']}</p>;
    },
  },
  {
    title: function title() {
      return <p>Name</p>;
    },
    render: function DateTaken(rowData) {
      return (
        <p>
          {rowData['firstName']} {rowData['lastName']}
        </p>
      );
    },
  },
  {
    title: function title() {
      return <p>Email</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{rowData?.email?.address}</p>;
    },
  },
  {
    title: function title() {
      return <p>Status</p>;
    },
    render: function DateTaken(rowData) {
      return <UserStatus user={rowData} />;
    },
  },
  {
    title: function title() {
      return <p>Loan Limit</p>;
    },
    render: function DateTaken(rowData) {
      return <p>{formatAmount(rowData['loanLimit'])}</p>;
    },
  },
  {
    title: function title() {
      return <p>Action</p>;
    },
    render: function SerialNumber(rowData) {
      return <AgentActions agent={rowData} />;
    },
  },
];
