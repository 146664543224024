import client from 'src/client';
import {
  ADD_ACCOUNTS,
  ADD_ACCOUNT,
  ACCOUNT_IS_LOADING,
  ACCOUNT_NOT_LOADING,
  SET_ACCOUNT_LIMIT,
  SET_ACCOUNT_STATUS,
  SET_ACCOUNT_PAGE,
  CLEAR_ACCOUNT,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from './alertActions';

const { userService, authService } = client;

const accountIsLoading =
  (msg = 'Account Loading') =>
  (dispatch) => {
    dispatch({ type: ACCOUNT_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const accountNotLoading = () => (dispatch) => {
  dispatch({ type: ACCOUNT_NOT_LOADING });
  dispatch(clearAlert());
};

const addAccounts = (accounts) => {
  return { type: ADD_ACCOUNTS, payload: accounts };
};

const addAccount = (account) => {
  return { type: ADD_ACCOUNT, payload: account };
};

const clearAccount = () => {
  return { type: CLEAR_ACCOUNT };
};

export const setAccountPage = (page) => {
  return { type: SET_ACCOUNT_PAGE, payload: page };
};

export const setAccountLimit = (limit) => {
  return { type: SET_ACCOUNT_LIMIT, payload: limit };
};

export const setAccountStatus = (status) => {
  return { type: SET_ACCOUNT_STATUS, payload: status };
};

const generatePath = (endPoint, account, platform) => {
  if (account === null) {
    return `${endPoint}?platform=${platform}`;
  }
  const { limit, page, status } = account;
  return `${endPoint}?status=${status}&limit=${limit}&page=${page}&platform=${platform}`;
};

export const getUserAccounts = () => (dispatch, getState) => {
  dispatch(accountIsLoading('Loading Accounts'));
  const { accounts } = getState().account;
  const { profile } = getState().user;
  const path = generatePath('/users', accounts, profile.platform);
  userService
    .get(path)
    .then((res) => {
      dispatch(addAccounts(res.data));
      dispatch(accountNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Accounts' }));
      dispatch(accountNotLoading());
    });
};

export const getUserById = (user_id) => (dispatch) => {
  dispatch(accountIsLoading('Loading Account'));
  authService
    .get(`/client-users/${user_id}`)
    .then((res) => {
      dispatch(addAccount(res.data));
      dispatch(accountNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Account' }));
      dispatch(accountNotLoading());
    });
};

export const activateAccount =
  (user_id, callbacks = {}) =>
  (dispatch) => {
    dispatch(accountIsLoading('Activating Account'));
    authService
      .get(`/client-users/${user_id}/activate`)
      .then((res) => {
        dispatch(addAccount(res.data));
        dispatch(successAlert('Account activateed'));
        if (callbacks.onSuccess) callbacks.onSuccess();
      })
      .catch(() => {
        dispatch(errorAlert({ msg: 'Error Loading Account' }));
        dispatch(accountNotLoading());
      });
  };

export const suspendAccount =
  (user_id, callbacks = {}) =>
  (dispatch) => {
    dispatch(accountIsLoading('Suspending Account'));
    authService
      .get(`/client-users/${user_id}/suspend`)
      .then((res) => {
        dispatch(addAccount(res.data));
        dispatch(successAlert('Account suspended'));
        if (callbacks.onSuccess) callbacks.onSuccess();
      })
      .catch(() => {
        dispatch(errorAlert({ msg: 'Error Loading Account' }));
        dispatch(accountNotLoading());
      });
  };

export const searchAccount = (searchString) => (dispatch, getState) => {
  dispatch(accountIsLoading('Searching...'));
  const { profile } = getState().user;
  userService
    .get(`/users/search/${profile.platform}/${searchString}`)
    .then((res) => {
      dispatch(addAccounts(res.data));
      dispatch(accountNotLoading());
    })
    .catch((err) => {
      dispatch(clearAccount());
      dispatch(errorAlert({ msg: err.response.data.message }));
      dispatch(accountNotLoading());
    });
};

export const loadAgentOptions = async (searchString, platform = '') => {
  let options = [];
  await userService
    .get(`/users/search/${platform}/${searchString}`)
    .then((res) => {
      options = res?.data?.data || [];
    })
    .catch(() => {});

  return options
    .map((x) => ({
      ...x,
      label: `${x.firstName} ${x.lastName} // ${x.email}`,
      value: x._id,
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};
