import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSingleYawoLoan, getAllYawoLoans } from 'src/store/actions/yawo/loanActions';
import ApproveLoan from './ApproveLoan';
import Loader from 'src/components/notification/Loader';
import { formatAmount, dateFormatYMD, formatTime } from 'src/utils/helpers';
import LoanStatus from './LoanStatus';
import Button from 'src/components/common/Button';

const StyledIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.color.grey};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
  }
  .item {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    color: black;
    margin-bottom: 0.5rem;
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ViewLoan = ({ loanId, yawoLoan = {}, getSingleYawoLoan, getAllYawoLoans, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const [openApprove, setOpenApprove] = useState(false);
  const toggleApprove = () => {
    setOpenApprove(!openApprove);
  };

  const vendorHasNotApproved = !yawoLoan?.vendorApproved?.status;
  const loanStatusIsNotRejected = yawoLoan?.status !== 'rejected';
  const showApproveButton = vendorHasNotApproved && loanStatusIsNotRejected;

  useEffect(() => {
    if (isOpen) getSingleYawoLoan(loanId);
  }, [isOpen, getSingleYawoLoan, loanId]);

  const interestRatePercentage = Number(yawoLoan?.interestRate) / 100;
  const dailyCollectionAmount =
    (Number(yawoLoan?.amount) * interestRatePercentage + Number(yawoLoan?.amount)) / Number(yawoLoan?.durationInDays);
  const totalToPay = Number(yawoLoan?.amount) + Number(yawoLoan?.interestAmount);

  return (
    <div>
      <Tooltip title="View Loan">
        <StyledIcon onClick={toggleModal} />
      </Tooltip>
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Loan Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div className="item">
                <p>Amount</p>
                <h6 className="value">{formatAmount(yawoLoan && yawoLoan.amount)}</h6>
              </div>
              <div className="item">
                <p>Interest Amount</p>
                <h6 className="value">{formatAmount(yawoLoan && yawoLoan.interestAmount)}</h6>
              </div>
              <div className="item">
                <p>Interest Rate</p>
                <h6 className="value">{yawoLoan && yawoLoan.interestRate}</h6>
              </div>
              <div className="item">
                <p>Daily Collection Amount</p>
                <h6 className="value">{dailyCollectionAmount || ''}</h6>
              </div>
              <div className="item">
                <p>Total to Pay</p>
                <h6 className="value">{totalToPay || ''}</h6>
              </div>
              <div className="item">
                <p>Loan Duration</p>
                <h6 className="value">{yawoLoan?.durationInDays ? `${yawoLoan.durationInDays} working days` : ''}</h6>
              </div>
              <div className="item">
                <p>Vendor Commission Percentage</p>
                <h6 className="value">{yawoLoan && yawoLoan.vendorCommissionPercentage}</h6>
              </div>
              <div className="item">
                <p>Agent Commission Percentage</p>
                <h6 className="value">{yawoLoan && yawoLoan.agentCommissionPercentage}</h6>
              </div>
              <div className="item">
                <p>Loan Reference</p>
                <h6 className="value">{yawoLoan && yawoLoan.loanRef}</h6>
              </div>
              <div className="item">
                <p>Status</p>
                <h6 className="value">{yawoLoan && <LoanStatus status={yawoLoan.status} />}</h6>
              </div>
              {yawoLoan?.message && (
                <div className="item">
                  <p>Rejection Reason</p>
                  <h6 className="value">{yawoLoan?.message}</h6>
                </div>
              )}
              <div className="item">
                <p>Vendor Approval Status</p>
                <h6 className="value">{yawoLoan?.vendorApproved?.status ? 'YES' : 'NO'}</h6>
              </div>
              <div className="item">
                <p>Yawo Approval Status</p>
                <h6 className="value">{yawoLoan?.adminApproved?.status ? 'YES' : 'NO'}</h6>
              </div>
              <div className="item">
                <p>Agent</p>
                {yawoLoan?.loanAgent && (
                  <h6 className="value">
                    {yawoLoan?.loanAgent?.firstName} {yawoLoan?.loanAgent?.lastName} (
                    {yawoLoan?.loanAgent?.email?.address})
                  </h6>
                )}
              </div>
              <div className="item">
                <p>Customer</p>
                {yawoLoan?.loanCustomer && (
                  <h6 className="value">
                    {yawoLoan?.loanCustomer?.firstName} {yawoLoan?.loanCustomer?.lastName} (
                    {yawoLoan?.loanCustomer?.phoneNumber?.number})
                  </h6>
                )}
              </div>
              <div className="item">
                <p>Guarantor</p>
                {yawoLoan?.loanGuarantor && (
                  <h6 className="value">
                    {yawoLoan?.loanGuarantor?.firstName} {yawoLoan?.loanGuarantor?.lastName} (
                    {yawoLoan?.loanGuarantor?.phoneNumber?.number})
                  </h6>
                )}
              </div>
              <div className="item">
                <p>Date</p>
                <h6 className="value">{yawoLoan && dateFormatYMD(yawoLoan.createdAt)}</h6>
              </div>
              <div className="item">
                <p>Time</p>
                <h6 className="value">{yawoLoan && formatTime(yawoLoan.createdAt)}</h6>
              </div>
              <div className="item">
                <p>Photos</p>
              </div>
              <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                <div style={{ width: '31%' }}>
                  <div style={{ fontSize: 'small' }}>customer</div>
                  <img src={yawoLoan?.loanCustomer?.photo} style={{ width: '100%' }} />
                </div>
                <div style={{ width: '31%' }}>
                  <div style={{ fontSize: 'small' }}>loan photo</div>
                  <img src={yawoLoan?.photo} style={{ width: '100%' }} />
                </div>
                <div style={{ width: '31%' }}>
                  <div style={{ fontSize: 'small' }}>guarantor</div>
                  <img src={yawoLoan?.loanGuarantor?.photo} style={{ width: '100%' }} />
                </div>
              </div>
              <br />
              {showApproveButton && (
                <>
                  <Button text="Approve Loan" fullWidth={true} onClick={toggleApprove} />
                  <ApproveLoan
                    isOpen={openApprove}
                    handleClose={toggleApprove}
                    loanId={loanId}
                    onSuccess={getAllYawoLoans}
                  />
                </>
              )}
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewLoan.propTypes = {
  yawoLoan: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoLoan: PropTypes.func.isRequired,
  getAllYawoLoans: PropTypes.func.isRequired,
  loanId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoLoan } = state.yawoLoan;
  return { isLoading, yawoLoan };
};

export default connect(mapStateToProps, { getSingleYawoLoan, getAllYawoLoans })(ViewLoan);
