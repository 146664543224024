import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSingleYawoAgent, getAllYawoAgents } from 'src/store/actions/yawo/agentActions';
import VerifyAgent from './VerifyAgent';
import Loader from 'src/components/notification/Loader';
import { formatAmount, dateFormatYMD, formatTime } from 'src/utils/helpers';
import UserVerificationStatus from 'src/components/yawo/UserVerificationStatus';
import UserIsActiveStatus from 'src/components/yawo/UserIsActiveStatus';
import Button from 'src/components/common/Button';

const StyledIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.color.grey};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    color: black;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 425px) {
      display: grid;
      grid-gap: 0rem;
    }
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ViewAgent = ({ agentId, yawoAgent, getSingleYawoAgent, getAllYawoAgents, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const [openVerify, setOpenVerify] = useState(false);
  const toggleVerify = () => setOpenVerify(!openVerify);

  useEffect(() => {
    if (isOpen) getSingleYawoAgent(agentId);
  }, [isOpen, getSingleYawoAgent, agentId]);

  return (
    <div>
      <Tooltip title="View Agent">
        <StyledIcon onClick={toggleModal} />
      </Tooltip>
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Agent Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div>
                <>
                  <div className="item">
                    <p>Photo</p>
                  </div>
                  <div>
                    <img src={yawoAgent?.photo} style={{ width: '100%', maxWidth: '320px' }} />
                  </div>
                </>
              </div>

              <div>
                <div className="item">
                  <p>First Name</p>
                  <h6 className="value">{yawoAgent && yawoAgent.firstName}</h6>
                </div>
                <div className="item">
                  <p>Last Name</p>
                  <h6 className="value">{yawoAgent && yawoAgent.lastName}</h6>
                </div>
                <div className="item">
                  <p>Email</p>
                  <h6 className="value">{yawoAgent && yawoAgent?.email?.address}</h6>
                </div>
                <div className="item">
                  <p>Phone Number</p>
                  <h6 className="value">{yawoAgent && yawoAgent?.phoneNumber?.number}</h6>
                </div>
                <div className="item">
                  <p>Sex</p>
                  <h6 className="value">{yawoAgent && yawoAgent?.sex}</h6>
                </div>
                <div className="item">
                  <p>Date of Birth</p>
                  <h6 className="value">{yawoAgent && yawoAgent?.dob}</h6>
                </div>
                <div className="item">
                  <p>Yawo Wallet</p>
                  <h6 className="value">
                    {yawoAgent && yawoAgent.yawoWallet && (
                      <>
                        {yawoAgent.yawoWallet}{' '}
                        <Link to={`/yawo?yawoWalletIuwi=${yawoAgent.yawoWallet}`}>
                          <span>view wallet</span>
                        </Link>
                      </>
                    )}
                  </h6>
                </div>
                <div className="item">
                  <p>Loan Limit</p>
                  <h6 className="value">{formatAmount(yawoAgent && yawoAgent.loanLimit)}</h6>
                </div>
                <div className="item">
                  <p>Verification Status</p>
                  <h6 className="value">{yawoAgent && <UserVerificationStatus user={yawoAgent} />}</h6>
                </div>
                <div className="item">
                  <p>Is Active</p>
                  <h6 className="value">{yawoAgent && <UserIsActiveStatus user={yawoAgent} />}</h6>
                </div>
                <div className="item">
                  <p>Date</p>
                  <h6 className="value">{yawoAgent && dateFormatYMD(yawoAgent.createdAt)}</h6>
                </div>
                <div className="item">
                  <p>Time</p>
                  <h6 className="value">{yawoAgent && formatTime(yawoAgent.createdAt)}</h6>
                </div>
                <>
                  {!yawoAgent?.vendor?.isVerified && (
                    <>
                      <Button text="Verify Agent" fullWidth={true} onClick={toggleVerify} />
                      <VerifyAgent
                        isOpen={openVerify}
                        handleClose={toggleVerify}
                        agentId={agentId}
                        onSuccess={getAllYawoAgents}
                      />
                    </>
                  )}
                </>
              </div>
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewAgent.propTypes = {
  yawoAgent: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoAgent: PropTypes.func.isRequired,
  getAllYawoAgents: PropTypes.func,
  agentId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoAgent } = state.yawoAgent;
  return { isLoading, yawoAgent };
};

export default connect(mapStateToProps, { getSingleYawoAgent, getAllYawoAgents })(ViewAgent);
