import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { TextInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
import { addNewPosMapping } from 'src/store/actions/mappingActions';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import styled from 'styled-components';

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    margin-bottom: 10px;
    h6 {
      color: ${(props) => props.theme.color.cyan};
      font-weight: 700;
      font-size: 25px;
      line-height: 25px;
      @media screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: ${(props) => props.theme.color.deepGrey};
    transform: scale(1.5);

    &:hover {
      background-color: ${(props) => props.theme.color.lightGrey};
      transition: 0.3s;
    }
  }
  .form {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const NewPosMapping = ({ isOpen, handleClose, addNewPosMapping }) => {
  const [posId, setPosId] = useState('');
  const [email, setEmail] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    const data = { email, posId };
    addNewPosMapping(data);
    return handleClose();
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose}>
      <Wrapper>
        <div className="header">
          <div className="header_title">
            <h6>New POS Mapping</h6>
          </div>

          <div onClick={handleClose} className="cancel">
            <CloseIcon />
          </div>
        </div>
        <div className="form">
          <TextInput label="POS ID:" value={posId} onChange={setPosId} />
          <TextInput label="Email:" value={email} onChange={setEmail} />
        </div>
        <Button text="Map New POS" fullWidth={true} onClick={onSubmit} />
      </Wrapper>
    </Modal>
  );
};

NewPosMapping.defaultProps = {
  isOpen: false,
};

NewPosMapping.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addNewPosMapping: PropTypes.func.isRequired,
};

export default connect(null, { addNewPosMapping })(NewPosMapping);
