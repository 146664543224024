import querystring from 'querystring';
import client from 'src/client';
import {
  ADD_YAWO_LOANS,
  ADD_YAWO_LOAN,
  YAWO_LOAN_IS_LOADING,
  YAWO_LOAN_NOT_LOADING,
  SET_YAWO_LOAN_DATE,
  RESET_YAWO_LOAN_DATE,
  SET_YAWO_LOAN_LIMIT,
  SET_YAWO_LOAN_STATUS,
  SET_YAWO_LOAN_PAGE,
} from 'src/store/types';
import { errorAlert, infoAlert, clearAlert, successAlert } from 'src/store/actions/alertActions';
import { dateFormatYMD } from 'src/utils/helpers';

const { authService } = client;

const yawoLoanIsLoading =
  (msg = 'Loading Yawo Loans') =>
  (dispatch) => {
    dispatch({ type: YAWO_LOAN_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const yawoLoanNotLoading = () => (dispatch) => {
  dispatch({ type: YAWO_LOAN_NOT_LOADING });
};

const addYawoLoans = (yawoLoans) => {
  return { type: ADD_YAWO_LOANS, payload: yawoLoans };
};

const addYawoLoan = (yawoLoan) => {
  return { type: ADD_YAWO_LOAN, payload: yawoLoan };
};

export const setYawoLoanDate = (startDate, endDate) => {
  return { type: SET_YAWO_LOAN_DATE, payload: { startDate, endDate } };
};

export const resetYawoLoanDate = () => {
  return { type: RESET_YAWO_LOAN_DATE };
};

export const setYawoLoanPage = (page) => {
  return { type: SET_YAWO_LOAN_PAGE, payload: page };
};

export const setYawoLoanLimit = (limit) => {
  return { type: SET_YAWO_LOAN_LIMIT, payload: limit };
};

export const setYawoLoanStatus = (status) => {
  return { type: SET_YAWO_LOAN_STATUS, payload: status };
};

const generatePath = (endPoint, yawoLoan, query) => {
  const { limit, page, startDate, endDate } = yawoLoan || {};
  query = {
    page,
    limit,
    startDate: startDate ? dateFormatYMD(startDate) : undefined,
    endDate: endDate ? dateFormatYMD(endDate) : dateFormatYMD(Date.now()),
    ...query,
  };
  if (query.page === undefined) delete query.page;
  if (query.limit === undefined) delete query.limit;
  if (query.startDate === undefined) delete query.startDate;
  if (query.endDate === undefined) delete query.endDate;
  let url = endPoint;
  if (query && Object.keys(query).length > 0) {
    url += `?${querystring.encode(query)}`;
  }
  return url;
};

export const getAllYawoLoans =
  (query = {}) =>
  (dispatch, getState) => {
    dispatch(yawoLoanIsLoading(query?.search ? 'Searching...' : 'Loading Yawo Loans'));
    const { yawoLoans } = getState().yawoLoan;
    const { profile } = getState().user;
    const shortCode = profile.platform;
    const path = generatePath('/yawo/loans', yawoLoans, { shortCode, ...query });
    authService
      .get(path)
      .then((res) => {
        dispatch(addYawoLoans(res?.data?.data));
        dispatch(clearAlert());
      })
      .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Loans' })))
      .finally(() => dispatch(yawoLoanNotLoading()));
  };

export const getSingleYawoLoan = (loanId, extraParams) => (dispatch) => {
  const { silently = false } = extraParams || {};
  if (!silently) {
    dispatch(yawoLoanIsLoading('Loading Yawo Loan'));
  }
  authService
    .get(`/yawo/loans/${loanId}`)
    .then((res) => {
      dispatch(addYawoLoan(res?.data?.data));
      dispatch(clearAlert());
    })
    .catch(() => dispatch(errorAlert({ msg: 'Error Loading Yawo Loan' })))
    .finally(() => dispatch(yawoLoanNotLoading()));
};

export const approveLoan =
  ({ shortCode, loanId }, callbacks) =>
  (dispatch) => {
    dispatch(yawoLoanIsLoading('approving Loan ...'));
    authService
      .post('/yawo/loans/verify', { shortCode, loanId })
      .then((res) => {
        dispatch(addYawoLoan(res?.data?.data));
        dispatch(successAlert('Loan Approved'));
        if (callbacks.onSuccess) callbacks.onSuccess();
      })
      .catch((err) => {
        dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error approving loan' }));
      })
      .finally(() => dispatch(yawoLoanNotLoading()));
  };

export const rejectLoan =
  ({ loanId, message }, callbacks) =>
  (dispatch) => {
    dispatch(yawoLoanIsLoading('rejecting Loan ...'));
    authService
      .post('/yawo/loans/reject', { loanId, message })
      .then((res) => {
        dispatch(addYawoLoan(res?.data?.data));
        dispatch(successAlert('Loan Rejected'));
        if (callbacks.onSuccess) callbacks.onSuccess();
      })
      .catch((err) => {
        dispatch(errorAlert({ msg: err?.response?.data?.message || 'Error rejecting loan' }));
      })
      .finally(() => dispatch(yawoLoanNotLoading()));
  };
