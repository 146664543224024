import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TextInput, PasswordInput } from 'src/components/common/inputs';
import Button from 'src/components/common/Button';
import { loginUser } from 'src/store/actions/userActions';
import { connect } from 'react-redux';

const Wrapper = styled(Paper)`
  padding: 2rem;
  max-width: 30rem;
  min-width: 25rem;
  display: grid;
  grid-gap: 0.6rem;
  @media screen and (max-width: 425px) {
    max-width: 90%;
    min-width: 90%;
  }
  .forgot_section {
    display: flex;
    justify-content: flex-end;
  }
  .forgot_link {
    font-size: 0.8rem;
    color: ${(props) => props.theme.color.cyan};
    text-decoration: none;
  }
`;

const LoginForm = ({ loginUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    const data = {
      email,
      password,
    };
    loginUser(data);
  };

  return (
    <Wrapper>
      <TextInput label="Username or Email address" value={email} onChange={setEmail} />
      <PasswordInput label="Password" value={password} onChange={setPassword} />
      <div className="forgot_section">
        <Link to="/forgot" className="forgot_link">
          Forgot Password?
        </Link>
      </div>
      <Button text="Login" fullWidth={true} onClick={onSubmit} />
    </Wrapper>
  );
};

LoginForm.propTypes = {
  loginUser: PropTypes.func.isRequired,
};

export default connect(null, { loginUser })(LoginForm);
