import { combineReducers } from 'redux';
import { CLEAR_SESSION } from 'src/store/types';

import appReducer from './appReducer';
import alertReducer from './alertReducer';
import userReducer from './userReducer';
import transactionReducer from './transactionReducer';
import priceReducer from './priceReducer';
import mappingReducer from './mappingReducer';
import posMappingReducer from './posMappingReducer';
import platformReducer from './platformReducer';
import accountReducer from './accountReducer';
import yawoLoanReducer from './yawo/loanReducer';
import yawoWalletHistoryReducer from './yawo/walletHistoryReducer';
import yawoTransactionReducer from './yawo/transactionReducer';
import yawoCollectionReducer from './yawo/collectionReducer';
import yawoVendorReducer from './yawo/vendorReducer';
import yawoAgentReducer from './yawo/agentReducer';
import yawoCustomerReducer from './yawo/customerReducer';
import yawoGuarantorReducer from './yawo/guarantorReducer';

const reducers = combineReducers({
  app: appReducer,
  alert: alertReducer,
  user: userReducer,
  transaction: transactionReducer,
  price: priceReducer,
  mapping: mappingReducer,
  posMapping: posMappingReducer,
  platform: platformReducer,
  account: accountReducer,
  yawoLoan: yawoLoanReducer,
  yawoWalletHistory: yawoWalletHistoryReducer,
  yawoTransaction: yawoTransactionReducer,
  yawoCollection: yawoCollectionReducer,
  yawoVendor: yawoVendorReducer,
  yawoAgent: yawoAgentReducer,
  yawoCustomer: yawoCustomerReducer,
  yawoGuarantor: yawoGuarantorReducer,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === CLEAR_SESSION) state = undefined;
  return reducers(state, action);
};

export default rootReducer;
