import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Select, FormControl, MenuItem, Typography } from '@material-ui/core';

const Wrapper = styled.div`
  width: 100%;
  .label {
    color: ${(props) => props.theme.color.grey};
    min-width: max-content;
  }
  .input {
    margin: 0;
    min-width: 100%;
    /* padding-top: 0.5rem; */
  }
  .error {
    color: red;
  }
  .select {
    height: auto;
    min-width: 79px;
    width: 100%;
    outline: 0px;
    border-radius: 0;
    border: 1px solid ${(props) => props.theme.color.font_03};
  }
  .MuiOutlinedInput-notchedOutline legend {
    color: ${(props) => props.theme.color.font_01};
    font-size: 11px;
    visibility: visible;
  }
`;

const SelectInput = ({ label, onChange, value, options, error, selectLabel, name }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const displayError = () => (
    <Typography variant="subtitle2" component="h6" className="error">
      {error && error}
    </Typography>
  );

  return (
    <Wrapper>
      <FormControl variant="outlined" fullWidth className="input">
        <p className="label">{label}</p>
        <Select
          className="select"
          fullWidth
          margin="dense"
          name={name}
          // variant="outlined"
          label={selectLabel}
          placeholder="Select here"
          value={value}
          onChange={handleChange}
          error={error ? true : false}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
        {error && displayError()}
      </FormControl>
    </Wrapper>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  selectLabel: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export { SelectInput };
