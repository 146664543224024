import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
// import Button from 'src/components/common/Button';
// import { formatDate } from 'src/utils/helpers';
// import { DateRangeInput } from 'src/components/common/inputs';
import GuarantorTable from './GuarantorTable';
import {
  getAllYawoGuarantors,
  setYawoGuarantorPage,
  setYawoGuarantorLimit,
  setYawoGuarantorDate,
  resetYawoGuarantorDate,
} from 'src/store/actions/yawo/guarantorActions';
import AsyncSelect from 'react-select/async';
import reactSelectUtil from 'src/utils/reactSelectUtil';
import { loadCustomerOptions } from 'src/store/actions/yawo/customerActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .time_display {
    min-width: 13rem;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function GuarantorsComponent({
  getAllYawoGuarantors,
  isLoading,
  yawoGuarantors,
  setYawoGuarantorPage,
  setYawoGuarantorLimit,
  // setYawoGuarantorDate,
  // resetYawoGuarantorDate,
  profile,
}) {
  const shortCode = profile.platform;
  const [customer, setCustomer] = useState(null);
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  const getYawoGuarantors = () => {
    const query = {};
    if (customer?.value) {
      query.customerId = customer?.value;
    }
    getAllYawoGuarantors(query);
  };

  useEffect(() => {
    getYawoGuarantors();
  }, [customer]);

  const onPageChange = (page) => {
    setYawoGuarantorPage(page);
    getYawoGuarantors();
  };

  const onLimitChange = (limit) => {
    setYawoGuarantorLimit(limit);
    getYawoGuarantors();
  };

  // const onDateChange = (date) => {
  //   setYawoGuarantorDate(date.startDate, date.endDate);
  //   getYawoGuarantors();
  // };

  // const onResetDateClick = () => {
  //   resetYawoGuarantorDate();
  //   getYawoGuarantors();
  // };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard
          isLoading={isLoading}
          title="Total Guarantors"
          value={yawoGuarantors !== null && yawoGuarantors.count}
        />
        {/* <div className="date_range">
          <Button
            onClick={toggle}
            text={`${yawoGuarantors === null ? '' : formatDate(yawoGuarantors.start_date)} - ${
              yawoGuarantors === null ? '' : formatDate(yawoGuarantors.end_date)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div> */}
      </div>

      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', margin: '1rem 0', fontSize: '1rem' }}>
        <h4>Customer:</h4>
        <div style={{ width: '20rem' }}>
          <AsyncSelect
            name="customer"
            placeholder="Select Customer"
            cacheOptions
            loadOptions={(val) => loadCustomerOptions(val, shortCode)}
            theme={reactSelectUtil.theme}
            styles={reactSelectUtil.styles}
            isClearable
            onChange={(newValue) => setCustomer(newValue)}
            value={customer}
          />
        </div>
      </div>

      <GuarantorTable
        isLoading={isLoading}
        tableData={yawoGuarantors}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

GuarantorsComponent.propTypes = {
  getAllYawoGuarantors: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  yawoGuarantors: PropTypes.object,
  setYawoGuarantorLimit: PropTypes.func.isRequired,
  setYawoGuarantorPage: PropTypes.func.isRequired,
  setYawoGuarantorDate: PropTypes.func.isRequired,
  resetYawoGuarantorDate: PropTypes.func.isRequired,
  profile: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { yawoGuarantors, isLoading } = state.yawoGuarantor;
  const { profile } = state.user;
  return {
    isLoading,
    yawoGuarantors,
    profile,
  };
};

export default connect(mapStateToProps, {
  getAllYawoGuarantors,
  setYawoGuarantorLimit,
  setYawoGuarantorPage,
  setYawoGuarantorDate,
  resetYawoGuarantorDate,
})(GuarantorsComponent);
