import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { formatDate, formatAmount } from 'src/utils/helpers';
import { DateRangeInput } from 'src/components/common/inputs';
import DashboardTable from 'src/components/dashboard/DashboardTable';
import {
  getTransactionsByWalletId,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
} from 'src/store/actions/transactionActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

const UserTransactions = ({
  match,
  getTransactionsByWalletId,
  isLoading,
  transactions,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
}) => {
  const { walletId } = match.params;
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('overview');
  const [state, setState] = useState('overview');
  const [subType, setSubType] = useState(' ');

  const toggle = () => setIsOpen(!isOpen);

  const getTransactions = useCallback(
    (state) => {
      state === 'overview' ? getTransactionsByWalletId(walletId, 'all') : getTransactionsByWalletId(walletId, state);
    },
    [getTransactionsByWalletId, walletId]
  );

  useEffect(() => {
    getTransactions(state);
  }, [state, getTransactions]);

  const onNavChange = (type) => {
    setType(type);
    setSubType(' ');
    setTransactionPage(1);
    setTransactionStatus('all');
    setState(type);
  };

  const onPageChange = (page) => {
    setTransactionPage(page);
    getTransactions(state);
  };

  const onLimitChange = (limit) => {
    setTransactionLimit(limit);
    getTransactions(state);
  };

  const onResetDateClick = () => {
    resetTransactionDate();
    getTransactions(state);
  };

  const onStatusChange = (status) => {
    setTransactionStatus(status);
    getTransactions(state);
  };

  const onDateChange = (date) => {
    setTransactionDate(date.startDate, date.endDate);
    getTransactions(state);
  };

  const onSubTypeChange = (type) => {
    setSubType(type);
    setState(type);
  };
  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard isLoading={isLoading} title="Volume" value={transactions !== null && transactions.count} />
        <DisplayCard
          isLoading={isLoading}
          title="Value"
          value={transactions !== null && formatAmount(transactions.value)}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Revenue"
          value={transactions !== null && formatAmount(transactions.revenue)}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Income"
          value={transactions !== null && formatAmount(transactions.income)}
        />
        <div className="date_range">
          <Button
            onClick={toggle}
            text={`${transactions === null ? '' : formatDate(transactions.start_date)} - ${
              transactions === null ? '' : formatDate(transactions.end_date)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div>
      </div>
      <DashboardTable
        subType={subType}
        setSubType={onSubTypeChange}
        type={type}
        setType={onNavChange}
        isLoading={isLoading}
        tableData={transactions}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        onStatusChange={onStatusChange}
      />
    </Wrapper>
  );
};

UserTransactions.propTypes = {
  getTransactionsByWalletId: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  transactions: PropTypes.array.isRequired,
  setTransactionLimit: PropTypes.func.isRequired,
  setTransactionPage: PropTypes.func.isRequired,
  setTransactionDate: PropTypes.func.isRequired,
  setTransactionStatus: PropTypes.func.isRequired,
  resetTransactionDate: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { transactions, isLoading } = state.transaction;
  return {
    isLoading,
    transactions,
  };
};

export default connect(mapStateToProps, {
  getTransactionsByWalletId,
  setTransactionStatus,
  setTransactionDate,
  setTransactionLimit,
  setTransactionPage,
  resetTransactionDate,
})(withRouter(UserTransactions));
