import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { getAllYawoCustomers } from 'src/store/actions/yawo/customerActions';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.white};
  border: 2px solid ${(props) => props.theme.color.grey2};
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
  height: 40px;
  display: flex;
  padding: 0px 17px;
  input {
    color: ${(props) => props.theme.color.font_02};
    font-family: satoshi;

    &::placeholder {
      font-family: satoshi;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }
`;

const CustomerSearch = ({ getAllYawoCustomers }) => {
  const [searchString, setSearchString] = useState('');

  const searchCustomers = () => {
    getAllYawoCustomers({ page: 1, search: searchString });
  };

  const onChange = (e) => {
    setSearchString(e.target.value);
    if (e.target.value === '') getAllYawoCustomers({ page: 1 });
  };

  const onEnterPress = (e) => {
    if (e.key === 'Enter' && searchString !== '') {
      e.preventDefault();
      e.stopPropagation();
      searchCustomers();
    }
  };

  return (
    <Wrapper>
      <InputBase placeholder="Search Customers..." value={searchString} onChange={onChange} onKeyPress={onEnterPress} />
      <IconButton onClick={searchCustomers}>
        <SearchIcon />
      </IconButton>
    </Wrapper>
  );
};

CustomerSearch.propTypes = {
  getAllYawoCustomers: PropTypes.func.isRequired,
};

export default connect(null, { getAllYawoCustomers })(CustomerSearch);
