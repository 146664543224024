import {
  YAWO_GUARANTOR_IS_LOADING,
  YAWO_GUARANTOR_NOT_LOADING,
  ADD_YAWO_GUARANTORS,
  ADD_YAWO_GUARANTOR,
  SET_YAWO_GUARANTOR_DATE,
  RESET_YAWO_GUARANTOR_DATE,
  SET_YAWO_GUARANTOR_LIMIT,
  SET_YAWO_GUARANTOR_PAGE,
  SET_YAWO_GUARANTOR_STATUS,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoGuarantors: null,
  yawoGuarantor: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_GUARANTOR_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_GUARANTOR_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_GUARANTORS:
      return { ...state, yawoGuarantors: action.payload };
    case ADD_YAWO_GUARANTOR:
      return { ...state, yawoGuarantor: action.payload };
    case SET_YAWO_GUARANTOR_DATE:
      return {
        ...state,
        yawoGuarantors: {
          ...state.yawoGuarantors,
          start_date: action.payload.start_date,
          end_date: action.payload.end_date,
        },
      };
    case RESET_YAWO_GUARANTOR_DATE:
      return {
        ...state,
        yawoGuarantors: {
          ...state.yawoGuarantors,
          start_date: null,
          end_date: null,
          page: 1,
        },
      };
    case SET_YAWO_GUARANTOR_LIMIT:
      return {
        ...state,
        yawoGuarantors: {
          ...state.yawoGuarantors,
          limit: action.payload,
          page: 1,
        },
      };
    case SET_YAWO_GUARANTOR_PAGE:
      return {
        ...state,
        yawoGuarantors: {
          ...state.yawoGuarantors,
          page: action.payload,
        },
      };
    case SET_YAWO_GUARANTOR_STATUS:
      return {
        ...state,
        yawoGuarantors: {
          ...state.yawoGuarantors,
          status: action.payload,
          page: 1,
        },
      };
    default:
      return state;
  }
}
