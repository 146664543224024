import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Table from 'src/components/common/table/Table';
import { overviewHeader } from './walletHistoriesHeaders';
import { sortTableData } from 'src/utils/helpers';
import { SelectInput } from 'src/components/common/inputs';
import WalletHistorySearch from './WalletHistorySearch';

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const typeOptions = [
  { text: 'All', value: 'all' },
  { text: 'Credit', value: 'credit' },
  { text: 'Debit', value: 'debit' },
];

const statusOptions = [
  { text: 'All', value: 'all' },
  { text: 'Success', value: 'success' },
  { text: 'Failed', value: 'failed' },
];

function WalletHistoryTable({ tableData, onPageChange, onLimitChange, type, setType, status, onStatusChange }) {
  const tableKey = {};
  const rightHeader = (
    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
      <div>
        <SelectInput selectLabel="Type" options={typeOptions} value={type} onChange={setType} />
      </div>
      <div>
        <SelectInput selectLabel="Status" options={statusOptions} value={status} onChange={onStatusChange} />
      </div>
    </div>
  );

  return (
    <Wrapper>
      <Table
        headers={overviewHeader}
        leftHeader={<WalletHistorySearch />}
        rightHeader={rightHeader}
        showNav={true}
        data={tableData === null ? [] : sortTableData(tableData.data, tableKey)}
        limit={tableData === null ? 20 : tableData.limit}
        page={tableData === null ? 1 : tableData.page}
        count={tableData === null ? 1 : tableData.count}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
      />
    </Wrapper>
  );
}

WalletHistoryTable.propTypes = {
  tableData: PropTypes.object,
  onLimitChange: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  leftHeader: PropTypes.any,
  rightHeader: PropTypes.any,
  type: PropTypes.string,
  setType: PropTypes.func.isRequired,
  status: PropTypes.string,
  onStatusChange: PropTypes.func.isRequired,
};

export default WalletHistoryTable;
