import { ADD_PLATFORMS, PLATFORM_IS_LOADING, PLATFORM_NOT_LOADING } from 'src/store/types';
import client from 'src/client';
import { errorAlert } from './alertActions';

export const platformIsLoading = () => {
  return {
    type: PLATFORM_IS_LOADING,
  };
};

export const platformNotLoading = () => {
  return {
    type: PLATFORM_NOT_LOADING,
  };
};

const addPlatforms = (platforms) => {
  return {
    type: ADD_PLATFORMS,
    payload: platforms,
  };
};

export const getPlatforms = () => (dispatch) => {
  dispatch(platformIsLoading());
  client.authService
    .get('/platforms')
    .then((res) => {
      dispatch(addPlatforms(res.data));
      dispatch(platformNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Error Loading Platforms' }));
      dispatch(platformNotLoading());
    });
};
