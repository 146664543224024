import {
  YAWO_TRANSACTION_IS_LOADING,
  YAWO_TRANSACTION_NOT_LOADING,
  ADD_YAWO_TRANSACTIONS,
  ADD_YAWO_TRANSACTION,
  CLEAR_YAWO_TRANSACTIONS,
  SET_YAWO_TRANSACTION_DATE,
  SET_YAWO_TRANSACTION_LIMIT,
  SET_YAWO_TRANSACTION_PAGE,
  SET_YAWO_TRANSACTION_STATUS,
  RESET_YAWO_TRANSACTION_DATE,
} from 'src/store/types';

const initialState = {
  isLoading: false,
  yawoTransactions: null,
  yawoTransaction: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case YAWO_TRANSACTION_IS_LOADING:
      return { ...state, isLoading: true };
    case YAWO_TRANSACTION_NOT_LOADING:
      return { ...state, isLoading: false };
    case ADD_YAWO_TRANSACTIONS:
      return { ...state, yawoTransactions: action.payload };
    case ADD_YAWO_TRANSACTION:
      return { ...state, yawoTransaction: action.payload };
    case CLEAR_YAWO_TRANSACTIONS:
      return { ...state, yawoTransactions: initialState.yawoTransactions };
    case SET_YAWO_TRANSACTION_DATE:
      return {
        ...state,
        yawoTransactions: {
          ...state.yawoTransactions,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          page: 1,
        },
      };
    case RESET_YAWO_TRANSACTION_DATE:
      return {
        ...state,
        yawoTransactions: {
          ...state.yawoTransactions,
          startDate: null,
          endDate: null,
          page: 1,
        },
      };
    case SET_YAWO_TRANSACTION_LIMIT:
      return {
        ...state,
        yawoTransactions: {
          ...state.yawoTransactions,
          limit: action.payload,
          page: 1,
        },
      };
    case SET_YAWO_TRANSACTION_PAGE:
      return {
        ...state,
        yawoTransactions: {
          ...state.yawoTransactions,
          page: action.payload,
        },
      };
    case SET_YAWO_TRANSACTION_STATUS:
      return {
        ...state,
        yawoTransactions: {
          ...state.yawoTransactions,
          status: action.payload,
          page: 1,
        },
      };
    default:
      return state;
  }
}
