import React, { useEffect, useState } from 'react';
import Table from 'src/components/common/table/Table';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PriceTableNavBar from './PriceTableNavBar';
import { getPriceSettingHeader } from './priceSettingHeader';
import { connect } from 'react-redux';
import { sortTableData } from 'src/utils/helpers';
import { getPrices, getUpPrices } from 'src/store/actions/priceActions';
import AddNewPrice from './addPrice';
import UpdatePrice from './updatePrice';
import DeletePrice from './deletePrice';

const Wrapper = styled.div`
  .rightTableHeader {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
  }
  .leftTableHeader {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    align-items: center;
    @media screen and (max-width: 768px) {
      gap: 1rem;
    }
  }
  input[type='radio'] {
    display: none;
  }
  .radio {
    label {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 13px;
      color: ${(props) => props.theme.color.font_01};
      border-bottom: 2px solid ${(props) => props.theme.color.white};
      &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.color.black};
        transition: 0.3s;
      }
    }
    .active-label {
      font-weight: 700;
      color: ${(props) => props.theme.color.cyan};
    }
  }
`;

const terminals = [
  { value: 'flutterwave', label: 'Terminal 1' },
  { value: 'up', label: 'Terminal 2' },
  { value: 'ikonik', label: 'Terminal 3' },
];

function PriceSettings({ category, navOptions, getPrices, getUpPrices, prices, navDefault, terminal, setTerminal }) {
  const [type, setType] = useState('p2p');

  const [selected, setSelected] = useState('');



  useEffect(() => {
    setType(navDefault);
  }, [navDefault]);

  useEffect(() => {
    // if (type) {
    if (type === 'pos') {
      terminal === 'up' ? getUpPrices() : getPrices(type, terminal);
    } else {
      getPrices(type, terminal);
    }
    // }
    setSelected('');
  }, [type, getPrices, getUpPrices, terminal]);

  const header = getPriceSettingHeader(category, selected, setSelected);

  const resetSelected = () => {
    setSelected('');
  };

  const terminals13 = terminals.filter((terminal13) => {
    return terminal13.value !== 'up';
  });

  return (
    <Wrapper>
      <PriceTableNavBar isActive={type} setIsActive={setType} options={navOptions || []} />
      <Table
        headers={header.header}
        rightHeader={
          <div className="rightTableHeader">
            {selected !== '' && (
              <UpdatePrice
                data={prices[Number(selected)]}
                resetSelected={resetSelected}
                terminal={terminal}
                category={category}
                type={type}
              />
            )}
            {selected !== '' && terminal !== 'up' && (
              <DeletePrice
                resetSelected={resetSelected}
                category={category}
                type={type}
                data={prices[Number(selected)]}
                terminal={terminal}
              />
            )}
            {terminal !== 'up' && <AddNewPrice category={category} type={type} terminal={terminal} />}
          </div>
        }
        leftHeader={
          type === 'pos' && category !== 'pos-activation' ? (
            <div className="leftTableHeader">
              {terminals.map((option) => (
                <div className="radio" key={option.value}>
                  <label className={terminal === option.value && 'active-label'}>
                    <input
                      type="radio"
                      value={option.value}
                      checked={terminal === option.value}
                      onChange={(e) => setTerminal(e.target.value)}
                    />
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          ) : category === 'pos-activation' ? (
            <div className="leftTableHeader">
              {terminals13.map((option) => (
                <div className="radio" key={option.value}>
                  <label className={terminal === option.value ? 'active-label' : ''}>
                    <input
                      type="radio"
                      value={option.value}
                      checked={terminal === option.value}
                      onChange={(e) => setTerminal(e.target.value)}
                    />
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          ) : null
        }
        data={prices === null ? [] : sortTableData(prices, header.key)}
      />
    </Wrapper>
  );
}

PriceSettings.propTypes = {
  category: PropTypes.string.isRequired,
  navDefault: PropTypes.string,
  getPrices: PropTypes.func.isRequired,
  getUpPrices: PropTypes.func.isRequired,
  prices: PropTypes.array,
  navOptions: PropTypes.array,
  terminal: PropTypes.string,
  setTerminal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { prices, isLoading } = state.price;
  return {
    prices,
    isLoading,
  };
};

export default connect(mapStateToProps, { getPrices, getUpPrices })(PriceSettings);
