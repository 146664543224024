import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UpdateTransferPrice from './UpdateTransferPrice';
import UpdateWithdrawalPrice from './UpdateWithdrawalPrice';
import UpdateAirtimePrice from './UpdateAirtimePrice';
import UpdateDataPrice from './UpdateDataPrice';
import UpdateTvCablePrice from './UpdateTvCablePrice';
import UpdatePhcnPrice from './UpdatePhcnPrice';
import UpdatePosActivationPrice from './UpdatePosActivationPrice';
import Button from 'src/components/common/Button';

function UpdatePrice({ category, type, data, resetSelected, terminal }) {
  const [withdrawalIsOpen, setWithdrawalIsOpen] = useState(false);
  const [transferIsOpen, setTransferIsOpen] = useState(false);
  const [airtimeIsOpen, setAirtimeIsOpen] = useState(false);
  const [dataIsOpen, setDataIsOpen] = useState(false);
  const [tvCableIsOpen, setTvCableIsOpen] = useState(false);
  const [phcnIsOpen, setPhcnIsOpen] = useState(false);
  const [posActivationIsOpen, setPosActivationIsOpen] = useState(false);

  const toggleTransfer = () => {
    setTransferIsOpen(!transferIsOpen);
  };

  const toggleWithdrawal = () => {
    setWithdrawalIsOpen(!withdrawalIsOpen);
  };

  const toggleAirtime = () => {
    setAirtimeIsOpen(!airtimeIsOpen);
  };

  const toggleData = () => {
    setDataIsOpen(!dataIsOpen);
  };

  const toggleTvCable = () => {
    setTvCableIsOpen(!tvCableIsOpen);
  };

  const togglePhcn = () => {
    setPhcnIsOpen(!phcnIsOpen);
  };

  const togglePosActivation = () => {
    setPosActivationIsOpen(!posActivationIsOpen);
  };

  return (
    <div>
      {category === 'transfers' && <Button text="Update" onClick={toggleTransfer} />}
      {category === 'withdrawals' && <Button text="Update" onClick={toggleWithdrawal} />}
      {category === 'airtime' && <Button text="Update" onClick={toggleAirtime} />}
      {category === 'data' && <Button text="Update" onClick={toggleData} />}
      {category === 'tv-cable' && <Button text="Update" onClick={toggleTvCable} />}
      {category === 'electricity' && <Button text="Update" onClick={togglePhcn} />}
      {category === 'pos-activation' && <Button text="Update" onClick={togglePosActivation} />}

      <UpdateTransferPrice
        resetSelected={resetSelected}
        isOpen={transferIsOpen}
        handleClose={toggleTransfer}
        type={type}
        data={data}
      />
      <UpdateWithdrawalPrice
        resetSelected={resetSelected}
        isOpen={withdrawalIsOpen}
        handleClose={toggleWithdrawal}
        type={type}
        data={data}
        terminal={terminal}
      />
      <UpdateAirtimePrice
        resetSelected={resetSelected}
        isOpen={airtimeIsOpen}
        handleClose={toggleAirtime}
        type={type}
        data={data}
      />
      <UpdateDataPrice
        resetSelected={resetSelected}
        isOpen={dataIsOpen}
        handleClose={toggleData}
        type={type}
        data={data}
      />
      <UpdateTvCablePrice
        resetSelected={resetSelected}
        isOpen={tvCableIsOpen}
        handleClose={toggleTvCable}
        type={type}
        data={data}
      />
      <UpdatePhcnPrice
        resetSelected={resetSelected}
        isOpen={phcnIsOpen}
        handleClose={togglePhcn}
        type={type}
        data={data}
      />
      <UpdatePosActivationPrice
        resetSelected={resetSelected}
        isOpen={posActivationIsOpen}
        handleClose={togglePosActivation}
        type={type}
        terminal={terminal}
        data={data}
      />
    </div>
  );
}

UpdatePrice.propTypes = {
  category: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  terminal: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  resetSelected: PropTypes.func.isRequired,
};

export default UpdatePrice;
