import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ViewTransaction from './ViewTransaction';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .icon {
    cursor: pointer;
  }
  .approve {
    color: ${(props) => props.theme.color.cyan};
  }
  .reject {
    color: ${(props) => props.theme.color.red};
  }
`;

const TransactionActions = ({ transaction }) => {
  return (
    <Wrapper>
      <ViewTransaction transactionRef={transaction.yawoRef} />
    </Wrapper>
  );
};

TransactionActions.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default TransactionActions;
