/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserById } from 'src/store/actions/accountActions';
import Loader from 'src/components/notification/Loader';
import { formatDate, formatAmount } from 'src/utils/helpers';
import SuspendUser from './SuspendUser';
import ActivateUser from './ActivateUser';
import AppLayout from 'src/components/layout/AppLayout';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.white};
  padding: 40px;

  .img-btns {
    display: flex;
    gap: 40px;
    align-items: center;
    flex-wrap: wrap;
  }
  .profile-image {
    width: 136px;
    height: 136px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.color.grey2};
  }
  .buttons {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }
  .line {
    border-top: 2px solid ${(props) => props.theme.color.font_03};
  }
  .details-section {
    padding: 16px 0;
    .details {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(219px, 1fr));
      gap: 15px;
    }
    .text {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.25px;
    }

    .value,
    h4 {
      color: ${(props) => props.theme.color.font_04};
    }
    h4 {
      margin-bottom: 10px;
    }
    .key {
      color: ${(props) => props.theme.color.deepGrey};
    }
    .decorated {
      color: ${(props) => props.theme.color.cyan};
    }
  }
`;

const User = ({ getUserById, account, isLoading }) => {
  const { userId } = useParams();

  const [openSuspend, setOpenSuspend] = useState(false);
  const toggleSuspend = () => setOpenSuspend(!openSuspend);
  const [openActivate, setOpenActivate] = useState(false);
  const toggleActivate = () => setOpenActivate(!openActivate);

  useEffect(() => {
    getUserById(userId);
  }, [getUserById, userId]);

  const fullName = `${account?.firstName} ${account?.lastName}`;

  return (
    <AppLayout title={account?.firstName ? fullName : ''} showBackButton goBackTo="/users">
      {isLoading ? (
        <Loader />
      ) : !account ? (
        <p>Account not found</p>
      ) : (
        <Wrapper>
          <div className="img-btns">
            {/* <div className="profile-image"></div> */}
            <div className="buttons">
              {account?.status === 'active' && (
                <>
                  <Button theme="red" text="Suspend" onClick={toggleSuspend} />
                  <SuspendUser
                    isOpen={openSuspend}
                    handleClose={toggleSuspend}
                    userId={userId}
                    onSuccess={() => getUserById(userId)}
                  />
                </>
              )}
              {account?.status === 'suspended' && (
                <>
                  <Button text="Activate" onClick={toggleActivate} />
                  <ActivateUser
                    isOpen={openActivate}
                    handleClose={toggleActivate}
                    userId={userId}
                    onSuccess={() => getUserById(userId)}
                  />
                </>
              )}

              <Link to={`/users/${account?.walletId}/transactions`}>
                <Button text="View Transactions" />
              </Link>

              {/* <Button text="Reset PIN" /> */}
            </div>
          </div>
          <br />
          <div className="line"></div>

          <div className="details-section">
            <h4 className="text">Personal Details</h4>
            <div className="details">
              <div className="info">
                <p className="key text">FullName</p>
                <p className="value text">
                  {account?.firstName} {account?.lastName}
                </p>
              </div>
              <div className="info">
                <p className="key text">Phone Number</p>
                <p className="value text">{account?.phoneNumber}</p>
              </div>
              <div className="info">
                <p className="key text">Email Address</p>
                <p className="value text">{account?.email}</p>
              </div>
              <div className="info">
                <p className="key text">Signup Date</p>
                <p className="value text">{formatDate(account?.timeCreated)}</p>
              </div>
            </div>
          </div>
          <div className="line"></div>

          <div className="details-section">
            <h4 className="text">Transaction Details</h4>
            <div className="details">
              <div className="info">
                <p className="key text">Transfer ID / Wallet ID</p>
                <p className="value text">{account?.iuwi}</p>
              </div>
              <div className="info">
                <p className="key text">Bank Account</p>
                <p className="value text">{account?.accountNumber}</p>
              </div>
              <div className="info">
                <p className="key text">Bank Name</p>
                <p className="value text">{account?.accountBank}</p>
              </div>
              <div className="info">
                <p className="key text">Wallet Limit</p>
                <p className="value text">{formatAmount(account?.walletLimit)}</p>
              </div>
            </div>
          </div>

          {/*
          <div className="line"></div>

        <div className="details-section">
          <h4 className="text">Device Details</h4>
          <div className="details">
            <div className="info">
              <p className="key text">Device ID</p>
              <Link className="value text decorated">{account?.deviceId || '--'} </Link>
            </div>
          </div>
        </div> */}
        </Wrapper>
      )}
    </AppLayout>
  );
};

User.propTypes = {
  account: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getUserById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, account } = state.account;
  return { isLoading, account };
};

export default connect(mapStateToProps, { getUserById })(User);
