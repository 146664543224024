module.exports = Object.freeze({
  // APP ACTIONS TYPES
  CLEAR_SESSION: 'CLEAR_SESSION',
  APP_IS_LOADING: 'APP_IS_LOADING',
  APP_NOT_LOADING: 'APP_NOT_LOADING',

  //NOTIFICATION ACTION TYPES
  SUCCESS_ALERT: 'SUCCESS_ALERT',
  ERROR_ALERT: 'ERROR_ALERT',
  INFO_ALERT: 'INFO_ALERT',
  CLEAR_ALERT: 'CLEAR_ALERT',

  // USER ACTION TYPES
  SET_USER: 'SET_USER',
  SET_AUTH: 'SET_AUTH',
  REMOVE_USER: 'REMOVE_USER',

  // APP ACTIONS TYPES
  ADD_PLATFORMS: 'ADD_PLATFORMS',
  PLATFORM_IS_LOADING: 'PLATFORM_IS_LOADING',
  PLATFORM_NOT_LOADING: 'PLATFORM_NOT_LOADING',

  // TRANSACTION HISTORY
  TRANSACTION_IS_LOADING: 'TRANSACTION_IS_LOADING',
  TRANSACTION_NOT_LOADING: 'TRANSACTION_NOT_LOADING',
  ADD_TRANSACTIONS: 'ADD_TRANSACTIONS',
  ADD_TRANSACTION: 'ADD_TRANSACTION',
  CLEAR_TRANSACTIONS: 'CLEAR_TRANSACTIONS',
  SET_TRANSACTION_PAGE: 'SET_TRANSACTION_PAGE',
  SET_TRANSACTION_LIMIT: 'SET_TRANSACTION_LIMIT',
  SET_TRANSACTION_STATUS: 'SET_TRANSACTION_STATUS',
  SET_TRANSACTION_DATE: 'SET_TRANSACTION_DATE',
  RESET_TRANSACTION_DATE: 'RESET_TRANSACTION_DATE',

  // PRICE SETTING ACTIONS
  PRICE_IS_LOADING: 'PRICE_IS_LOADING',
  PRICE_NOT_LOADING: 'PRICE_NOT_LOADING',
  SET_CATEGORY: 'SET_CATEGORY',
  ADD_PRICES: 'ADD_PRICES',
  CLEAR_PRICES: 'CLEAR_PRICES',

  // MAPPING ACTIONS
  OLD_MAPPING_IS_LOADING: 'OLD_MAPPING_IS_LOADING',
  OLD_MAPPING_NOT_LOADING: 'OLD_MAPPING_NOT_LOADING',
  OLD_ADD_MAPPINGS: 'OLD_ADD_MAPPINGS',
  OLD_ADD_POS_MAPPINGS: 'OLD_ADD_POS_MAPPINGS',
  OLD_CLEAR_MAPPINGS: 'OLD_CLEAR_MAPPINGS',
  OLD_SET_POS_PAGE: 'OLD_SET_POS_PAGE',
  OLD_SET_POS_LIMIT: 'OLD_SET_POS_LIMIT',

  // POS MAPPING ACTIONS
  POS_MAPPING_IS_LOADING: 'POS_MAPPING_IS_LOADING',
  POS_MAPPING_NOT_LOADING: 'POS_MAPPING_NOT_LOADING',
  ADD_POS_MAPPINGS: 'ADD_POS_MAPPINGS',
  CLEAR_POS_MAPPINGS: 'CLEAR_POS_MAPPINGS',
  SET_POS_MAPPING_PAGE: 'SET_POS_MAPPING_PAGE',
  SET_POS_MAPPING_LIMIT: 'SET_POS_MAPPING_LIMIT',

  // APP ACTIONS TYPES
  ADD_ACCOUNTS: 'ADD_ACCOUNTS',
  ADD_ACCOUNT: 'ADD_ACCOUNT',
  CLEAR_ACCOUNT: 'CLEAR_ACCOUNT',
  ACCOUNT_IS_LOADING: 'ACCOUNT_IS_LOADING',
  ACCOUNT_NOT_LOADING: 'ACCOUNT_NOT_LOADING',
  SET_ACCOUNT_PAGE: 'SET_ACCOUNT_PAGE',
  SET_ACCOUNT_LIMIT: 'SET_ACCOUNT_LIMIT',
  SET_ACCOUNT_STATUS: 'SET_ACCOUNT_STATUS',

  // YAWO LOANS ACTION TYPES
  ADD_YAWO_LOANS: 'ADD_YAWO_LOANS',
  ADD_YAWO_LOAN: 'ADD_YAWO_LOAN',
  CLEAR_YAWO_LOANS: 'CLEAR_YAWO_LOANS',
  YAWO_LOAN_IS_LOADING: 'YAWO_LOAN_IS_LOADING',
  YAWO_LOAN_NOT_LOADING: 'YAWO_LOAN_NOT_LOADING',
  SET_YAWO_LOAN_PAGE: 'SET_YAWO_LOAN_PAGE',
  SET_YAWO_LOAN_LIMIT: 'SET_YAWO_LOAN_LIMIT',
  SET_YAWO_LOAN_STATUS: 'SET_YAWO_LOAN_STATUS',
  SET_YAWO_LOAN_DATE: 'SET_YAWO_LOAN_DATE',
  RESET_YAWO_LOAN_DATE: 'RESET_YAWO_LOAN_DATE',

  // YAWO WALLET HISTORY ACTION TYPES
  ADD_YAWO_WALLET_HISTORIES: 'ADD_YAWO_WALLET_HISTORIES',
  ADD_YAWO_WALLET_HISTORY: 'ADD_YAWO_WALLET_HISTORY',
  CLEAR_YAWO_WALLET_HISTORIES: 'CLEAR_YAWO_WALLET_HISTORIES',
  YAWO_WALLET_HISTORY_IS_LOADING: 'YAWO_WALLET_HISTORY_IS_LOADING',
  YAWO_WALLET_HISTORY_NOT_LOADING: 'YAWO_WALLET_HISTORY_NOT_LOADING',
  SET_YAWO_WALLET_HISTORY_PAGE: 'SET_YAWO_WALLET_HISTORY_PAGE',
  SET_YAWO_WALLET_HISTORY_LIMIT: 'SET_YAWO_WALLET_HISTORY_LIMIT',
  SET_YAWO_WALLET_HISTORY_STATUS: 'SET_YAWO_WALLET_HISTORY_STATUS',
  SET_YAWO_WALLET_HISTORY_DATE: 'SET_YAWO_WALLET_HISTORY_DATE',
  RESET_YAWO_WALLET_HISTORY_DATE: 'RESET_YAWO_WALLET_HISTORY_DATE',

  // YAWO TRANSACTION ACTION TYPES
  ADD_YAWO_TRANSACTIONS: 'ADD_YAWO_TRANSACTIONS',
  ADD_YAWO_TRANSACTION: 'ADD_YAWO_TRANSACTION',
  CLEAR_YAWO_TRANSACTIONS: 'CLEAR_YAWO_TRANSACTIONS',
  YAWO_TRANSACTION_IS_LOADING: 'YAWO_TRANSACTION_IS_LOADING',
  YAWO_TRANSACTION_NOT_LOADING: 'YAWO_TRANSACTION_NOT_LOADING',
  SET_YAWO_TRANSACTION_PAGE: 'SET_YAWO_TRANSACTION_PAGE',
  SET_YAWO_TRANSACTION_LIMIT: 'SET_YAWO_TRANSACTION_LIMIT',
  SET_YAWO_TRANSACTION_STATUS: 'SET_YAWO_TRANSACTION_STATUS',
  SET_YAWO_TRANSACTION_DATE: 'SET_YAWO_TRANSACTION_DATE',
  RESET_YAWO_TRANSACTION_DATE: 'RESET_YAWO_TRANSACTION_DATE',

  // YAWO COLLECTIONS ACTION TYPES
  ADD_YAWO_COLLECTIONS: 'ADD_YAWO_COLLECTIONS',
  ADD_YAWO_COLLECTION: 'ADD_YAWO_COLLECTION',
  YAWO_COLLECTION_IS_LOADING: 'YAWO_COLLECTION_IS_LOADING',
  YAWO_COLLECTION_NOT_LOADING: 'YAWO_COLLECTION_NOT_LOADING',
  SET_YAWO_COLLECTION_PAGE: 'SET_YAWO_COLLECTION_PAGE',
  SET_YAWO_COLLECTION_LIMIT: 'SET_YAWO_COLLECTION_LIMIT',
  SET_YAWO_COLLECTION_STATUS: 'SET_YAWO_COLLECTION_STATUS',
  SET_YAWO_COLLECTION_DATE: 'SET_YAWO_COLLECTION_DATE',
  RESET_YAWO_COLLECTION_DATE: 'RESET_YAWO_COLLECTION_DATE',

  // YAWO VENDORS ACTION TYPES
  ADD_YAWO_VENDOR: 'ADD_YAWO_VENDOR',
  YAWO_VENDOR_IS_LOADING: 'YAWO_VENDOR_IS_LOADING',
  YAWO_VENDOR_NOT_LOADING: 'YAWO_VENDOR_NOT_LOADING',

  // YAWO AGENTS ACTION TYPES
  ADD_YAWO_AGENTS: 'ADD_YAWO_AGENTS',
  ADD_YAWO_AGENT: 'ADD_YAWO_AGENT',
  YAWO_AGENT_IS_LOADING: 'YAWO_AGENT_IS_LOADING',
  YAWO_AGENT_NOT_LOADING: 'YAWO_AGENT_NOT_LOADING',
  SET_YAWO_AGENT_PAGE: 'SET_YAWO_AGENT_PAGE',
  SET_YAWO_AGENT_LIMIT: 'SET_YAWO_AGENT_LIMIT',
  SET_YAWO_AGENT_STATUS: 'SET_YAWO_AGENT_STATUS',
  SET_YAWO_AGENT_DATE: 'SET_YAWO_AGENT_DATE',
  RESET_YAWO_AGENT_DATE: 'RESET_YAWO_AGENT_DATE',

  // YAWO CUSTOMERS ACTION TYPES
  ADD_YAWO_CUSTOMERS: 'ADD_YAWO_CUSTOMERS',
  ADD_YAWO_CUSTOMER: 'ADD_YAWO_CUSTOMER',
  YAWO_CUSTOMER_IS_LOADING: 'YAWO_CUSTOMER_IS_LOADING',
  YAWO_CUSTOMER_NOT_LOADING: 'YAWO_CUSTOMER_NOT_LOADING',
  SET_YAWO_CUSTOMER_PAGE: 'SET_YAWO_CUSTOMER_PAGE',
  SET_YAWO_CUSTOMER_LIMIT: 'SET_YAWO_CUSTOMER_LIMIT',
  SET_YAWO_CUSTOMER_STATUS: 'SET_YAWO_CUSTOMER_STATUS',
  SET_YAWO_CUSTOMER_DATE: 'SET_YAWO_CUSTOMER_DATE',
  RESET_YAWO_CUSTOMER_DATE: 'RESET_YAWO_CUSTOMER_DATE',

  // YAWO GUARANTORS ACTION TYPES
  ADD_YAWO_GUARANTORS: 'ADD_YAWO_GUARANTORS',
  ADD_YAWO_GUARANTOR: 'ADD_YAWO_GUARANTOR',
  YAWO_GUARANTOR_IS_LOADING: 'YAWO_GUARANTOR_IS_LOADING',
  YAWO_GUARANTOR_NOT_LOADING: 'YAWO_GUARANTOR_NOT_LOADING',
  SET_YAWO_GUARANTOR_PAGE: 'SET_YAWO_GUARANTOR_PAGE',
  SET_YAWO_GUARANTOR_LIMIT: 'SET_YAWO_GUARANTOR_LIMIT',
  SET_YAWO_GUARANTOR_STATUS: 'SET_YAWO_GUARANTOR_STATUS',
  SET_YAWO_GUARANTOR_DATE: 'SET_YAWO_GUARANTOR_DATE',
  RESET_YAWO_GUARANTOR_DATE: 'RESET_YAWO_GUARANTOR_DATE',
});
