/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { createYawoVendor } from 'src/store/actions/yawo/vendorActions';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import contentfulUtils from 'src/utils/contentfulUtils';
import { CONTENTFUL_CONTENT_KEYS } from 'src/constants';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.white};
  max-height: calc((100vh - 176px) - 4rem);
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  gap: 10px;

  h1 {
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: ${(props) => props.theme.color.font_02};
  }

  label.label {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: ${(props) => props.theme.color.font_01};
  }

  .buttons,
  .check {
    display: flex;
  }
  .buttons {
    width: 100%;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  .check {
    margin-top: 20px;
    gap: 5px;
    align-items: flex-start;
  }

  .no-underline {
    text-decoration: none;
  }

  .full-terms {
    min-height: 300px;
    overflow-y: scroll;
  }
`;

const Terms = ({ createYawoVendor, profile }) => {
  const [isSelected, setIsSelected] = useState(false);
  const history = useHistory();

  const shortCode = profile.platform;
  const handleChange = () => {
    setIsSelected(!isSelected);
  };
  const data = {
    shortCode,
  };
  const onSubmit = (event) => {
    event.preventDefault();
    createYawoVendor(data, {
      success: () => history.push('/yawo/onboarding/submitted'),
    });
  };

  const [yawoVendorTerms, setYawoVendorTerms] = useState({});

  useEffect(() => {
    const { YAWO_VENDOR_TERMS } = CONTENTFUL_CONTENT_KEYS;
    // declare the term fetching function
    const fetchTerms = async () => {
      const { fields } = await contentfulUtils.client.getEntry(YAWO_VENDOR_TERMS);
      setYawoVendorTerms(fields);
    };
    // call the function
    fetchTerms().catch(console.error);
  }, []);

  let fullTerms = contentfulUtils.formatRichText(yawoVendorTerms.content);
  fullTerms = fullTerms.replace(/(?:\r\n|\r|\n)/g, '<br />');

  return (
    <Wrapper>
      <h1>{yawoVendorTerms.title || 'Yawo MMO Terms & Conditions'}</h1>

      <div className="full-terms" dangerouslySetInnerHTML={{ __html: fullTerms }} />

      <div className="check">
        <Checkbox
          checked={isSelected}
          onChange={handleChange}
          size="small"
          id="mmo_agree"
          style={{ margin: '0px', padding: '0px', color: '#219DD3' }}
        />
        <label htmlFor="mmo_agree" className="label">
          MMO agrees to connect agents for Yawo collections
        </label>
      </div>

      <div className="buttons">
        <Link to="/yawo/onboarding/no-access" className="no-underline">
          <Button text="Cancel" theme="gray" />
        </Link>
        <Button text="Agree & Submit" disabled={!isSelected} onClick={onSubmit} />
      </div>
    </Wrapper>
  );
};

Terms.propTypes = {
  profile: PropTypes.object.isRequired,
  createYawoVendor: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading } = state.yawoVendor;
  const { profile } = state.user;
  return {
    isLoading,
    profile,
  };
};

export default connect(mapStateToProps, {
  createYawoVendor,
})(Terms);
