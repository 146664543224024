import React from 'react';
import AppLayout from 'src/components/layout/AppLayout';
//import PropTypes from 'prop-types';
//import PosHeaderForm from 'src/components/posMappings/PosHeaderForm';
import PosMappingTable from 'src/components/posMappings/PosMappingTable';

function PosMapping() {
  return (
    <AppLayout title="POS Mappings">
      <PosMappingTable />
    </AppLayout>
  );
}

PosMapping.propTypes = {};

export default PosMapping;
