/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */

export const downloadBlob = (content, filename, contentType = 'text/csv;charset=utf-8;') => {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a link to download it
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', filename);
  a.click();
};

export default downloadBlob;
