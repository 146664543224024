import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import VerifyIcon from '@material-ui/icons/CallToAction';
import { Tooltip } from '@material-ui/core';
import ViewLoan from './ViewLoan';
import RejectLoan from './RejectLoan';
import { getAllYawoLoans } from 'src/store/actions/yawo/loanActions';
import { connect } from 'react-redux';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .icon {
    cursor: pointer;
  }
  .approve {
    color: ${(props) => props.theme.color.cyan};
  }
  .reject {
    color: ${(props) => props.theme.color.red};
  }
`;

const LoanActions = ({ loan, getAllYawoLoans }) => {
  const [openReject, setOpenReject] = useState(false);
  const toggleReject = () => {
    setOpenReject(!openReject);
  };

  const vendorHasNotApproved = !loan?.vendorApproved?.status;
  const loanStatusIsNotRejected = loan?.status !== 'rejected';
  const showRejectButton = vendorHasNotApproved && loanStatusIsNotRejected;

  return (
    <Wrapper>
      <ViewLoan loanId={loan._id} />
      {showRejectButton && (
        <>
          <Tooltip title="Reject">
            <VerifyIcon className="icon reject" onClick={toggleReject} />
          </Tooltip>
          <RejectLoan isOpen={openReject} handleClose={toggleReject} loanId={loan._id} onSuccess={getAllYawoLoans} />
        </>
      )}
    </Wrapper>
  );
};

LoanActions.propTypes = {
  loan: PropTypes.object.isRequired,
  getAllYawoLoans: PropTypes.func,
};

export default connect(null, { getAllYawoLoans })(LoanActions);
