import React from 'react';
import SingleSelect from 'src/components/common/table/SingleSelect';
import { formatAmount } from 'src/utils/helpers';

const priceSettingHeader1 = (selected, onSelectChange) => {
  return [
    {
      title: function title() {
        return <p>Select</p>;
      },
      render: function DateTaken(rowData, index) {
        return <SingleSelect value={String(index)} selected={selected || ''} onClick={onSelectChange} />;
      },
    },
    {
      title: function title() {
        return <p>{'Range (₦) Min - Max '}</p>;
      },
      render: function SerialNumber(rowData) {
        return <p>{`${formatAmount(rowData.min) || ''} - ${formatAmount(rowData.max) || ''}`}</p>;
      },
    },
    {
      title: function title() {
        return <p>Commissions</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['Commissions'] || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>Price</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['Price']) || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'User Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['User Charge']) || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Agent Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['Agent Charge']) || '-'}</p>;
      },
    },
  ];
};

const tableKey1 = {
  Price: 'ikonikCharge',
  Commissions: 'commission',
};
tableKey1['User Charge'] = 'platformUserCharge';
tableKey1['Agent Charge'] = 'platformAgentCharge';

const priceSettingWithdrawals = (selected, onSelectChange) => {
  return [
    {
      title: function title() {
        return <p>Select</p>;
      },
      render: function DateTaken(rowData, index) {
        return <SingleSelect value={String(index)} selected={selected || ''} onClick={onSelectChange} />;
      },
    },
    {
      title: function title() {
        return <p>{'Range (₦) Min - Max '}</p>;
      },
      render: function SerialNumber(rowData) {
        return <p>{`${formatAmount(rowData.min) || ''} - ${formatAmount(rowData.max) || ''}`}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Ikonik Charge (%)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['ikonikPercentageCharge'] || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Ikonik Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['ikonikFixedCharge']) || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'User Charge (%)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['platformUserPercentageCharge'] || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'User Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['platformUserFixedCharge']) || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Agent Charge (%)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['platformAgentPercentageCharge'] || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Agent Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['platformAgentFixedCharge']) || '-'}</p>;
      },
    },
  ];
};

const priceSettingHeader2 = (selected, onSelectChange) => {
  return [
    {
      title: function title() {
        return <p>Select</p>;
      },
      render: function Select(rowData, index) {
        return <SingleSelect value={String(index)} selected={selected || ''} onClick={onSelectChange} />;
      },
    },
    {
      title: function title() {
        return <p>{'Ikonik Commission(%)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['Commissions']}</p>;
      },
    },
    {
      title: function title() {
        return <p>Ikonik Conveniency</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['Conveniency']}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Agent Commission(%)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['Agent Commission']}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Agent Conveniency (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{rowData['Agent Conveniency']}</p>;
      },
    },
  ];
};

const priceSettingHeaderForPosActivation = (selected, onSelectChange) => {
  return [
    {
      title: function title() {
        return <p>Select</p>;
      },
      render: function Select(rowData, index) {
        return <SingleSelect value={String(index)} selected={selected || ''} onClick={onSelectChange} />;
      },
    },
    {
      title: function title() {
        return <p>{'Ikonik Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['ikonikCharge']) || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'User Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(rowData['platformCharge']) || '-'}</p>;
      },
    },
    {
      title: function title() {
        return <p>{'Total Charge (₦)'}</p>;
      },
      render: function DateTaken(rowData) {
        return <p>{formatAmount(+rowData['ikonikCharge'] + +rowData['platformCharge']) || '-'}</p>;
      },
    },
  ];
};

const tableKey2 = {
  Commissions: 'ikonikCommission',
  Conveniency: 'ikonikConvenience',
};
tableKey2['Agent Commission'] = 'platformCommission';
tableKey2['Agent Conveniency'] = 'platformConvenience';

export const getPriceSettingHeader = (type, selected, onSelectChange) => {
  if (type === 'pos-activation') {
    return { header: priceSettingHeaderForPosActivation(selected, onSelectChange), key: 'platformCharge' };
  }
  if (type === 'tv-cable' || type === 'electricity') {
    return { header: priceSettingHeader2(selected, onSelectChange), key: tableKey2 };
  }
  if (type === 'withdrawals') {
    return { header: priceSettingWithdrawals(selected, onSelectChange), key: {} };
  }
  return { header: priceSettingHeader1(selected, onSelectChange), key: tableKey1 };
};
