import client from 'src/client';
import { clearAlert, errorAlert, infoAlert } from './alertActions';
import {
  ADD_TRANSACTIONS,
  ADD_TRANSACTION,
  CLEAR_TRANSACTIONS,
  TRANSACTION_IS_LOADING,
  TRANSACTION_NOT_LOADING,
  SET_TRANSACTION_DATE,
  SET_TRANSACTION_PAGE,
  SET_TRANSACTION_LIMIT,
  SET_TRANSACTION_STATUS,
  RESET_TRANSACTION_DATE,
} from 'src/store/types';
import { dateFormatYMD } from 'src/utils/helpers';

const { walletService } = client;

const transactionIsLoading =
  (msg = 'Loading Transactions') =>
  (dispatch) => {
    dispatch({ type: TRANSACTION_IS_LOADING });
    dispatch(infoAlert(msg));
  };

const transactionNotLoading = () => (dispatch) => {
  dispatch(clearAlert());
  dispatch({ type: TRANSACTION_NOT_LOADING });
};

const addTransactions = (transactions) => {
  return { type: ADD_TRANSACTIONS, payload: transactions };
};

const addTransaction = (transaction) => {
  return { type: ADD_TRANSACTION, payload: transaction };
};

const clearTransactions = () => {
  return { type: CLEAR_TRANSACTIONS };
};

const generatePath = (endPoint, transaction) => {
  if (transaction === null) {
    return endPoint;
  }

  const { start_date, end_date, limit, page, status } = transaction;

  if (start_date === null || end_date === null) {
    if (status === 'all') {
      return `${endPoint}?limit=${limit}&page=${page}`;
    }

    return `${endPoint}?limit=${limit}&status=${status}&page=${page}`;
  }

  if (status === 'all') {
    return `${endPoint}?limit=${limit}&page=${page}&start_date=${dateFormatYMD(start_date)}&end_date=${dateFormatYMD(
      end_date
    )}`;
  }

  return `${endPoint}?limit=${limit}&status=${status}&page=${page}&start_date=${dateFormatYMD(
    start_date
  )}&end_date=${dateFormatYMD(end_date)}`;
};

export const setTransactionDate = (start_date, end_date) => {
  return { type: SET_TRANSACTION_DATE, payload: { start_date, end_date } };
};

export const resetTransactionDate = () => {
  return { type: RESET_TRANSACTION_DATE };
};

export const setTransactionPage = (page) => {
  return { type: SET_TRANSACTION_PAGE, payload: page };
};

export const setTransactionLimit = (limit) => {
  return { type: SET_TRANSACTION_LIMIT, payload: limit };
};

export const setTransactionStatus = (status) => {
  return { type: SET_TRANSACTION_STATUS, payload: status };
};

export const getAllTransactions = () => (dispatch, getState) => {
  dispatch(transactionIsLoading());
  const { user, transaction } = getState();
  const path = generatePath(`history/${user.profile.platform}`, transaction.transactions);
  dispatch(clearTransactions());
  walletService
    .get(path)
    .then((res) => {
      dispatch(addTransactions(res.data));
      dispatch(transactionNotLoading());
    })
    .catch((err) => {
      const error = err?.response?.data?.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(transactionNotLoading());
    });
};

export const getTransactionsByType = (type) => (dispatch, getState) => {
  dispatch(transactionIsLoading());
  const { user, transaction } = getState();
  const path = generatePath(`history/transactions/${user.profile.platform}/${type}`, transaction.transactions);
  dispatch(clearTransactions());
  walletService
    .get(path)
    .then((res) => {
      dispatch(addTransactions(res.data));
      dispatch(transactionNotLoading());
    })
    .catch((err) => {
      const error = err.response.data.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(transactionNotLoading());
    });
};

const generatePosPath = (endPoint, transactions) => {
  if (transactions === null) {
    return `${endPoint}?type=pos`;
  }
  const { start_date, end_date, limit, page, status } = transactions;

  if (start_date === null || end_date === null) {
    if (status === 'all') {
      return `${endPoint}?type=pos&limit=${limit}&page=${page}`;
    }

    return `${endPoint}?type=pos&limit=${limit}&status=${status}&page=${page}`;
  }

  if (status === 'all') {
    return `${endPoint}?type=pos&limit=${limit}&page=${page}&start_date=${dateFormatYMD(
      start_date
    )}&end_date=${dateFormatYMD(end_date)}`;
  }

  return `${endPoint}?type=pos&limit=${limit}&status=${status}&page=${page}&start_date=${dateFormatYMD(
    start_date
  )}&end_date=${dateFormatYMD(end_date)}`;
};

export const getPosTransactions = (walletId) => (dispatch, getState) => {
  dispatch(transactionIsLoading());
  const { transactions } = getState().transaction;
  const path = generatePosPath(`history/transactions/${walletId}`, transactions);
  dispatch(clearTransactions());
  walletService
    .get(path)
    .then((res) => {
      dispatch(addTransactions(res.data));
      dispatch(transactionNotLoading());
    })
    .catch((err) => {
      const error = err.response.data.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(transactionNotLoading());
    });
};

export const getTransaction = (transactionRef) => (dispatch) => {
  dispatch(transactionIsLoading());
  walletService
    .get(`history/transaction/${transactionRef}`)
    .then((res) => {
      dispatch(addTransaction(res.data));
      dispatch(transactionNotLoading());
    })
    .catch(() => {
      dispatch(errorAlert({ msg: 'Something went wrong. Try Again' }));
      dispatch(transactionNotLoading());
    });
};

const generateWalletIdPath = (endPoint, type, transactions) => {
  if (transactions === null) {
    return `${endPoint}?type=${type}`;
  }
  const { start_date, end_date, limit, page, status } = transactions;

  if (start_date === null || end_date === null) {
    if (status === 'all') {
      return `${endPoint}?type=${type}&limit=${limit}&page=${page}`;
    }

    return `${endPoint}?type=${type}&limit=${limit}&status=${status}&page=${page}`;
  }

  if (status === 'all') {
    return `${endPoint}?type=${type}&limit=${limit}&page=${page}&start_date=${dateFormatYMD(
      start_date
    )}&end_date=${dateFormatYMD(end_date)}`;
  }

  return `${endPoint}?type=${type}&limit=${limit}&status=${status}&page=${page}&start_date=${dateFormatYMD(
    start_date
  )}&end_date=${dateFormatYMD(end_date)}`;
};

export const getTransactionsByWalletId = (walletId, type) => (dispatch, getState) => {
  dispatch(transactionIsLoading());
  const { transaction } = getState();
  const path = generateWalletIdPath(`history/transactions/${walletId}`, type, transaction.transactions);
  dispatch(clearTransactions());
  walletService
    .get(path)
    .then((res) => {
      dispatch(addTransactions(res.data));
      dispatch(transactionNotLoading());
    })
    .catch((err) => {
      const error = err.response.data.message;
      dispatch(errorAlert({ msg: error }));
      dispatch(transactionNotLoading());
    });
};
