import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'src/components/common/Modal';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { getSingleYawoGuarantor, getAllYawoGuarantors } from 'src/store/actions/yawo/guarantorActions';
import VerifyGuarantor from './VerifyGuarantor';
import Loader from 'src/components/notification/Loader';
import { dateFormatYMD, formatTime } from 'src/utils/helpers';
import UserVerificationStatus from 'src/components/yawo/UserVerificationStatus';
import UserIsActiveStatus from 'src/components/yawo/UserIsActiveStatus';
import Button from 'src/components/common/Button';

const StyledIcon = styled(OpenInNewIcon)`
  color: ${(props) => props.theme.color.grey};
  cursor: pointer;
`;

const Wrapper = styled.div`
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
  }
  .header_title {
    display: flex;
    justify-content: center;
  }
  .cancel {
    cursor: pointer;
    font-weight: bold;
  }
  .body {
    background-color: ${(props) => props.theme.color.lightGrey};
    padding: 1rem;
    margin: 0.5rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    color: black;
    margin-bottom: 0.5rem;
    @media screen and (max-width: 425px) {
      display: grid;
      grid-gap: 0rem;
    }
  }
  .value {
    color: black;
    font-weight: bold;
    font-size: 0.8rem;
  }
`;

const ViewGuarantor = ({ guarantorId, yawoGuarantor, getSingleYawoGuarantor, getAllYawoGuarantors, isLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  const [openVerify, setOpenVerify] = useState(false);
  const toggleVerify = () => setOpenVerify(!openVerify);

  useEffect(() => {
    if (isOpen) getSingleYawoGuarantor(guarantorId);
  }, [isOpen, getSingleYawoGuarantor, guarantorId]);

  return (
    <div>
      <Tooltip title="View Guarantor">
        <StyledIcon onClick={toggleModal} />
      </Tooltip>
      <Modal isOpen={isOpen} handleClose={toggleModal}>
        <Wrapper>
          <div className="header">
            <div className="header_title">
              <h6>Guarantor Details</h6>
            </div>

            <CloseIcon onClick={toggleModal} className="cancel" />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="body">
              <div>
                <>
                  <div>
                    <div className="item">
                      <p>Photo</p>
                    </div>
                    <div>
                      <img src={yawoGuarantor?.photo} style={{ width: '100%', maxWidth: '320px' }} />
                    </div>
                  </div>
                </>
              </div>

              <div>
                <div className="item">
                  <p>First Name</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor.firstName}</h6>
                </div>
                <div className="item">
                  <p>Last Name</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor.lastName}</h6>
                </div>
                <div className="item">
                  <p>Other Names</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor.otherNames}</h6>
                </div>
                <div className="item">
                  <p>Email</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor?.email?.address}</h6>
                </div>
                <div className="item">
                  <p>Phone Number</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor?.phoneNumber?.number}</h6>
                </div>
                <div className="item">
                  <p>Sex</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor?.sex}</h6>
                </div>
                <div className="item">
                  <p>Date of Birth</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor?.dob}</h6>
                </div>
                <div className="item">
                  <p>Customer</p>
                  {yawoGuarantor?.loanCustomer && (
                    <h6 className="value">
                      {yawoGuarantor?.loanCustomer?.firstName} {yawoGuarantor?.loanCustomer?.lastName} (
                      {yawoGuarantor?.loanCustomer?.phoneNumber?.number})
                    </h6>
                  )}
                </div>
                <div className="item">
                  <p>Verification Status</p>
                  <h6 className="value">{yawoGuarantor && <UserVerificationStatus user={yawoGuarantor} />}</h6>
                </div>
                <div className="item">
                  <p>Is Active</p>
                  <h6 className="value">{yawoGuarantor && <UserIsActiveStatus user={yawoGuarantor} />}</h6>
                </div>
                <div className="item">
                  <p>Account Number</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor?.bank?.accountNumber}</h6>
                </div>
                <div className="item">
                  <p>Bank Name</p>
                  <h6 className="value">{yawoGuarantor && yawoGuarantor?.bank?.bankName}</h6>
                </div>
                <div className="item">
                  <p>Date</p>
                  <h6 className="value">{yawoGuarantor && dateFormatYMD(yawoGuarantor.createdAt)}</h6>
                </div>
                <div className="item">
                  <p>Time</p>
                  <h6 className="value">{yawoGuarantor && formatTime(yawoGuarantor.createdAt)}</h6>
                </div>
                <>
                  {!yawoGuarantor?.vendor?.isVerified && (
                    <>
                      <Tooltip title="Verify">
                        <Button text="Verify Guarantor" fullWidth={true} onClick={toggleVerify} />
                      </Tooltip>
                      <VerifyGuarantor
                        isOpen={openVerify}
                        handleClose={toggleVerify}
                        guarantorId={guarantorId}
                        onSuccess={getAllYawoGuarantors}
                      />
                    </>
                  )}
                </>
              </div>
            </div>
          )}
        </Wrapper>
      </Modal>
    </div>
  );
};

ViewGuarantor.propTypes = {
  yawoGuarantor: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getSingleYawoGuarantor: PropTypes.func.isRequired,
  getAllYawoGuarantors: PropTypes.func.isRequired,
  guarantorId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading, yawoGuarantor } = state.yawoGuarantor;
  return { isLoading, yawoGuarantor };
};

export default connect(mapStateToProps, { getSingleYawoGuarantor, getAllYawoGuarantors })(ViewGuarantor);
