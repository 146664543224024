const reactSelectTheme = (theme) => ({
  ...theme,
  outline: 'none',
  borderRadius: '0',
  colors: {
    ...theme.colors,
    primary25: '#3D7DF630',
    primary: '#3D7DF6',
  },
});

const reactSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: state.isFocused ? '#212121' : '#E8E8E8',
    borderRadius: '5px',
    boxShadow: 'none',
    height: '2.2rem',
    '&:hover': {
      borderColor: '#212121',
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: '0rem 0.5rem',
  }),
  singleValue: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  input: (provided) => ({
    ...provided,
    marginLeft: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: 0,
    color: '#A2A2A2',
    whiteSpace: 'nowrap',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: 0,
    '& [class$="indicatorContainer"]': {
      cursor: 'pointer',
    },
  }),
  option: (provided) => ({
    ...provided,
    cursor: 'pointer',
    '&:active': {
      backgroundColor: '#3D7DF630',
    },
  }),
};

export const reactSelectUtil = {
  theme: reactSelectTheme,
  styles: reactSelectStyles,
};

export default reactSelectUtil;
