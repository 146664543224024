import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'materialui-daterange-picker';

function DateRangeInput({ onChange, toggleOpen, isOpen }) {
  return <DateRangePicker open={isOpen} toggle={toggleOpen} onChange={(range) => onChange(range)} />;
}

DateRangeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export { DateRangeInput };
