import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
//import EditIcon from '@material-ui/icons/Edit';
// import DeleteIcon from '@material-ui/icons/Delete';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import { Tooltip } from '@material-ui/core';
//import UpdatePosMapping from './UpdatePosMapping';
// import DeletePosMapping from './DeletePosMapping';
import MapPosToAgent from './MapPosToAgent';
import UnmapPosFromAgent from './UnmapPosFromAgent';
// import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  width: max-content;
  .icon {
    cursor: pointer;
  }
  .delete {
    color: ${(props) => props.theme.color.red};
  }
  .edit {
    color: ${(props) => props.theme.color.cyan};
  }
`;

const PosActions = ({ data }) => {
  //const [openEdit, setOpenEdit] = useState(false);
  // const [openDelete, setOpenDelete] = useState(false);
  const [openMapModal, setOpenMapModal] = useState(false);
  const [openUnmapModal, setOpenUnmapModal] = useState(false);

  /* const toggleEdit = () => {
		setOpenEdit(!openEdit);
	}; */

  // const toggleDelete = () => {
  // 	setOpenDelete(!openDelete);
  // };

  const toggleMapModal = () => setOpenMapModal(!openMapModal);
  const toggleUnmapModal = () => setOpenUnmapModal(!openUnmapModal);

  const { pos: posId, agentId } = data?.mapping?.agentMapping || {};

  return (
    <Wrapper>
      {/* <Tooltip title="View Transactions">
				<Link to= {`/pos/${data.walletId}/transactions`}>
					<ReceiptIcon
						className="icon edit"
					/>
				</Link>
			</Tooltip> */}
      {/* <Tooltip title="Edit">
				<EditIcon
					className="icon edit"
					onClick={toggleEdit}
				/>
			</Tooltip> */}
      {/* <Tooltip title="Delete">
				<DeleteIcon className="icon delete" onClick={toggleDelete} />
			</Tooltip> */}
      {/* 			<UpdatePosMapping
				isOpen={openEdit}
				handleClose={toggleEdit}
				data={data}
			/> */}
      {/* <DeletePosMapping
				isOpen={openDelete}
				handleClose={toggleDelete}
				posId={posId}
				mappingId={data.mappingId}
			/> */}
      {posId && agentId ? (
        <>
          <Tooltip title="Unmap From Agent">
            <LinkOffIcon className="icon unmap" onClick={toggleUnmapModal} style={{ color: '#D05F5F' }} />
          </Tooltip>
          <UnmapPosFromAgent isOpen={openUnmapModal} handleClose={toggleUnmapModal} posId={posId} agentId={agentId} />
        </>
      ) : (
        <>
          <Tooltip title="Map To Agent">
            <LinkIcon className="icon map" onClick={toggleMapModal} style={{ color: '#3D7DF6' }} />
          </Tooltip>
          <MapPosToAgent isOpen={openMapModal} handleClose={toggleMapModal} posDevice={data} />
        </>
      )}
    </Wrapper>
  );
};

PosActions.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PosActions;
