import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DisplayCard from 'src/components/dashboard/DisplayCard';
import styled from 'styled-components';
import Button from 'src/components/common/Button';
import { formatDate, formatAmount } from 'src/utils/helpers';
import { DateRangeInput } from 'src/components/common/inputs';
import PosTransactionsTable from './PosTransactionTable';
import {
  getPosTransactions,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
} from 'src/store/actions/transactionActions';

const Wrapper = styled.div`
  .cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: max-content;
    grid-gap: 1.5rem;
    height: max-content;
  }
  .date_range {
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .date_actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    grid-gap: 0.5rem;
    margin-top: 0.5rem;
  }
  .range_input {
    width: max-content;
    height: max-content;
    margin: auto;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

function PosTransactions({
  getPosTransactions,
  match,
  isLoading,
  transactions,
  setTransactionPage,
  setTransactionLimit,
  setTransactionDate,
  setTransactionStatus,
  resetTransactionDate,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const { walletId } = match.params;

  const toggle = () => setIsOpen(!isOpen);

  const getTransactions = useCallback(() => {
    getPosTransactions(walletId);
  }, [getPosTransactions, walletId]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  const onPageChange = (page) => {
    setTransactionPage(page);
    getTransactions();
  };

  const onLimitChange = (limit) => {
    setTransactionLimit(limit);
    getTransactions();
  };

  const onResetDateClick = () => {
    resetTransactionDate();
    getTransactions();
  };

  const onStatusChange = (status) => {
    setTransactionStatus(status);
    getTransactions();
  };

  const onDateChange = (date) => {
    setTransactionDate(date.startDate, date.endDate);
    getTransactions();
  };

  return (
    <Wrapper>
      <div className="cards">
        <DisplayCard isLoading={isLoading} title="Volume" value={transactions !== null && transactions.count} />
        <DisplayCard
          isLoading={isLoading}
          title="Value"
          value={transactions !== null && formatAmount(transactions.value)}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Revenue"
          value={transactions !== null && formatAmount(transactions.revenue)}
        />
        <DisplayCard
          isLoading={isLoading}
          title="Income"
          value={transactions !== null && formatAmount(transactions.income)}
        />
        <div className="date_range">
          <Button
            onClick={toggle}
            text={`${transactions === null ? '' : formatDate(transactions.start_date)} - ${
              transactions === null ? '' : formatDate(transactions.end_date)
            }`}
            className="time_display"
          />
          <div className="range_input">
            <DateRangeInput isOpen={isOpen} onChange={onDateChange} toggleOpen={toggle} />
          </div>
          <div className="date_actions">
            <Button text="Reset time" onClick={onResetDateClick} />
          </div>
        </div>
      </div>
      <PosTransactionsTable
        tableData={transactions}
        onPageChange={onPageChange}
        onLimitChange={onLimitChange}
        onStatusChange={onStatusChange}
      />
    </Wrapper>
  );
}

PosTransactions.propTypes = {
  getPosTransactions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  transactions: PropTypes.array.isRequired,
  setTransactionLimit: PropTypes.func.isRequired,
  setTransactionPage: PropTypes.func.isRequired,
  setTransactionDate: PropTypes.func.isRequired,
  setTransactionStatus: PropTypes.func.isRequired,
  resetTransactionDate: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { transactions, isLoading } = state.transaction;
  return {
    isLoading,
    transactions,
  };
};

export default connect(mapStateToProps, {
  getPosTransactions,
  setTransactionStatus,
  setTransactionDate,
  setTransactionLimit,
  setTransactionPage,
  resetTransactionDate,
})(withRouter(PosTransactions));
