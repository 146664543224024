import React from 'react';
import YawoOnboardingLayout from './Layout';

function Submitted() {
  return (
    <YawoOnboardingLayout
      heading="Yawo Registration Submitted"
      details="Your request to become a Yawo MMO has been sent and is being reviewed.
    Once your registration has been approved, you’ll be notified. This may take up to 24hours."
    />
  );
}

export default Submitted;
