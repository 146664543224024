import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: max-content;
  height: max-content;
  min-height: 111px;
  min-width: 215px;
  padding: 0.2rem 0.5rem;
  display: flex;
  padding: 29px 13px;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
  background-color: ${(props) => props.theme.color.white};

  .MuiPaper-rounded {
    border-radius: 0;
  }

  p {
    color: ${(props) => props.theme.color.font_01};
  }

  h3 {
    color: ${(props) => props.theme.color.cyan};
    font-weight: 700;
    font-size: 26px;
    margin-top: 10px;
    line-height: 24px;
  }
`;

function DisplayCard({ title, value, isLoading }) {
  return (
    <Wrapper>
      <p>{title}</p>
      <h3>{!isLoading && value}</h3>
    </Wrapper>
  );
}

DisplayCard.defaultProps = {
  isLoading: false,
  value: ' ',
};

DisplayCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DisplayCard;
