import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const Wrapper = styled.div`
  .item {
    display: flex;
    width: max-content;
    padding: 0;
    align-items: center;
  }

  .text {
    font-size: 0.8rem;
  }

  .icon {
    height: 1rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin-right: 0.5rem;
  }
  .success_icon {
    background-color: ${(props) => props.theme.color.lightGreen};
    color: ${(props) => props.theme.color.green} !important;
  }
  .success_text {
    color: ${(props) => props.theme.color.green};
  }
  .failed_icon {
    background-color: ${(props) => props.theme.color.lightRed};
    color: ${(props) => props.theme.color.red} !important;
  }
  .failed_text {
    color: ${(props) => props.theme.color.red};
  }
  .pending_icon {
    background-color: ${(props) => props.theme.color.lightYellow};
    color: ${(props) => props.theme.color.yellow} !important;
  }
  .pending_text {
    color: ${(props) => props.theme.color.yellow};
  }
  .refunded_icon {
    background-color: ${(props) => props.theme.color.grey};
    color: ${(props) => props.theme.color.cyan} !important;
  }
  .refunded_text {
    color: ${(props) => props.theme.color.cyan};
  }
`;

function TransactionStatus({ status }) {
  const displayPending = () => (
    <div className="item">
      <FiberManualRecordIcon className="icon pending_icon" alt="action_icon" />
      <p className="text pending_text">PENDING</p>
    </div>
  );

  const displayRefunded = () => (
    <div className="item">
      <FiberManualRecordIcon className="icon refunded_icon" alt="action_icon" />
      <p className="text refunded_text">REFUNDED</p>
    </div>
  );

  const displaySuccess = () => (
    <div className="item">
      <FiberManualRecordIcon className="icon success_icon" alt="action_icon" />
      <p className="text success_text">SUCCESSFUL</p>
    </div>
  );

  const displayFailed = () => (
    <div className="item">
      <FiberManualRecordIcon className="icon failed_icon" alt="action_icon" />
      <p className="text failed_text">UNSUCCESSFUL</p>
    </div>
  );

  return (
    <Wrapper>
      {status === 'FAILED' && displayFailed()}
      {status === 'SUCCESS' && displaySuccess()}
      {status === 'PENDING' && displayPending()}
      {status === 'REFUNDED' && displayRefunded()}
      {status === 'BILLING_FAILED' && displayFailed()}
      {status === 'BILLING_PENDING' && displayPending()}
      {status === 'BILLING_SUCCESSFUL' && displaySuccess()}
    </Wrapper>
  );
}

TransactionStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default TransactionStatus;
